import React, { Fragment, memo } from "react";
import { useSelector } from "react-redux";
import get from "lodash/get";
import { Link } from "@quintype/components";
import { getStoryHeadline, getStoryLabel, isExternalStory } from "../../utils/story";
import { HeroImage } from "../../atoms/HeroImage";
import { PublishAndReadTime } from "../../atoms/PublishAndReadTime";
import { DfpComponent } from "../../ads/dfp-component";
import { Loading } from "../../atoms/Loading/loading";
import Pagination from "../../atoms/Pagination";
import Dropdown from "./Dropdown";
import { useDeviceType } from "../../custom-hooks/useDeviceType";
import { SvgIconHandler } from "../../atoms/svg-icon-hadler";
import { getQueryParams, setQueryParam } from "../../utils/utils";
import { Label } from "../../atoms/Label";
import Headline from "../../atoms/Headline";
import SubHeadline from "../../atoms/SubHeadline";

import "./search-page.m.css";

const SearchResults = ({
  config,
  searchResult,
  trendingStories,
  loading,
  error,
  setOffset,
  setFilterValue,
  setSortText,
  filterValue,
  sortText,
  offset,
  sectionId,
  sectionName,
  handleSearch,
  allSectionRef,
  allCuisineRef,
  allCookingTimeRef,
  allPrepTimeRef,
  childSections,
  setCuisineFilter,
  cuisineFilter,
  setPrepTimeFilterValue,
  prepTimeFilterValue,
  setCookTimeFilterValue,
  cookTimeFilterValue,
  itemsPerPage,
}) => {
  const totalItemsCount = searchResult?.total;
  const itemsCountPerPage = itemsPerPage;

  const isMobile = useDeviceType();
  const totalPages = Math.ceil(totalItemsCount / itemsCountPerPage);
  const pageRangeDisplayed = isMobile === "mobile" ? Math.min(totalItemsCount, 3) : Math.min(totalItemsCount, 7);

  const handleMultiplePageChange = (multiplePageTurnType) => {
    if (multiplePageTurnType === 1) {
      setQueryParam("page", totalPages);
      setOffset(totalPages);
    }

    if (multiplePageTurnType === -1) {
      setQueryParam("page", 1);
      setOffset(1);
    }
  };

  const handleSinglePageChange = (singlePageTurnType) => {
    if (singlePageTurnType === 1) {
      if (offset + 1 <= totalPages) {
        setQueryParam("page", offset + 1);
        setOffset(offset + 1);
      }
    }

    if (singlePageTurnType === -1) {
      if (offset - 1 >= 0) {
        setQueryParam("page", offset - 1);
        setOffset(offset - 1);
      }
    }
  };

  const handleSelfPageChange = (newOffset) => {
    setQueryParam("page", newOffset + 1);
    setOffset(newOffset + 1);
  };

  const adConfig = useSelector((state) =>
    get(state, ["qt", "config", "ads-config", "slots", "search_page_row_adslot"], {})
  );

  const bottomAdConfig = useSelector((state) =>
    get(state, ["qt", "config", "ads-config", "slots", "search_page_bottom_adslot"], {})
  );

  const handleFilterValue = (e) => {
    e?.target?.value === "section" ? setFilterValue("") : setFilterValue(e?.target?.value);
    getQueryParams("page") && setQueryParam("page", 1);
    getQueryParams("page") && setOffset(1);
  };
  const handleSetSortText = (e) => {
    setSortText(e.target.value);
  };
  const handlePrepTimeFilterValue = (e) => {
    setPrepTimeFilterValue(e.target.value);
  };
  const handleCookTimeFilterValue = (e) => {
    setCookTimeFilterValue(e.target.value);
  };
  const handleCuisineFilterValue = (e) => {
    setCuisineFilter(e.target.value);
  };

  const StoryTextContent = ({ story, type, isSubHeadlineToShow = false, showLabel }) => {
    const { subheadline } = story;
    const headline = getStoryHeadline(story);
    return (
      <div styleName={`${type}-story-box`}>
        {getStoryLabel(story) && showLabel && <Label story={story} />}
        {headline && <Headline headline={headline} styleName={`${type}-headline`} />}
        {isSubHeadlineToShow && subheadline && (
          <SubHeadline subHeadline={subheadline} styleName={`${type}-subheadline`} />
        )}
        <PublishAndReadTime showReadTime={true} showTimestamp={true} story={story} />
      </div>
    );
  };

  const StoryComponent = ({ story, type, isImageToShow = false, isSubHeadlineToShow = false }) => {
    const externalStoryUrl = isExternalStory(story);
    const href = externalStoryUrl ? externalStoryUrl : `/${story?.slug}`;
    const target = externalStoryUrl ? "_blank" : "_self";
    return (
      <Link href={href} target={target}>
        {isImageToShow ? (
          <div styleName={`${type}-story-container`}>
            <StoryTextContent story={story} type={type} isSubHeadlineToShow={isSubHeadlineToShow} showLabel={true} />
            <div styleName={`${type}-story-image-container`}>
              <HeroImage
                story={story}
                widths={[135]}
                aspectRatio={[[4, 3]]}
                headline={getStoryHeadline(story)}
                iconSizes={{ width: 48, height: 48 }}
              />
            </div>
          </div>
        ) : (
          <StoryTextContent story={story} type={type} isSubHeadlineToShow={isSubHeadlineToShow} showLabel={true} />
        )}
      </Link>
    );
  };

  const StoriesWrapper = () => {
    return (
      <div styleName="stories-wrapper">
        <div styleName="stories-results">
          <div styleName="filter-bar">
            <span styleName="results-count-text">{searchResult?.total} RESULTS</span>

            {sectionName !== "Food" ? (
              <div styleName="filter-container">
                <Dropdown
                  label="Filter By"
                  config={config}
                  customClass="selector"
                  options={allSectionRef}
                  value={filterValue}
                  customHandleChange={handleFilterValue}
                  sectionId={sectionId}
                  flag="filterBy"
                  childSections={childSections}
                />
                <Dropdown
                  label="Sort By"
                  config={config}
                  customClass="selector"
                  value={sortText}
                  customHandleChange={handleSetSortText}
                  handleSearch={handleSearch}
                  flag="sortBy"
                />
              </div>
            ) : (
              <div styleName="recipe-filter-container">
                <Dropdown
                  label="Preparation Time"
                  config={config}
                  options={allPrepTimeRef}
                  customClass="selector"
                  value={prepTimeFilterValue}
                  customHandleChange={handlePrepTimeFilterValue}
                  sectionId={sectionId}
                  flag="prepTime"
                  childSections={childSections}
                />
                <Dropdown
                  label="Cooking Time"
                  config={config}
                  options={allCookingTimeRef}
                  customClass="selector"
                  value={cookTimeFilterValue}
                  customHandleChange={handleCookTimeFilterValue}
                  handleSearch={handleSearch}
                  flag="cookTime"
                />
                <Dropdown
                  label="Cuisine"
                  config={config}
                  options={allCuisineRef}
                  customClass="selector"
                  value={cuisineFilter}
                  customHandleChange={handleCuisineFilterValue}
                  handleSearch={handleSearch}
                  flag="cuisine"
                />
              </div>
            )}
          </div>
          {searchResult?.items?.map((story, index) => (
            <Fragment key={index}>
              <StoryComponent story={story} type="secondary" isImageToShow={true} isSubHeadlineToShow={true} />
              {index < searchResult?.items.length - 1 && <div styleName="horizontal-separator"></div>}
            </Fragment>
          ))}

          {searchResult?.total > 0 && (
            <Pagination
              innerClass="pagination"
              activePage={offset}
              activeClass="active"
              itemsCountPerPage={itemsCountPerPage}
              totalItemsCount={searchResult?.total > 9987 ? 9987 : searchResult?.total|| 0}
              pageRangeDisplayed={pageRangeDisplayed}
              handleMultiplePageChange={handleMultiplePageChange}
              handleSinglePageChange={handleSinglePageChange}
              handleSelfPageChange={handleSelfPageChange}
              prevPageText={<SvgIconHandler type="arrow" width="16" height="16" />}
              nextPageText={<SvgIconHandler type="arrow" width="16" height="16" />}
              firstPageText={<SvgIconHandler type="double-arrow" width="16" height="16" />}
              lastPageText={<SvgIconHandler type="double-arrow" width="16" height="16" />}
              disabledClass="disable"
              hideDisabled={false}
              hideFirstLastPages={false}
            />
          )}
        </div>
        <div styleName="separartor"></div>
        <div styleName="right-column">
          <div styleName="top-ad-container">
            <DfpComponent
              adStyleName="ad-slot-size-300x600"
              id={`search-page-row-ad-row-ad1`}
              path={adConfig.ad_unit}
              size={adConfig.sizes}
              viewPortSizeMapping={adConfig.view_port_size_mapping}
            />
          </div>
          <div>
            <h2 styleName="trending-stories-text">TRENDING STORIES</h2>
            <div styleName="stories-trendings">
              {trendingStories?.map((story, index) => (
                <Fragment key={index}>
                  <StoryComponent story={story.story} type="secondary" />
                  {index !== trendingStories.length - 1 && <div styleName="horizontal-separator"></div>}
                </Fragment>
              ))}
            </div>
          </div>

          <div styleName="top-ad-container">
            <DfpComponent
              adStyleName="ad-slot-size-300x600"
              id={`search-page-row-ad-row-ad2`}
              path={adConfig.ad_unit}
              size={adConfig.sizes}
              viewPortSizeMapping={adConfig.view_port_size_mapping}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {loading && <Loading />}
      <StoriesWrapper />
      <div styleName="bottom-ad-container">
        <DfpComponent
          adStyleName="ad-slot-size-970x250"
          id={`search-page-row-bottom-ad`}
          path={bottomAdConfig.ad_unit}
          size={bottomAdConfig.sizes}
          viewPortSizeMapping={bottomAdConfig.view_port_size_mapping}
        />
      </div>
    </>
  );
};

const arePropsEqual = (prevProps, nextProps) => {
  return prevProps?.loading === nextProps?.loading;
};

export default memo(SearchResults, arePropsEqual);
