import React from "react";
import { getSectionNameFromSectionId } from "../../utils/utils";

import "./search-page.m.css";

const Dropdown = ({
  config = {},
  customClass,
  label,
  options = [],
  customHandleChange,
  value,
  sectionId,
  flag,
  childSections = [],
}) => {
  return (
    <div styleName={customClass}>
      <span styleName="label">{label}</span>
      {flag === "filterBy" && (
        <>
          {!sectionId && (
            <select name="section" id="section" onChange={customHandleChange} value={value}>
              <option value="">All</option>
              {options?.map((option, index) => {
                return (
                  <option key={`${index}-${option}`} value={option}>
                    {getSectionNameFromSectionId(config, option)}
                  </option>
                );
              })}
            </select>
          )}

          {sectionId && (
            <select name="section" id="section" onChange={customHandleChange} value={value}>
              <option
                value={`${sectionId}${
                  childSections.length > 0 ? "," + childSections?.map((section) => section?.id).join(",") : ""
                }`}
              >
                All
              </option>
              {childSections?.map((section, index) => {
                return (
                  <option key={`${index}-${section?.name}`} value={section?.id}>
                    {section?.name}
                  </option>
                );
              })}
            </select>
          )}
        </>
      )}

      {flag === "sortBy" && (
        <select name="section" id="section" value={value} onChange={customHandleChange}>
          <option value="latest-published">Latest</option>
          <option value="score">Relevance</option>
        </select>
      )}

      {(flag === "cuisine" || flag === "prepTime" || flag === "cookTime") && (
        <select name="section" id="section" onChange={customHandleChange} value={value}>
          <option value="All">All</option>
          {options.map((cuisine, index) => (
            <option key={index} value={cuisine}>
              {cuisine}
            </option>
          ))}
        </select>
      )}
    </div>
  );
};

export default Dropdown;
