import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import get from "lodash/get";
import { MagazineEdition } from "../magazine-edition";
import { DfpComponent } from "../../ads/dfp-component";
import FilterMagazineByMonthYear from "./magazine-month-year";
import "./magazine-listing-page.m.css";

export function MagazineListingPage({ data = {}, config = {} }) {
  const sketchesHost = config["sketches-host"];

  let currPath = useSelector((state) => get(state, ["qt", "currentPath"], ""))
    .split("?")[0]
    .split("/")[1];
  let pageName = useSelector((state) =>
    get(state, ["qt", "config", "ads-config", "ad_name_mapping", currPath], "sections")
  );
  const networkId = useSelector((state) => get(state, ["qt", "config", "ads-config", "network_id"], ""));

  const BottomAdUnit = `${networkId}/GULFNEWS/Gulfnews_BTF/gulfnews_${pageName}_btf/gulfnews_${pageName}_btf_footer`;
  const adConfig = useSelector((state) =>
    get(state, ["qt", "config", "ads-config", "slots", "section_page_adslot_3"], {})
  );
  const { data: collectionData, magazineSlug } = data;
  const [showSearchResults, setShowSearchResults] = useState(false);
  const [showLoadmore, setShowLoadmore] = useState(false);

  const [offset, setOffset] = useState(8);

  const [editions, setEditions] = useState(collectionData?.length > 8 ? collectionData.slice(0, 8) : collectionData);

  const getLoadMoreEntityCollection = async () => {
    const limit = 8;
    const entityUrl = `/api/v1/magazines/${magazineSlug}/issues?limit=${limit}&offset=${offset}&sort-by=issue-date&order=desc`;
    try {
      const response = await fetch(entityUrl);
      const result = await response.json();
      const newEditions = result && result.data;

      setEditions([...editions, ...newEditions]);
      setOffset((prevOffset) => prevOffset + 8);
    } catch (err) {
      console.error(`failed to get other editions data: ${err}`);
    }
  };

  const handleLoadMore = async () => {
    await getLoadMoreEntityCollection();
  };
  useEffect(() => {
    editions?.length >= offset ? setShowLoadmore(true) : setShowLoadmore(false);
  }, [offset]);

  return (
    <div styleName="magazine-list-wrapper">
      <FilterMagazineByMonthYear
        magazineSlug={magazineSlug}
        setEditions={setEditions}
        setShowSearchResults={setShowSearchResults}
        sketchesHost={sketchesHost}
      />
      {editions?.length ? (
        <div styleName="magazine-list">
          <MagazineEdition data={editions} sketchesHost={sketchesHost} />
        </div>
      ) : (
        <div styleName="no-magazine">No Magazines Found!</div>
      )}
      {!showSearchResults && showLoadmore && (
        <div styleName="load-more">{<button onClick={handleLoadMore}>LOAD MORE</button>}</div>
      )}

      <div styleName="bottom-ad-container">
        <DfpComponent
          adStyleName="ad-slot-size-970x250"
          id={`magazine-bottom-ad`}
          path={BottomAdUnit}
          size={adConfig.sizes}
          viewPortSizeMapping={adConfig.view_port_size_mapping}
        />
      </div>
    </div>
  );
}
