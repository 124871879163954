import React, { useState, useEffect } from "react";
import { object, array, string, bool } from "prop-types";
import { useSelector } from "react-redux";
import get from "lodash/get";
import { StoryGrid } from "./story-grid/StoryGrid";
import { useDeviceType } from "../../../custom-hooks/useDeviceType";
import { DfpComponent } from "../../../ads/dfp-component";
import { HeroImage } from "../../../atoms/HeroImage";
import { PublishAndReadTime } from "../../../atoms/PublishAndReadTime";
import { BreadCrumbs } from "../../../story-templates/atoms";
import PageTitle from "../../../atoms/PageTitle";
import { getStoryHeadline } from "../../../utils/story";
import { Link } from "@quintype/components";
import { TrendingStories } from "../trending-stories/TrendingStories";

import "./photo-default-page.m.css";

const Photodefault = (props) => {
  const deviceType = useDeviceType();
  const isDesktop = deviceType === "desktop";
  const isMobile = deviceType === "mobile";
  let currPath = useSelector((state) => get(state, ["qt", "currentPath"], ""))
    .split("?")[0]
    .split("/")[1];
  let pageName = useSelector((state) =>
    get(state, ["qt", "config", "ads-config", "ad_name_mapping", currPath], "sections")
  );
  const networkId = useSelector((state) => get(state, ["qt", "config", "ads-config", "network_id"], ""));
  const rightTopAdUnit = `${networkId}/GULFNEWS/Gulfnews_ATF/gulfnews_${pageName}_atf/gulfnews_${pageName}_right_atf`;
  const rightBottomAdUnit = `${networkId}/GULFNEWS/Gulfnews_BTF/gulfnews_${pageName}_btf/gulfnews_${pageName}_btf_right`;
  const bottomAdUnit = `${networkId}/GULFNEWS/Gulfnews_BTF/gulfnews_${pageName}_btf/gulfnews_${pageName}_btf_footer`;
  const adConfig1 = useSelector((state) =>
    get(state, ["qt", "config", "ads-config", "slots", "section_page_adslot_1"], {})
  );
  const adConfig2 = useSelector((state) =>
    get(state, ["qt", "config", "ads-config", "slots", "section_page_adslot_2"], {})
  );
  const adConfig3 = useSelector((state) =>
    get(state, ["qt", "config", "ads-config", "slots", "section_page_adslot_3"], {})
  );
  const itemsPath = props.isMagazine ? ["data"] : ["data", "collection", "items"];
  const list = get(props, itemsPath) || [];

  const stories = list?.filter((item) => item.type === "story").map((ele) => ele.story);

  const [visibleItems, setVisibleItems] = stories?.length > 19 ? useState(19) : useState(stories?.length + 1);
  const imageUrl =
    get(stories[0], ["hero-image-s3-key"], "") ||
    get(stories[0], ["alternative", "home", "default", "hero-image", "hero-image-s3-key"], "");
  const loadMoreStories = () => {
    setVisibleItems((prevVisibleItems) =>
      stories.length > prevVisibleItems + 6 ? prevVisibleItems + 6 : stories.length
    );
  };
  const noOfElementsWithBorder = isMobile
    ? visibleItems - 4 + ((visibleItems - 1) % 2)
    : visibleItems - 5 + ((visibleItems - 1) % 3);
  const removeLeadingSlash = (path) => {
    return path.startsWith("/") ? path.slice(1) : path;
  };
  let currentPath = "";
  if (typeof window !== "undefined" && window.location) {
    currentPath = removeLeadingSlash(window.location.pathname);
  }
  return (
    <>
      <div styleName="breadcrumb-photo-wrapper">
        <BreadCrumbs currentPath={currentPath} />
      </div>
      <div styleName="photo-section-page-wrapper">
        <div className="container" styleName="wrapper">
          <div
            styleName="sectionContainer"
            style={{
              width: stories.length === 0 ? "calc(100% - 250px)" : "",
              display: stories.length === 0 ? "flex" : "",
              flexDirection: stories.length === 0 ? "column" : "",
            }}
          >
            {stories?.length > 0 && (
              <div styleName="firstStory">
                <Link href={`/${stories?.[0]?.slug}`}>
                  <HeroImage
                    story={stories[0]}
                    headline={stories?.[0]?.headline}
                    aspectRatio={[[4, 3]]}
                    iconSizes={isMobile ? { height: 32, width: 32 } : { height: 64, width: 64 }}
                    imageUrl={imageUrl}
                  />
                  <h1>{getStoryHeadline(stories?.[0])}</h1>
                  {stories?.[0]?.subheadline && <h2>{stories?.[0]?.subheadline}</h2>}
                  <div styleName="row-separator"></div>
                </Link>
              </div>
            )}
            {!isDesktop && (
              <div styleName="adSlot1">
                <DfpComponent
                  adStyleName={"ad-slot-size-300x250-constant"}
                  id="section-page-ad1"
                  path={rightTopAdUnit}
                  size={adConfig1.sizes}
                  viewPortSizeMapping={adConfig1.view_port_size_mapping}
                />
              </div>
            )}
            <div styleName="storyGrid">
              {stories?.length > 0 &&
                stories
                  ?.slice(1, visibleItems)
                  .map((story, index) => (
                    <StoryGrid
                      key={story?.id}
                      story={story}
                      leftImage={true}
                      showBorder={noOfElementsWithBorder - index >= 0 ? true : false}
                    />
                  ))}
            </div>

            {visibleItems < stories?.length && (
              <div styleName="loadmore-button-wrapper">
                <button styleName="loadmore-button" onClick={loadMoreStories}>
                  LOAD MORE
                </button>
              </div>
            )}
          </div>
          <div styleName="ad-container">
            {isDesktop && (
              <div styleName="adSlot1">
                <DfpComponent
                  adStyleName={"ad-slot-size-300x600"}
                  id="section-page-ad1"
                  path={rightTopAdUnit}
                  size={adConfig1.sizes}
                  viewPortSizeMapping={adConfig1.view_port_size_mapping}
                />
              </div>
            )}
            {!isDesktop && (
              <div styleName="adSlot2">
                <DfpComponent
                  adStyleName="ad-slot-size-300x250-constant"
                  id="section-page-ad2"
                  path={rightBottomAdUnit}
                  size={adConfig2.sizes}
                  viewPortSizeMapping={adConfig2.view_port_size_mapping}
                />
              </div>
            )}
            <TrendingStories
              showHeroImage={true}
              isPhotoSectioPage={true}
              defaultFallback={true}
              showReadTime={false}
              showLabel={true}
              showTimestamp={false}
            />
            {isDesktop && (
              <div styleName="adSlot2">
                <DfpComponent
                  adStyleName="ad-slot-size-300x600"
                  id="section-page-ad2"
                  path={rightBottomAdUnit}
                  size={adConfig2.sizes}
                  viewPortSizeMapping={adConfig2.view_port_size_mapping}
                />
              </div>
            )}
          </div>
        </div>
        <div styleName="adSlot3">
          <DfpComponent
            adStyleName={"ad-slot-size-970x250"}
            id="section-page-bottom-ad"
            path={bottomAdUnit}
            size={adConfig3.sizes}
            viewPortSizeMapping={adConfig3.view_port_size_mapping}
          />
        </div>
      </div>
    </>
  );
};
Photodefault.propTypes = {
  data: object,
  story: array,
  type: string,
  isSubHeadlineToShow: bool,
  isImageToShow: bool,
  show_timestamp: bool,
  show_read_time: bool,
};

export { Photodefault };
