import React, { useState, useEffect } from "react";
import get from "lodash/get";
import { useSelector } from "react-redux";
import { Link } from "@quintype/components";
import countryCities, { CitiesMapping } from "./components/country-city-data";
import { NextPrayerTime } from "./components/next-prayer-time";
import PrayerTimesTable, { CalculateImsakTime } from "./components/PrayerTImeTable";
import MonthDropdown from "./components/month-dropdown";
import BottomText from "./components/bottom-text";
import { LatestStories } from "./components/LatestStories";
import { DfpComponent } from "../../ads/dfp-component";
import SocialShareIcons from "../../story-templates/atoms/SocialShareIcons";
import "./prayer-page.m.css";
import PrayerTimeBox from './components/PrayerTimeBox';
import moment from "moment";


export const PrayerPage = () => {
  const today = new Date();
  const qtConfig = useSelector((state) => get(state, ["qt"], {}));
  const domain = get(qtConfig, ["currentPath"], "");
  const baseUrl = useSelector((state) => get(state, ["qt", "config", "sketches-host"], ""));

  const getInitialCountry = () => {
    const segments = domain?.split("/");
    const slug = segments[segments?.length - 1];
    switch (slug) {
      case "saudi-arabia-prayer-times":
        return "Saudi";
      case "kuwait-prayer-times":
        return "Kuwait";
      case "oman-prayer-times":
        return "Oman";
      case "bahrain-prayer-times":
        return "Bahrain";
      case "qatar-prayer-times":
        return "Qatar";
      default:
        return "UAE";
    }
  };


  const [state, setState] = useState({
    selectedCountry: getInitialCountry(),
    selectedCity: countryCities[getInitialCountry()]?.cities[0],
    cityPrayerTodayTime: {},
    cityPrayerData: {},
    error: false,
    selectedMonth: { monthNo: today?.getMonth() + 1, monthName: today?.toLocaleString("default", { month: "long" }) },
    currentPrayerTimeMonth: today?.getMonth() + 1,
    startDate: today?.getDate(),
    currentPrayer: { time: "fajr", note: "" },
    eid: false
  });

  function getPrayerTimeList() {
    let prayerTimesBox1 = [
      { name: "fajr", period: "AM", note: "" },
      { name: "sunrise", period: "AM", note: "" }
    ];
    let  prayerTimesBox2 = [
      { name: "dhuhr", period: "", note: "" },
      { name: "asr", period: "PM", note: "" },
      { name: "maghrib", period: "PM", note: "" },
      { name: "isha", period: "PM", note: "" },
    ]
    if (!state.eid?.[state.selectedCity]) {
      return  prayerTimesBox1.concat(prayerTimesBox2);
    }

    const eidPrayerTime = state.eid[state.selectedCity];
    const now = new Date();
    const eidTime = new Date(eidPrayerTime);
    const eidDate = new Date(eidTime.toDateString()); // Eid date without time

    const tomorrow = new Date();
    tomorrow.setDate(now.getDate() + 1);
    tomorrow.setHours(0, 0, 0, 0);


    // If Eid is tomorrow
    if (now.getHours() >= 18 && eidDate.getTime() === tomorrow.getTime() ) { // if eid tomorrow and isha time not passed
        if( state.cityPrayerTodayTime.day == now.getDate() ) { // Add at the end of prayer time list
            prayerTimesBox2.push({ name: "eid", period: "AM", note: "" });
        }
        else {
            prayerTimesBox1.push({ name: "eid", period: "AM", note: "" }); // add aftere sunrise
        }
    }
    else {
      eidTime.setMinutes(eidTime.getMinutes() + 15 ); // Show the prayer time until 15 minutes after it ends
      if (now.toDateString() === eidDate.toDateString() && now < eidTime ) {
        prayerTimesBox1.push({ name: "eid", period: "AM", note: "" });
      }
    }
    return prayerTimesBox1.concat(prayerTimesBox2);
  }

  function showImsak() {
    return state?.cityPrayerTodayTime?.["hijri_month"] == 'Ramadan';
  }

  useEffect(() => {
    const fetchInitialData = async () => {
      await fetchPrayerTimes(state?.selectedCity, state?.currentPrayerTimeMonth, state?.startDate, true);
    };
    fetchInitialData();
  }, []);

  const formattedTodaysDate = today?.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
  let currPath = useSelector((state) => get(state, ["qt", "currentPath"], ""))
    .split("?")[0]
    .split("/")[1];
  let pageName = useSelector((state) =>
    get(state, ["qt", "config", "ads-config", "ad_name_mapping", currPath], "sections")
  );
  const networkId = useSelector((state) => get(state, ["qt", "config", "ads-config", "network_id"], ""));
  const rightTopAdUnit = `${networkId}/GULFNEWS/Gulfnews_ATF/gulfnews_${pageName}_atf/gulfnews_${pageName}_right_atf`;
  const rightBottomAdUnit = `${networkId}/GULFNEWS/Gulfnews_BTF/gulfnews_${pageName}_btf/gulfnews_${pageName}_btf_article`;
  const BottomAdUnit = `${networkId}/GULFNEWS/Gulfnews_BTF/gulfnews_${pageName}_btf/gulfnews_${pageName}_btf_footer`;

  const adConfig1 = useSelector((state) =>
    get(state, ["qt", "config", "ads-config", "slots", "section_page_adslot_1"], {})
  );
  const adConfig2 = useSelector((state) =>
    get(state, ["qt", "config", "ads-config", "slots", "section_page_adslot_2"], {})
  );
  const adConfig3 = useSelector((state) =>
    get(state, ["qt", "config", "ads-config", "slots", "section_page_adslot_3"], {})
  );


  const isDateValidInCurrentMonth = (year, month, day) => {
    const testDate = new Date(year, month - 1, day);
    return testDate.getMonth() === month - 1;
  };
  const getMonthName = (monthIndex) => {
    return new Intl.DateTimeFormat("en-US", { month: "long" }).format(new Date(2024, monthIndex));
  };
  const fetchPrayerTimes = async (city, month, date, currentMonth) => {
    try {
      let year = today.getFullYear();
      const response = await fetch(
        `/prayer-time?country=${countryCities[
          state?.selectedCountry
        ].countryName.toLowerCase()}&city=${city?.toLowerCase()}&month=${month}&year=${year}`
      );
      let data = await response.json();
      if(currentMonth) {
        let currentPrayer = NextPrayerTime(data["date" + date]);
        let tomorrow = null;
        if (currentPrayer?.time === "") {

          tomorrow = moment().add(1, 'days').toDate();
          const newResponse = await fetch(
            `/prayer-time?country=${countryCities[
              state?.selectedCountry
            ].countryName.toLowerCase()}&city=${city?.toLowerCase()}&month=${tomorrow.getMonth() + 1}&year=${tomorrow.getFullYear()}`
          );
          setState((prevState) => ({
            ...prevState,
            selectedMonth: { monthNo: tomorrow.getMonth() + 1, monthName: getMonthName(tomorrow.getMonth()) },
          }));
          data = await newResponse.json();
          date = tomorrow.getDate();
        }
        setState((prevState) => ({
          ...prevState,
          cityPrayerTodayTime: data?.["date" + date],
          cityPrayerData: data,
          startDate: date,
          selectedCity: city,
          currentPrayerTimeMonth: tomorrow !== null ?  tomorrow.getMonth() + 1 : prevState?.currentPrayerTimeMonth,
          selectedMonth: tomorrow !== null ? { monthNo: tomorrow.getMonth() + 1, monthName: getMonthName(tomorrow.getMonth()) } :
              { monthNo: prevState?.currentPrayerTimeMonth, monthName: getMonthName(prevState?.currentPrayerTimeMonth - 1) },
          currentPrayer: NextPrayerTime(data["date" + date]),
          error: false,
          eid: data?.["eid"]
        }));

        /*if (!isDateValidInCurrentMonth(today.getFullYear() + 1, month, date + 1)) {
            console.log('validate current month');
            date = 1;
            year = month == 12 ? year + 1 : year;
            month = month == 12 ? 1 : month + 1;
            const newResponse = await fetch(
              `/prayer-time?country=${countryCities[
                state?.selectedCountry
              ].countryName.toLowerCase()}&city=${city?.toLowerCase()}&month=${month}&year=${year}`
            );
            setState((prevState) => ({
              ...prevState,
              selectedMonth: { monthNo: month, monthName: getMonthName(month - 1) },
            }));
            data = await newResponse.json();
          } else {
            console.log('data = ', date);
            date = date + 1;
          }*/
      }
      else {

        setState((prevState) => ({
          ...prevState,
          cityPrayerData: data,
          selectedMonth: { monthNo: month, monthName: getMonthName(month - 1) },
          error: false,
          eid: data?.["eid"]
        }));

      }


    } catch (error) {
      console.error("Error fetching prayer times:", error);
      setState((prevState) => ({
        ...prevState,
        error: true,
      }));
    }
  };

  const handleMonthChange = (newMonth, monthName) => {
    setState((prevState) => ({ ...prevState, selectedMonth: { monthNo: newMonth, monthName: monthName } }));
    fetchPrayerTimes(
      state?.selectedCity,
      newMonth,
      newMonth != today?.getMonth() + 1 ? 1 : today?.getDate(),
      false
    );
  };

  const handleCountryChange = async (country) => {
    const slugMap = {
      Saudi: "saudi-arabia-prayer-times",
      Kuwait: "kuwait-prayer-times",
      Oman: "oman-prayer-times",
      Bahrain: "bahrain-prayer-times",
      Qatar: "qatar-prayer-times",
      UAE: "prayer-times",
    };
    window.location.href = `/prayer-times/${slugMap[country]}`;
  };

  const handleCityChange = (city) => {
    //setState((prevState) => ({ ...prevState, selectedCity: city }));
    fetchPrayerTimes(city, state?.currentPrayerTimeMonth, state?.startDate, true);
  };

  const story = {
    url: `${baseUrl}${domain}`,
    headline: "Gulf News | Muslim Prayer, Salah, Namaz Timings in UAE",
  };

  const imageDimension = {
    width: "42px",
    height: "42px",
    viewbox: "0 0 42 42",
  };

  if (state?.error) return null;
  const startDate = today?.getMonth() + 1 != state?.selectedMonth.monthNo ? 1 : state?.startDate;
  return (
    <div styleName="prayer-page-container">
      <div styleName="prayer-times-wrapper">
        <div styleName="prayer-times-left-container">
          <div styleName="prayer-times-date">
            <h2>
              {state?.cityPrayerTodayTime?.hijri_month && `${state?.cityPrayerTodayTime.hijri_month} `}
              {state?.cityPrayerTodayTime?.hijri_day && `${state?.cityPrayerTodayTime.hijri_day}, `}
              {state?.cityPrayerTodayTime?.hijri_year}
            </h2>
            <p>{formattedTodaysDate}</p>
          </div>
          <div styleName="prayer-times-top-box">
            <div styleName="country-tabs">
              {Object.keys(countryCities)?.map((country) => (
                <button
                  key={country}
                  onClick={() => handleCountryChange(country)}
                  styleName={country === state?.selectedCountry ? "active" : ""}
                >
                  {country}
                </button>
              ))}
            </div>
            <div styleName="city-tabs">
              {countryCities[state?.selectedCountry]?.cities?.map((city) => (
                <button
                  key={city}
                  onClick={() => handleCityChange(city)}
                  styleName={city === state?.selectedCity ? "active" : ""}
                >
                  {CitiesMapping[city]}
                </button>
              ))}
            </div>
            <div styleName="prayer-times">
              {state?.cityPrayerTodayTime && (
                <>
                  {showImsak() && (
                    <div
                      key="Imsak"
                      styleName={`prayer-time-box`}
                    >
                      <div>
                        <div>Imsak</div>
                        <div>
                          {CalculateImsakTime(state?.cityPrayerTodayTime["fajr"]) || ""}
                          {Number(state?.cityPrayerTodayTime["fajr"]?.split(":")[0]) < 12}
                          <p>AM</p>
                        </div>
                      </div>
                    </div>
                  )}
                  {getPrayerTimeList()?.map(({ name, period }) => (
                      <PrayerTimeBox name={name} period={period} state={state} />
                  ))}
                </>
              )}
            </div>
          </div>

          <div styleName="prayer-times-social">
            <div styleName="prayer-times-link">
              <Link href="https://gulfnews.com/ramadan-prayer-timings" target="_self">
                Ramadan 2025 Prayer Timetable for UAE
              </Link>
            </div>
            <SocialShareIcons story={story} imageDimension={imageDimension} />
          </div>
          <div>
            <div styleName="prayer-times-table-header">
              <div>
                <h2>{`${state?.selectedMonth?.monthName} ${today?.getFullYear()}`} </h2>
                <p>
                  {state?.cityPrayerData?.['date' + startDate]?.["hijri_month"]} {state?.cityPrayerData?.['date' + startDate]?.["hijri_year"]}
                </p>
              </div>
              <div>
                <p>Select a month</p>
                <MonthDropdown handleMonthChange={handleMonthChange} selectedMonth={state?.selectedMonth?.monthNo} />
              </div>
            </div>
            <div
              styleName="prayer-times-table"
              style={{
                marginBottom: state?.selectedCountry !== "UAE" ? "0px" : undefined,
                borderBottom: state?.selectedCountry !== "UAE" ? "0px" : undefined,
              }}
            >
              <PrayerTimesTable
                prayerData={state?.cityPrayerData}
                startDay={ startDate }
                currentMonth={state?.selectedMonth?.monthName}
              />
            </div>
            {state?.selectedCountry === "UAE" && (
              <div styleName="prayer-times-table-bottom-text">
                <BottomText />
              </div>
            )}
          </div>
        </div>
        <div styleName="prayer-times-right-container">
          <div styleName="prayer-times-table-right-top-ad">
            <DfpComponent
              adStyleName="ad-slot-size-300x600"
              id="prayer-page-right-top-ad"
              path={rightTopAdUnit}
              size={adConfig1?.sizes}
              viewPortSizeMapping={adConfig1?.view_port_size_mapping}
            />
          </div>
          <LatestStories />
          <div styleName="prayer-times-table-right-bottom-ad">
            <DfpComponent
              adStyleName="ad-slot-size-300x250-constant"
              id="prayer-page-right-bottom-ad"
              path={rightBottomAdUnit}
              size={adConfig2?.sizes}
              viewPortSizeMapping={adConfig3?.view_port_size_mapping}
            />
          </div>
        </div>
      </div>
      <div styleName="prayer-times-table-bottom-ad">
        <DfpComponent
          adStyleName="prayer-page-bottom-ad-slot"
          id="prayer-page-bottom-ad"
          path={BottomAdUnit}
          size={adConfig3?.sizes}
          viewPortSizeMapping={adConfig3?.view_port_size_mapping}
        />
      </div>
    </div>
  );
};

PrayerPage.propTypes = {};
