const countryCities = {
  UAE: {
    countryName: "united_arab_emirates",
    cities: ["dubai", "abu_dhabi", "ras_al_khaimah", "fujairah", "sharjah", "ajman", "al_ain", "umm_al_quwain"],
  },
  Saudi: {
    countryName: "saudi_arabia",
    cities: ["riyadh", "makkah", "dammam"],
  },
  Kuwait: {
    countryName: "kuwait",
    cities: ["kuwait_city"],
  },
  Oman: {
    countryName: "oman",
    cities: ["muscat"],
  },
  Bahrain: {
    countryName: "bahrain",
    cities: ["manama"],
  },
  Qatar: {
    countryName: "qatar",
    cities: ["doha"],
  },
};

export default countryCities;

export const CitiesMapping = {
  dubai: "Dubai",
  abu_dhabi: "Abu Dhabi",
  ras_al_khaimah: "RAK",
  fujairah: "Fujairah",
  sharjah: "Sharjah",
  ajman: "Ajman",
  al_ain: "Al Ain",
  umm_al_quwain: "Umm Al Quwain",
  riyadh: "Riyadh",
  makkah: "Makkah",
  dammam: "Dammam",
  kuwait_city: "Kuwait City",
  muscat: "Muscat",
  manama: "Manama",
  doha: "Doha",
};
