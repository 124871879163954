import React, { useState } from "react";
import { string, shape, object } from "prop-types";
import get from "lodash/get";
import { LazyCollection, collectionToStories, LazyLoadImages } from "@quintype/components";
import { getCollectionTemplate } from "../../get-collection-template";
import { getCollectionFromCollections } from "../../utils/collection";
import { DefaultPage } from "./default-page/default";
import { SearchBar } from "../search-page/SearchBar";
import { Photodefault } from "./photo-default-page/Photodefault";
import GameSectionPage from "./games/GameSectionPage";
import PlayGamesPage from "./games/components/PlayGamesPage";
import { BreadCrumbs } from "../../story-templates/atoms";
import RecipeSectionPage from "./recipe-page/RecipeSectionPage";
import FourColGridSection from "./custom-section-templates/four-col-grid-section";
import VerticalListWithLabels from "./custom-section-templates/vertical-list-with-labels";

import "./section.m.css";

const SectionPage = (props) => {
  const [showSearchResults, setShowSearchResults] = useState(false);

  const slug = get(props, ["data", "collection", "slug"], null);

  const customTemplate =
    props.pageType === "magazine-landing-page"
      ? get(props, ["data", "data", "metadata", "section-page-default-template", 0, "key"], null)
      : get(props, ["data", "collection", "metadata", "section-page-default-template", 0, "key"], null);
  const collections = getCollectionFromCollections(props.data.collection, 1);
  const stories =
    (props?.data?.collection && props?.data?.collection?.items && collectionToStories(props?.data?.collection)) || [];
  const itemsPath = props.pageType === "magazine-landing-page" ? ["data", "items"] : ["data", "collection", "items"];
  const items = get(props, itemsPath) || [];

  const childCollections = items.filter(
    (item) => item?.type === "collection" && (item?.items?.length > 0 || item?.["associated-metadata"]?.playlist_id)
  );

  childCollections?.forEach((collection) => {
    if (collection?.["associated-metadata"]?.playlist_id) {
      collection.sectionSlug = props?.data?.section?.slug;
    }
  });

  const noStoriesFound =
    props.pageType === "collection-page" ? "No Collection Stories Found" : "No Section Stories Found !!!";

  const removeLeadingSlash = (path) => {
    return path.startsWith("/") ? path.slice(1) : path;
  };

  let currentPath = "";
  if (typeof window !== "undefined" && window.location) {
    currentPath = removeLeadingSlash(window.location.pathname);
  }

  const checkForSearchBar = (slug) => {
    switch (slug) {
      case "friday":
      case "best-buys":
      case "your-money":
      case "parenting":
      case "videos":
      case "recipe":
      case "living-in-uae":
        return true;
      default:
        return false;
    }
  };

  const getChildSections = () => {
    const slug = props?.data?.section?.slug;
    const currentSection = props?.config?.sections?.find((section) => section.slug === slug);
    const allChildSections = props?.config?.sections.filter((section) => section?.["parent-id"] === currentSection.id);
    return allChildSections;
  };

  const showSearchBar = checkForSearchBar(props?.data?.section?.slug);

  if (!childCollections && !stories) {
    return <h1>{noStoriesFound}</h1>;
  }

  const CustomTemplate = ({ customTemplate }) => {
    const photoItemsPath = props.pageType === "magazine-landing-page" ? ["data", "items"] : ["data"];
    const photoItems = get(props, photoItemsPath) || [];
    const fourColItemsPath = props.pageType === "magazine-landing-page" ? ["data", "items"] : ["data", "collection"];
    const fourColItems = get(props, fourColItemsPath) || [];
    const isMagazine = props.pageType === "magazine-landing-page";
    switch (customTemplate) {
      case "vertical-list-with-labels":
        return <VerticalListWithLabels props={props} type={"label"} />;
      case "vertical-list-with-sections":
        return <VerticalListWithLabels props={props} />;
      case "photo-default-section":
        return <Photodefault data={photoItems} isMagazine={isMagazine} />;
      case "four-col-grid-section":
        return <FourColGridSection collection={fourColItems} isMagazine={isMagazine} />;
      default:
        return (
          <DefaultPage
            data={props?.data}
            config={props.config}
            removeLeadingSlash={removeLeadingSlash}
            pageType={props.pageType}
          />
        );
    }
  };

  const DefaultTemplate = ({ props }) =>
    collections.length === 0 ? (
      props?.data?.section?.slug === "photos" ? (
        <Photodefault data={props.data} />
      ) : slug === "games" ? (
        <GameSectionPage data={props.data} />
      ) : slug === "sudoku-games" ? (
        <PlayGamesPage title="sudoku" />
      ) : slug === "crossword-games" ? (
        <PlayGamesPage title="crossword" />
      ) : slug === "spell-it-games" ? (
        <PlayGamesPage title="spellIt" />
      ) : slug === "word-search-games" ? (
        <PlayGamesPage title="wordSearch" />
      ) : (
        <DefaultPage data={props.data} removeLeadingSlash={removeLeadingSlash} />
      )
    ) : props?.data?.section?.slug === "food" ? (
      <>
        <div styleName="search-bar-container">
          <SearchBar
            config={props.config}
            showResultsOnButtonClick={true}
            sectionId={props?.data?.section?.id}
            sectionName={props?.data?.section?.name}
            setShowSearchResults={setShowSearchResults}
            showSearchResults={showSearchResults}
            childSections={getChildSections()}
          />
        </div>
        {!showSearchResults && (
          <div>
            <RecipeSectionPage data={props.data} />
          </div>
        )}
      </>
    ) : (
      <>
        <div styleName="breadCrumbs-wrapper">
          <BreadCrumbs currentPath={currentPath} />
        </div>
        {showSearchBar && (
          <div styleName="search-bar-container">
            <SearchBar
              config={props.config}
              showResultsOnButtonClick={true}
              sectionId={props?.data?.section?.id}
              sectionName={props?.data?.section?.slug}
              setShowSearchResults={setShowSearchResults}
              showSearchResults={showSearchResults}
              childSections={getChildSections()}
            />
          </div>
        )}
        {!showSearchResults && (
          <LazyLoadImages>
            <LazyCollection
              collection={{ items: childCollections }}
              collectionTemplates={getCollectionTemplate}
              lazyAfter={2}
              extraProp={props?.data?.section?.slug}
            />
          </LazyLoadImages>
        )}
      </>
    );
  return (
    <div className="section-page-wrapper" styleName="section-container">
      {!childCollections.length > 0 && customTemplate ? (
        <CustomTemplate customTemplate={customTemplate} />
      ) : (
        <DefaultTemplate props={props} />
      )}
    </div>
  );
};

SectionPage.propTypes = {
  pageType: string,
  data: shape({
    collection: object,
    section: string,
  }),
};

export { SectionPage };
