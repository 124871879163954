import React, { useState } from "react";
import { object } from "prop-types";
import GamesHomePage from "./components/GamesHomePage";
import PlayGamesPage from "./components/PlayGamesPage";

import "./GameSectionPage.m.css";

const GameSectionPage = (props) => {
  const [gameDetails, setGameDetails] = useState({
    GameName: "",
    IsPlaying: false,
    GameImage: "sudoku",
    GameBackgroundColor: "",
    GameDifficultyLevel: "",
    GameDate: "",
  });
  const stories = props?.data?.collection?.items?.map((item) => item?.story);
  return (
    <>
      {!gameDetails.IsPlaying ? (
        <GamesHomePage setGameDetails={setGameDetails} collection={props?.data?.collection} stories={stories} />
      ) : (
        <PlayGamesPage
          bgcolor={gameDetails.GameBackgroundColor}
          image={gameDetails.GameImage}
          title={gameDetails.GameName}
          GameDifficultyLevel={gameDetails.GameDifficultyLevel}
          GameDate={gameDetails.GameDate}
          isplaying={gameDetails.IsPlaying}
        />
      )}
    </>
  );
};

GameSectionPage.propTypes = {
  props: object,
};

export default GameSectionPage;
