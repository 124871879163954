import React from "react";
import PropTypes from "prop-types";
import { FourColGrid } from "../../../../collection-templates/four-col-grid";
import ThreeColumnFiveStories from "../../../../collection-templates/three-col-five-stories";

export default function FourColGridSection({ collection, isMagazine }) {
  const firstCollItems = isMagazine ? collection?.slice(0, 5) : collection?.items?.slice(0, 5);
  const secondCollItems = isMagazine ? collection?.slice(5) : collection?.items?.slice(5);

  const firstModifiedCollection = {
    ...collection,
    items: firstCollItems,
    ["associated-metadata"]: { show_column_separator: true, show_story_separator: true, show_row_separator: true },
  };
  const secondModifiedCollection = { ...collection, items: secondCollItems };
  const stories = secondModifiedCollection?.items.map((item) => item?.story);
  return (
    <>
      <ThreeColumnFiveStories collection={firstModifiedCollection} customSectionTemplate={true} />
      <FourColGrid collection={secondModifiedCollection} stories={stories} customSectionTemplate={true} />
    </>
  );
}

FourColGridSection.propTypes = {
  collection: PropTypes.object.isRequired,
};
