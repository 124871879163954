import React from "react";
import { object, bool } from "prop-types";
import get from "lodash/get";
import { Link } from "@quintype/components";
import { PublishAndReadTime } from "../../../../atoms/PublishAndReadTime";
import { HeroImage } from "../../../../atoms/HeroImage";
import { getStoryHeadline, getStoryLabel } from "../../../../utils/story";
import { Label } from "../../../../atoms/Label";
import Headline from "../../../../atoms/Headline";
import SubHeadline from "../../../../atoms/SubHeadline";

import "../default-page.m.css";

export const HorizontalStory = ({ story, leftImage }) => {
  const headline = getStoryHeadline(story);
  const subHeadLine = story?.subheadline;
  const label = getStoryLabel(story);
  const imageUrl =
    get(story, ["hero-image-s3-key"], "") ||
    get(story, ["alternative", "home", "default", "hero-image", "hero-image-s3-key"], "");

  return (
    <div>
      <div styleName={`common-story`} key={story?.id}>
        <Link href={`/${story?.slug}`}>
          <div styleName="second-card">
            <div styleName={`left-column`} style={{ order: leftImage ? "2" : "" }}>
              {label && <div styleName="label-wrapper">{<Label story={story} />}</div>}
              {headline && <Headline headline={headline} />}
              {subHeadLine && <SubHeadline styleName="subheadline" subHeadline={subHeadLine} />}

              <PublishAndReadTime story={story} showReadTime={true} showTimestamp={true} />
            </div>{" "}
            <div styleName="vertical-story-hero-image">
              <HeroImage
                story={story}
                headline={headline}
                aspectRatio={[[4, 3]]}
                iconSizes={{ height: 24, width: 24 }}
                imageUrl={imageUrl}
              />
            </div>
          </div>
        </Link>
      </div>
      <div styleName="row-separator"></div>
    </div>
  );
};

HorizontalStory.propTypes = {
  story: object,
  leftImage: bool,
};
