export function Paginator(totalResults, currentPage = 1, perPage = 25, length = 10) {
  let totalResultsNumber = parseInt(totalResults, 10);
  let currentPageNumber = parseInt(currentPage, 10);

  const totalPages = Math.ceil(totalResultsNumber / perPage);

  if (currentPageNumber < 1) {
    currentPageNumber = 1;
  }

  if (currentPageNumber > totalPages) {
    currentPageNumber = totalPages;
  }

  let firstPage = Math.max(1, currentPageNumber - Math.floor(length / 2));

  let lastPage = Math.min(totalPages, currentPageNumber + Math.floor(length / 2));

  if (lastPage - firstPage + 1 < length) {
    if (currentPageNumber < totalPages / 2) {
      lastPage = Math.min(totalPages, lastPage + (length - (lastPage - firstPage)));
    } else {
      firstPage = Math.max(1, firstPage - (length - (lastPage - firstPage)));
    }
  }

  if (lastPage - firstPage + 1 > length) {
    if (currentPageNumber > totalPages / 2) {
      firstPage++;
    } else {
      lastPage--;
    }
  }

  let firstResult = perPage * (currentPageNumber - 1);
  if (firstResult < 0) {
    firstResult = 0;
  }

  let lastResult = perPage * currentPageNumber - 1;
  if (lastResult < 0) {
    lastResult = 0;
  }
  if (lastResult > Math.max(totalResultsNumber - 1, 0)) {
    lastResult = Math.max(totalResultsNumber - 1, 0);
  }

  return {
    totalPages: totalPages,
    pages: Math.min(lastPage - firstPage + 1, totalPages),
    currentPage: currentPageNumber,
    firstPage,
    lastPage,
    previousPage: currentPageNumber - 1,
    nextPage: currentPageNumber + 1,
    hasPreviousPage: currentPageNumber > 1,
    hasNextPage: currentPageNumber < totalPages,
    totalResults: totalResultsNumber,
    results: Math.min(lastResult - firstResult + 1, totalResultsNumber),
    firstResult,
    lastResult,
  };
}
