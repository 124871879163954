import React from "react";
import { getCollectionFromCollections, getStoriesFromCollections } from "../../../utils/collection";
import SixColumnSevenStories from "../../../collection-templates/six-col-seven-stories";
import FourColumnFourStoriesVarTwo from "../../../collection-templates/four-col-four-stories-var-two";
import { SvgIconHandler } from "../../../atoms/svg-icon-hadler";
import { DfpComponent } from "../../../ads/dfp-component";
import get from "lodash/get";
import { useSelector } from "react-redux";
import { generateNavigateSlug, navigateTo } from "../../../utils";
import { Link } from "@quintype/components";

import "./RecipeSectionPage.m.css";

const Separator = ({ image }) => {
  return (
    <div styleName="separator">
      <div styleName="separator-line">
        <SvgIconHandler type={image} width="32px" height="32px" />
      </div>
    </div>
  );
};
const Title = ({ collection, config = {} }) => {
  const qtConfig = useSelector((state) => get(state, ["qt", "config"], {}));
  const customUrlPath = collection?.metadata?.["collection-display-url"] || "";
  let collectionDisplayName = collection?.metadata?.["collection-display-name"] || collection?.name;
  const slug = customUrlPath ? customUrlPath : generateNavigateSlug(collection, { ...qtConfig, ...config });
  return (
    <Link href={slug} navigateTo={() => navigateTo(dispatch, url)}>
      <div styleName="title">
        <h1>{collectionDisplayName}</h1>
      </div>
    </Link>
  );
};

const RecipeSectionPage = ({ data }) => {
  const collections = getCollectionFromCollections(data.collection);
  const adConfig = useSelector((state) =>
    get(state, ["qt", "config", "ads-config", "slots", "recipe_section_page_bottom_ad"], {})
  );
  const fruitImages = ["strawberry", "banana", "cherry"];
  return (
    <div styleName="recipe-section-page">
      {collections[0] && (
        <div styleName="six-col-seven-stories">
          <SixColumnSevenStories
            collection={collections[0]}
            stories={getStoriesFromCollections(collections[0])}
            displayRowSeparator={false}
            displayRowTitle={false}
            displayCookTime={true}
            displayAuthorName={true}
            displayServing={true}
          />
        </div>
      )}
      {collections?.map(
        (collection, index) =>
          index !== 0 && (
            <>
              <Separator image={fruitImages[index % fruitImages?.length]} />
              <Title collection={collection} />
              <div>
                <FourColumnFourStoriesVarTwo
                  displayRowSeperator={false}
                  displayRowTitle={false}
                  displaySponsorAd={false}
                  displaySecondRow={true}
                  isRecipePage={true}
                  collection={collection}
                  stories={getStoriesFromCollections(collection)}
                />
              </div>
            </>
          )
      )}
      <div styleName="follow-us">
        <div styleName="follow-us-content">
          <h1>Follow us on Social Media</h1>
          <p>Join our community and get the latest news and recipes</p>
        </div>
        <div styleName="follow-us-icons">
          <Link target="_blank" href="https://www.facebook.com/GulfNews.UAE">
            <SvgIconHandler
              iconStyle={{ color: "#0C1215" }}
              type="facebook"
              height="48px"
              width="48px"
              viewBox="0 0 48 48"
            />
          </Link>
          <Link target="_blank" href="https://twitter.com/gulf_news">
            <SvgIconHandler iconStyle={{ color: "#0C1215" }} type="x" height="48px" width="48px" viewBox="0 0 48 48" />
          </Link>
          <Link target="_blank" href="https://www.instagram.com/gulfnews/">
            <SvgIconHandler
              iconStyle={{ color: "#0C1215" }}
              type="instagram"
              height="48px"
              width="48px"
              viewBox="0 0 48 48"
            />
          </Link>
          <Link target="_blank" href="https://www.youtube.com/user/GulfNewsTV">
            <SvgIconHandler
              iconStyle={{ color: "#0C1215" }}
              type="youtube"
              height="48px"
              width="48px"
              viewBox="0 0 48 48"
            />
          </Link>
          <Link target="_blank" href="https://www.linkedin.com/company/gulf-news/">
            <SvgIconHandler
              iconStyle={{ color: "#0C1215" }}
              type="linkedin"
              height="48px"
              width="48px"
              viewBox="0 0 48 48"
            />
          </Link>
        </div>
      </div>
      <div styleName="bottom-ad-slot">
        <DfpComponent
          adStyleName="recipe-section-page-bottom"
          id={`recipe-section-page-bottom-ad`}
          path={adConfig?.ad_unit}
          size={adConfig?.sizes}
          viewPortSizeMapping={adConfig?.view_port_size_mapping}
        />
      </div>
    </div>
  );
};

RecipeSectionPage.propTypes = {};

export default RecipeSectionPage;
