import React from "react";
import { object, string } from "prop-types";
import { getStoryHeadline, getStoryLabel } from "../../utils/story";

import "./headline-with-label.m.css";

export const HeadlineWithLabel = ({ story, newStyle = "", headlineStyles, showLabel = true }) => {
  const label = getStoryLabel(story);
  const headline = getStoryHeadline(story);

  return (
    <div styleName="headline-container">
      {label && showLabel && <p styleName="label">{label}</p>}
      <h2 styleName={`headline ${newStyle}`} style={headlineStyles}>
        {headline}
      </h2>
    </div>
  );
};

HeadlineWithLabel.propTypes = {
  story: object,
  newStyle: string,
  headlineFontSize: string,
  headlineStyles: object,
};
