import { Link } from "@quintype/components";
import get from "lodash/get";
import PropTypes from "prop-types";
import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getCollectionitems, getMenuGroups } from "../../../../api/utils";
import { DfpComponent } from "../../ads/dfp-component";
import { HeroImage } from "../../atoms/HeroImage";
import Newsletter from "../../atoms/Newsletter";
import { PublishAndReadTime } from "../../atoms/PublishAndReadTime";
import { useDeviceType } from "../../custom-hooks/useDeviceType";
import { getStoryHeadline, isExternalStory } from "../../utils/story";

import "./latest-news.m.css";

const StoryTextContent = ({ story }) => {
  const customStyleForReadTime = { fontFamily: "Montserrat" };
  const { sections, authors } = story;
  return (
    <div styleName={`story-box`}>
      {sections?.[0]?.["display-name"] && (
        <div styleName="section-text-class">{sections?.[0]?.["display-name"].toUpperCase()}</div>
      )}
      {getStoryHeadline(story) && <span styleName={`headline`}>{getStoryHeadline(story)}</span>}
      {authors?.[0]?.name && <span styleName="section-text-class">{authors?.[0]?.name.toUpperCase()}</span>}
      <PublishAndReadTime showReadTime={true} showTimestamp={true} story={story} customStyle={customStyleForReadTime} />
    </div>
  );
};

const StoryComponent = ({ story }) => {
  const externalStoryUrl = isExternalStory(story);
  const href = externalStoryUrl || `/${story?.slug}`;
  const target = externalStoryUrl ? "_blank" : "_self";
  return (
    <Link href={href} target={target}>
      <div styleName={`story-container`}>
        <div styleName={`story-image-container`}>
          <HeroImage
            story={story}
            widths={[135]}
            aspectRatio={[[4, 3]]}
            headline={getStoryHeadline(story)}
            iconSizes={{ width: 24, height: 24 }}
          />
        </div>
        <StoryTextContent story={story} />
      </div>
    </Link>
  );
};

const LatestNewsPage = (props) => {
  const [menuGroupsList, setMenuGroupsList] = useState([]);
  const initialList = props?.data?.collection?.items ? props?.data?.collection?.items : [];
  const [stories, setStories] = useState(initialList?.length > 100 ? initialList.slice(0, 100) : initialList);
  const [offset, setOffset] = useState(100);
  const [visibleItems, setVisibleItems] = useState(initialList.length > 100);
  const slug = get(props, ["data", "collection", "slug"], null);
  let currPath = useSelector((state) => get(state, ["qt", "currentPath"], ""))
    .split("?")[0]
    .split("/")[1];
  let pageName = useSelector((state) =>
    get(state, ["qt", "config", "ads-config", "ad_name_mapping", currPath], "sections")
  );
  const networkId = useSelector((state) => get(state, ["qt", "config", "ads-config", "network_id"], ""));
  const rightTopAdUnit = `${networkId}/GULFNEWS/Gulfnews_ATF/gulfnews_${pageName}_atf/gulfnews_${pageName}_right_atf`;
  const rightBottomAdUnit = `${networkId}/GULFNEWS/Gulfnews_BTF/gulfnews_${pageName}_btf/gulfnews_${pageName}_btf_right`;
  const BottomAdUnit = `${networkId}/GULFNEWS/Gulfnews_BTF/gulfnews_${pageName}_btf/gulfnews_${pageName}_btf_footer`;
  const adConfig1 = useSelector((state) =>
    get(state, ["qt", "config", "ads-config", "slots", "section_page_adslot_1"], {})
  );
  const adConfig2 = useSelector((state) =>
    get(state, ["qt", "config", "ads-config", "slots", "section_page_adslot_2"], {})
  );
  const adConfig3 = useSelector((state) =>
    get(state, ["qt", "config", "ads-config", "slots", "section_page_adslot_3"], {})
  );
  const deviceType = useDeviceType();
  const isMobile = deviceType === "mobile";
  const isTablet = deviceType === "tablet";
  const isDesktop = deviceType === "desktop";

  useEffect(async () => {
    setMenuGroupsList(await getMenuGroups());
  }, []);

  const sectionMenuList = menuGroupsList?.["menu-groups"]?.["main-nav"]?.items || [];

  const coverImage = get(props, ["data", "collection", "metadata", "cover-image"], null);

  const customStyleForNewsLetter = {
    fontFamily: "Roboto Serif",
    margin: isDesktop ? "16px 0" : "32px 0px",
    width: "auto",
  };
  const newsLetterTextsStyle = {
    textAlign: isDesktop ? "left" : "center",
    fontFamily: "Roboto Serif",
  };

  const loadMoreStories = async () => {
    const limit = 99;
    const response = await getCollectionitems(slug, offset, limit);
    const { items: moreStories, totalCount } = response;
    setStories([...stories, ...moreStories]);
    setVisibleItems(offset + limit <= totalCount);
    setOffset((prevOffset) => prevOffset + 100);
  };

  return (
    <div styleName="container">
      <div styleName="stories-wrapper">
        <div styleName="stories-results">
          {stories?.map((item, index) => {
            const story = item?.story;
            return (
              <Fragment key={index}>
                <StoryComponent story={story} />
                {index !== stories.length - 1 && <div styleName="horizontal-separator"></div>}
              </Fragment>
            );
          })}

          {visibleItems < stories.length && (
            <p styleName="loadmore-button" onClick={loadMoreStories}>
              LOAD MORE
            </p>
          )}
        </div>
        <div styleName="separartor"></div>
        <div styleName="right-column">
          <div styleName="top-ad-container mobile-top-padding">
            <DfpComponent
              adStyleName="ad-slot-size-300x600"
              id={`latest-news-right-top-ad`}
              path={rightTopAdUnit}
              size={adConfig1?.sizes}
              viewPortSizeMapping={adConfig1.view_port_size_mapping}
            />
          </div>
          <Newsletter
            customStyle={customStyleForNewsLetter}
            isButtonPositionLower={true}
            newsLetterTextsStyle={newsLetterTextsStyle}
          />

          <div styleName="top-ad-container mobile-bottom-padding">
            <DfpComponent
              adStyleName="ad-slot-size-300x250-constant"
              id={`latest-news-right-bottom-ad`}
              path={rightBottomAdUnit}
              size={adConfig2.sizes}
              viewPortSizeMapping={adConfig2.view_port_size_mapping}
            />
          </div>
        </div>
      </div>
      <div styleName="bottom-ad-container">
        <DfpComponent
          adStyleName="ad-slot-size-970x250"
          id={`latest-news-bottom-ad`}
          path={BottomAdUnit}
          size={adConfig3.sizes}
          viewPortSizeMapping={adConfig3.view_port_size_mapping}
        />
      </div>
    </div>
  );
};

LatestNewsPage.propTypes = {
  data: PropTypes.object,
};

export { LatestNewsPage };
