import React from "react";
import { string, func } from "prop-types";
import { DailyCrossword, SpellIt, Sudoku, WordSearch } from "../../../../atoms/svg-icon-hadler/Icon";

import "./Game.m.css";

const Game = ({ day = "", date = "", image, handleClick }) => {
  const gameComponents = {
    sudoku: Sudoku,
    "daily-crossword": DailyCrossword,
    "spell-it": SpellIt,
    "word-search": WordSearch,
  };
  const GameComponent = gameComponents[image];
  return (
    <div styleName="game-card" onClick={handleClick}>
      {GameComponent && <GameComponent />}
      <div styleName="game-card-text">
        <h1 styleName="game-card-day">{day}</h1>
        <p styleName="game-card-date">{date}</p>
      </div>
      <div styleName="game-card-button">PLAY NOW</div>
    </div>
  );
};

Game.propTypes = {
  day: string,
  date: string,
  image: string,
  handleClick: func,
};

export default Game;
