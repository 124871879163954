import React, { useEffect, useState } from "react";
import { Link } from "@quintype/components";
import { object, bool, string } from "prop-types";
import { PublishAndReadTime } from "../../../atoms/PublishAndReadTime";
import { HeadlineWithLabel } from "../../../atoms/HeadlineWithLabel";
import { Label } from "../../../atoms/Label";
import { getCollectionitems } from "../../../../../api/utils";
import { getRecipeCookTime, storyTemplates } from "../../../utils/story";
import CookTime from "../../../atoms/CookTime";

import "./stories.m.css";

const Stories = ({
  defaultFallback = false,
  showReadTime = true,
  showLabel = true,
  showTimestamp = true,
  collectionName = "",
  title = "STORIES",
}) => {
  const [trendingStories, setTrendingStories] = useState([]);

  useEffect(() => {
    getCollectionitems(collectionName, 0, 5).then((trendingStoriesResult) => {
      setTrendingStories(trendingStoriesResult.items);
    });
  }, []);

  const HorizontalStory = ({
    story,
    showReadTime,
    showTimestamp,
    storySeperator,
    columnSeperator,
    defaultFallback,
    textColor,
    showLabel = true,
  }) => {
    const isRecipe = story && story["story-template"] === storyTemplates.recipe;
    const cookTime = getRecipeCookTime(story);
    return (
      <div
        styleName={"common-story"}
        key={story.id}
        style={{ border: storySeperator || "none", color: textColor || "" }}
      >
        <Link href={`/${story.slug}`}>
          <div styleName="second-card" style={{ border: columnSeperator || "none" }}>
            <div styleName={`left-column`}>
              <Label story={story} />
              <HeadlineWithLabel story={story} showLabel={false} />
              {isRecipe && <div styleName="attributes">{cookTime && <CookTime cookTime={cookTime} />}</div>}

              {!isRecipe && (
                <PublishAndReadTime showReadTime={showReadTime} showTimestamp={showTimestamp} story={story} />
              )}
            </div>
          </div>
        </Link>
      </div>
    );
  };

  HorizontalStory.propTypes = {
    story: object,
    showBorder: bool,
    showReadTime: bool,
    showTimestamp: bool,
    defaultFallback: bool,
    storySeperator: bool,
    columnSeperator: bool,
    textColor: string,
  };

  return (
    <div>
      <div styleName="stories-wrapper">
        <div styleName="right-column">
          <span styleName="latest-stories-text">{title}</span>
          <div styleName="third-column">
            {trendingStories?.map((story, index) => (
              <HorizontalStory
                key={story.id}
                story={story.story}
                showReadTime={showReadTime}
                showTimestamp={showTimestamp}
                storySeperator={true}
                columnSeperator={true}
                textColor={"#ffffff"}
                defaultFallback={defaultFallback}
                showLabel={showLabel}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
export { Stories };
