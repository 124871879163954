import React from "react";

import "./search-heading.m.css";

const SearchHeading = ({ headingText }) => {
  return (
    <div className="container" styleName="search-heading-container">
      <h1 styleName="heading">{headingText}</h1>
      <div styleName="row-separator"></div>
    </div>
  );
};

export default SearchHeading;
