import React from "react";
import Chart, { formatTableDate } from "../../../../atoms/Chart";
import get from "lodash/get";
import LeftSection from "../../left-section/LeftSection";
import TopStoryCard from "../../top-story-card/TopStoryCard";
import RightSection from "../../right-section/RightSection";
import { useDeviceType } from "../../../../custom-hooks/useDeviceType";
import { useSelector } from "react-redux";
import { DfpComponent } from "../../../../ads/dfp-component";
import HistoricalFuelRateTable from "./HistoricalFuelRateTable";

import "./fuel-rate.m.css";

export const HistoricalFuelPage = (props) => {
  const lineKeys = ["super98", "special95", "ePlus91", "diesel"];
  const lineNames = ["Super 98", "Special 95", "EPlus 91", "Diesel"];
  const xAxisKey = "month";
  const menuItems = ["Remittance", "Gold Rate", "Fuel Price", "Currency"];
  const sectionIds = ["remittance", "gold-rate", "fuel-price", "currency"];
  const deviceType = useDeviceType();
  const isMobile = deviceType === "mobile";
  const isDesktop = deviceType === "desktop";
  const url =
    "https://www.lulumoney.com/?utm_source=https%3A%2F%2Fgulfnews.com%2Fgold-forex&utm_medium=Banner&utm_campaign=Gulfnewsrate_check";
  const adConfig = useSelector((state) =>
    get(state, ["qt", "config", "ads-config", "slots", "section_page_adslot_3"], {})
  );

  const parseFuelData = (dataString) =>
    dataString
      ?.split("\n")
      ?.filter((line) => line?.trim())
      ?.map((line) => {
        const values = line
          ?.replace(/[{}]/g, "")
          ?.split(",")
          ?.map((item) => item?.trim());
        const obj = {};
        values?.forEach((value) => {
          let [key, val] = value?.split(":")?.map((item) => item?.trim()?.replace(/"/g, ""));
          val = isNaN(val) ? val : parseFloat(val);
          if (val !== undefined && val !== "" && val !== null) {
            obj[key] = val;
          }
        });
        return obj;
      });
  const parsedFuelData = parseFuelData(props?.data?.["historicalFuelApiData"]) || [];

  const formatChartDate = (dateString) => {
    const [month, year] = dateString?.split(" ") || [];
    const monthNumber = new Date(Date.parse(`${month} 1, 2024`))?.getMonth() + 1;
    return `${year}-${monthNumber}`;
  };

  const formattedChartData = parsedFuelData
    ?.slice(1, 7)
    ?.map((item) => ({
      month: formatChartDate(item?.month),
      super98: item?.super98,
      special95: item?.special95,
      ePlus91: item?.eplus91,
      diesel: item?.diesel,
    }))
    ?.reverse();

  const currentDate = formatTableDate();
  const topSixMonthsData = parsedFuelData?.slice(1, 7);
  const allPrices = topSixMonthsData?.flatMap((entry) => [
    entry?.diesel,
    entry?.eplus91,
    entry?.super95,
    entry?.super98,
  ]);

  const maxRate = allPrices.length > 0 ? Math.max(...allPrices) : 0;
  const minRate = allPrices.length > 0 ? Math.min(...allPrices) : 0;
  const adjustedMaxPrice = parseFloat((maxRate + 0.3).toFixed(2));
  const domin = [minRate, adjustedMaxPrice];

  return (
    <div styleName="fuel-page-container">
      <div styleName="fuel-page-wrapper">
        <div styleName="fuel-page-left-center-container">
          <div styleName="fuel-page-left-container">
            <LeftSection menuItems={menuItems} sectionIds={sectionIds} />
          </div>
          <div styleName="fuel-page-center-container">
            <div>
              <TopStoryCard heading="Historical Fuel Rates" date={currentDate} id="fuel-rates-001" />

              <Chart
                data={formattedChartData}
                xAxisKey={xAxisKey}
                domin={domin}
                lineKeys={lineKeys}
                lineNames={lineNames}
                dataKey={lineKeys}
              />

              <HistoricalFuelRateTable fuelRateData={parsedFuelData} />
            </div>
          </div>
        </div>
        <div styleName="fuel-page-right-container">
          <RightSection showYourMoneyStories={false} />
        </div>
      </div>
      <div>
        {isDesktop ? (
          <DfpComponent
            adStyleName="ad-slot-size-970x250"
            id="forex-page-bottom-ad"
            path={"/1019334/GULFNEWS/Gulfnews_BTF/gulfnews_goldforex_btf/gulfnews_goldforex_btf_footer"}
            size={adConfig?.sizes}
            viewPortSizeMapping={adConfig?.view_port_size_mapping}
          />
        ) : isMobile ? (
          <div styleName="right-bottom-ad-slot-320">
            <DfpComponent
              adStyleName="ad-slot-size-320x50 "
              id="forex-page-right-bottom-ad"
              path={"/1019334/GULFNEWS/Gulfnews_BTF/gulfnews_goldforex_btf/gulfnews_goldforex_btf_footer"}
              size={adConfig?.sizes}
              viewPortSizeMapping={adConfig?.view_port_size_mapping}
            />
          </div>
        ) : (
          <div styleName="right-bottom-ad-slot">
            <DfpComponent
              adStyleName="ad-slot-size-300x250-constant"
              id="forex-page-right-bottom-ad"
              path={"/1019334/GULFNEWS/Gulfnews_BTF/gulfnews_goldforex_btf/gulfnews_goldforex_btf_footer"}
              size={adConfig?.sizes}
              viewPortSizeMapping={adConfig?.view_port_size_mapping}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default HistoricalFuelPage;
