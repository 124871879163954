import React from "react";
import "./PrayerTimeTable.m.css";

export const CalculateImsakTime = (prayerTime) => {
  if (prayerTime) {
    const [hours, minutes] = prayerTime?.toString()?.split(":")?.map(Number);
    const fajrTime = new Date();
    fajrTime.setHours(hours);
    fajrTime.setMinutes(minutes);
    const imsakTime = new Date(fajrTime);
    imsakTime.setMinutes(fajrTime.getMinutes() - 10);
    const formattedImsakTime = imsakTime.toTimeString().split(":").slice(0, 2).join(":");
    return formattedImsakTime;
  }
};

const PrayerTimesTable = React.memo(({ prayerData, startDay, currentMonth }) => {
  const prayerTimes = Object.values(prayerData).slice(4);
  let hasRamdanMonthValue = false;

  for (const key in prayerData) {
    if (prayerData[key].hijri_month === "Ramadan" && prayerData[key].day >= startDay) {
      hasRamdanMonthValue = true;
      break;
    }
  }

  return (
    <div styleName="table-container">
      <table styleName="prayer-times-table">
        <thead>
          <tr>
            <th>Date</th>
            {hasRamdanMonthValue && <th>Imsak</th>}
            <th>Fajr</th>
            <th>Sunrise</th>
            <th>Dhuhr</th>
            <th>Asr</th>
            {hasRamdanMonthValue ? <th>Maghrib / Iftar</th> : <th>Maghrib</th>}
            <th>Isha</th>
          </tr>
        </thead>
        <tbody>
          {prayerTimes.map(
            (date, index) =>
              date.day >= startDay && (
                <tr key={index}>
                  <td>
                    {`${date.day} ${currentMonth}`} <p>{`${date.hijri_month} ${date.hijri_day}`}</p>
                  </td>
                  {hasRamdanMonthValue && (
                    <td>{date.hijri_month === "Ramadan" ? CalculateImsakTime(date.fajr) : ""}</td>
                  )}
                  <td>{date.fajr}</td>
                  <td>{date.sunrise}</td>
                  <td>{date.dhuhr}</td>
                  <td>{date.asr}</td>
                  <td>{date.maghrib}</td>
                  <td>{date.isha}</td>
                </tr>
              )
          )}
        </tbody>
      </table>
    </div>
  );
});

export default PrayerTimesTable;
