import React, { useEffect, useState } from "react";
import { Link } from "@quintype/components";
import get from "lodash/get";
import { object, bool, string } from "prop-types";
import { PARTNER_CONTENT_STORY_TEMPLATE } from "../../../../constants";
import { PublishAndReadTime } from "../../../atoms/PublishAndReadTime";
import { HeadlineWithLabel } from "../../../atoms/HeadlineWithLabel";
import { getRecipeCookTime, storyTemplates } from "../../../utils/story";
import CookTime from "../../../atoms/CookTime";
import { getCollectionitems } from "../../../../../api/utils";
import { Label } from "../../../atoms/Label";
import "./LatestStories.m.css";

const LatestStories = ({ defaultFallback = false, showReadTime = true, showLabel = true, showTimestamp = true }) => {
  const [trendingStories, setTrendingStories] = useState([]);

  useEffect(() => {
    const fetchStories = async () => {
      const result = await getCollectionitems("latest-stories", 0, 5);
      setTrendingStories(result.items);
    };
    fetchStories();
  }, []);

  const HorizontalStory = ({
    story,
    showThumbnail,
    showReadTime,
    showTimestamp,
    storySeperator,
    columnSeperator,
    defaultFallback,
    textColor,
    showLabel = true,
  }) => {
    const sponsor = get(story, ["metadata", "sponsored-by"], null);
    const isPartneredContent = story["story-template"] === "publication" || sponsor !== null;
    const imageUrl =
      get(story, ["hero-image-s3-key"], "") ||
      get(story, ["alternative", "home", "default", "hero-image", "hero-image-s3-key"], "");

    const isRecipe = story && story["story-template"] === storyTemplates.recipe;
    const cookTime = getRecipeCookTime(story);
    const servings = get(story, ["metadata", "servings"], "");

    return (
      <div styleName="common-story" key={story.id} style={{ border: storySeperator || "none", color: textColor || "" }}>
        <Link href={`/${story.slug}`}>
          <div styleName="second-card" style={{ border: columnSeperator || "none" }}>
            <div styleName={`left-column ${imageUrl ? "show-thumbnail" : "hide-thumbnail"}`}>
              {showLabel && <Label story={story} />}
              <HeadlineWithLabel story={story} showLabel={false} />
              {isRecipe ? (
                <div styleName="attributes">
                  {cookTime && <CookTime cookTime={cookTime} />}
                  {cookTime && servings && <span styleName="separator">|</span>}
                  {servings && <p styleName="servings">Servings: {servings}</p>}
                </div>
              ) : (
                <PublishAndReadTime showReadTime={showReadTime} showTimestamp={showTimestamp} story={story} />
              )}
            </div>
          </div>
        </Link>
      </div>
    );
  };

  HorizontalStory.propTypes = {
    story: object,
    showThumbnail: bool,
    showBorder: bool,
    showReadTime: bool,
    showTimestamp: bool,
    defaultFallback: bool,
    storySeperator: bool,
    columnSeperator: bool,
    textColor: string,
    showLabel: bool,
  };

  return (
    <div>
      <div styleName="stories-wrapper">
        <div styleName="right-column">
          <span styleName="latest-stories-text">LATEST STORIES</span>
          <div styleName="third-column">
            {trendingStories?.map((story) => (
              <HorizontalStory
                key={story.id}
                story={story.story}
                showReadTime={showReadTime}
                showTimestamp={showTimestamp}
                storySeperator={true}
                columnSeperator={true}
                textColor={"#ffffff"}
                showThumbnail={true}
                defaultFallback={defaultFallback}
                showLabel={showLabel}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export { LatestStories };
