import React from "react";
import { Link } from "@quintype/components";
import { SvgIconHandler } from "../atoms/svg-icon-hadler";

import "./not-found.m.css";

const NotFoundPage = () => (
  <div className="container" styleName="not-found-container">
    <div styleName="not-found">
      <div styleName="svg-container">
        <SvgIconHandler type="404-robot" height="330" width="313" />
      </div>

      <div styleName="not-found-text">
        <h2 styleName="err-head">Page Not Found</h2>
        <div styleName="divider"></div>
        <p styleName="desc">
          You may have typed the web address incorrectly, or the page that you were searching for may have been moved.
        </p>
        <p styleName="desc">
          Visiting the following links may help: gulfnews.com to return to the main{" "}
          <Link href="/">GulfNews homepage</Link>, -or- <Link href="/search">gulfnews.com/search</Link> to visit the
          archive search page.
        </p>
        <p styleName="desc">Please let us know what went wrong by filling out our website feedback form.</p>
      </div>
    </div>
  </div>
);

export { NotFoundPage };
