import React from "react";

import "./fuel-rate.m.css";

const HistoricalFuelRateTable = ({ fuelRateData }) => {
  const topSixMonthsData = fuelRateData?.slice(1, 13);
  console.log("topSixMonthsData:", topSixMonthsData);
  return (
    <div styleName="fuel-table-container">
      <table styleName="fuel-rate-exchange-table">
        <thead>
          <tr>
            <th>Date</th>
            <th>Super 98</th>
            <th>Special 95</th>
            <th>EPlus 91</th>
            <th>Diesel</th>
          </tr>
        </thead>
        <tbody>
          {fuelRateData?.length > 0 ? (
            topSixMonthsData?.map((item) => (
              <tr key={item?.month}>
                <td>{item?.month}</td>
                <td>{item?.super98}</td>
                <td>{item?.special95}</td>
                <td>{item?.eplus91}</td>
                <td>{item?.diesel}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5">No data available.</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default HistoricalFuelRateTable;
