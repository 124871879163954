import React from "react";
import { formatTableDate } from "../../../../atoms/Chart";

import "./remmitance-rate.m.css";

const HistoricalRemmitanceTable = ({ currencyDetails }) => (
  <div styleName="remmitance-table-container">
    <table styleName="remmitance-rate-exchange-table">
      <thead>
        <tr>
          <th>Date</th>
          <th>Rate (1 AED)</th>
        </tr>
      </thead>
      <tbody>
        {currencyDetails?.rates?.length > 0 ? (
          currencyDetails?.rates?.map((item) => (
            <tr key={item?.modifiedDate}>
              <td>{formatTableDate(item?.modifiedDate)}</td>
              <td>{item?.currencyRate}</td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="5">No data available</td>
          </tr>
        )}
      </tbody>
    </table>
  </div>
);

export default HistoricalRemmitanceTable;
