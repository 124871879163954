import React, { useState } from "react";
import { Link } from "@quintype/components";
import { object, func } from "prop-types";
import { useSelector } from "react-redux";
import get from "lodash/get";
import { useDeviceType } from "../../../custom-hooks/useDeviceType";
import { DfpComponent } from "../../../ads/dfp-component";
import { HorizontalStory } from "./horizontal-stories/HorizontalStory";
import { TrendingStories } from "../trending-stories/TrendingStories";
import { HeroImage } from "../../../atoms/HeroImage";
import { PublishAndReadTime } from "../../../atoms/PublishAndReadTime";
import { getStoryHeadline, getStoryLabel } from "../../../utils/story";
import { SvgIconHandler } from "../../../atoms/svg-icon-hadler";
import { BreadCrumbs } from "../../../story-templates/atoms";
import { Label } from "../../../atoms/Label";

import "./default-page.m.css";

const DefaultPage = ({ data, config, removeLeadingSlash, pageType }) => {
  const deviceType = useDeviceType();
  const isDesktop = deviceType === "desktop";
  const isMobile = deviceType === "mobile";
  let currPath = useSelector((state) => get(state, ["qt", "currentPath"], ""))
    .split("?")[0]
    .split("/")[1];
  let pageName = useSelector((state) =>
    get(state, ["qt", "config", "ads-config", "ad_name_mapping", currPath], "sections")
  );
  const networkId = useSelector((state) => get(state, ["qt", "config", "ads-config", "network_id"], ""));
  const rightTopAdUnit = `${networkId}/GULFNEWS/Gulfnews_ATF/gulfnews_${pageName}_atf/gulfnews_${pageName}_right_atf`;
  const rightBottomAdUnit = `${networkId}/GULFNEWS/Gulfnews_BTF/gulfnews_${pageName}_btf/gulfnews_${pageName}_btf_right`;
  const BottomAdUnit = `${networkId}/GULFNEWS/Gulfnews_BTF/gulfnews_${pageName}_btf/gulfnews_${pageName}_btf_footer`;
  const adConfig1 = useSelector((state) =>
    get(state, ["qt", "config", "ads-config", "slots", "section_page_adslot_1"], {})
  );
  const adConfig2 = useSelector((state) =>
    get(state, ["qt", "config", "ads-config", "slots", "section_page_adslot_2"], {})
  );
  const adConfig3 = useSelector((state) =>
    get(state, ["qt", "config", "ads-config", "slots", "section_page_adslot_3"], {})
  );

  const items =
    pageType === "magazine-landing-page" ? get(data, ["data", "items"], []) : get(data, ["collection", "items"], []);
  const stories = items?.filter((item) => item.type === "story").map((ele) => ele.story) || [];

  const storyLabel = stories.length > 0 ? getStoryLabel(stories[0]) : "";
  const initialVisibleItems = stories.length > 8 ? 8 : stories.length;
  const [visibleItems, setVisibleItems] = useState(initialVisibleItems);
  const imageUrl =
    get(stories[0], ["hero-image-s3-key"], "") ||
    get(stories[0], ["alternative", "home", "default", "hero-image", "hero-image-s3-key"], "");
  const loadMoreStories = () => {
    setVisibleItems((prevVisibleItems) => Math.min(prevVisibleItems + 3, stories.length));
  };

  let currentPath = "";
  if (typeof window !== "undefined" && window.location) {
    currentPath = removeLeadingSlash(window.location.pathname);
  }
  const displayName = config?.sections?.find((section) => section.id === data?.collection?.metadata?.section?.[0]?.id);

  const isPodcastsSectionPage = data?.collection?.slug === "podcasts";
  let iconSize = { width: "46", height: "46" };
  !isDesktop && (iconSize = { width: "40", height: "40" });

  const trendingHeadlineCustomStyle = {
    fontSize: isDesktop ? "16px" : "18px",
  };

  return (
    <div>
      <div styleName="breadCrumbs-wrapper">
        <BreadCrumbs currentPath={currentPath} />
      </div>
      {isPodcastsSectionPage && (
        <div styleName="podcast-header-wrapper">
          <div styleName="podcast-header">
            <div styleName="podcast-content">
              <h3 styleName="podcast-heading">Tell Me Why</h3>
              <p styleName="podcast-description">
                An honest, informative and fun podcast by Gulf News for inquisitive souls with a thirst for
                understanding. Join us as we tackle an array of topics and delve into the fascinating depths of
                knowledge.
              </p>
            </div>
            <div styleName="podcast-images">
              <div styleName="podcast-image">
                <img
                  src="https://images.assettype.com/gulfnews/2024-11-26/aj6see1m/tellmewhy-thumbnailsquare-small360.jpg"
                  alt="Tell Me Why"
                />
              </div>
              <div styleName="podcast-links-wrapper">
                <p>Also available on</p>
                <div styleName="podcast-links">
                  <Link href="https://podcasts.apple.com/au/channel/gulf-news-podcasts/id6442719122" target="_blank">
                    <SvgIconHandler
                      type="apple-podcast"
                      width={`${iconSize?.width}px`}
                      height={`${iconSize?.height}px`}
                      viewBox={`0 0 ${iconSize?.width} ${iconSize?.height}`}
                    />
                  </Link>
                  <Link href="https://open.spotify.com/show/7egzLyxT6QFVEWe6UaYVa2" target="_blank">
                    <SvgIconHandler
                      type="spotify-color-icon"
                      width={`${iconSize?.width}px`}
                      height={`${iconSize?.height}px`}
                      viewBox={`0 0 ${iconSize?.width} ${iconSize?.height}`}
                    />
                  </Link>
                  <Link href="https://www.youtube.com/playlist?list=PLvkwANzYwLljvN8zKpQfkfQdFmjIoPLRw" target="_blank">
                    <SvgIconHandler
                      type="youtube-color-icon"
                      width={`${iconSize?.width}px`}
                      height={`${iconSize?.height}px`}
                      viewBox={`0 0 ${iconSize?.width} ${iconSize?.height}`}
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="container" styleName="wrapper">
        <div
          styleName="sectionContainer"
          style={{
            width: stories.length === 0 ? "calc(100% - 250px)" : "",
            display: stories.length === 0 ? "flex" : "",
            flexDirection: stories.length === 0 ? "column" : "",
          }}
        >
          <Link href={`/${stories?.[0]?.slug}`}>
            {stories.length > 0 && (
              <div styleName="first-story-container">
                <div styleName="first-image-container">
                  <HeroImage
                    story={stories[0]}
                    headline={stories[0]?.headline}
                    aspectRatio={[[92, 69]]}
                    iconSizes={isMobile ? { height: 32, width: 32 } : { height: 48, width: 48 }}
                    imageUrl={imageUrl}
                  />
                </div>
                <div styleName="first-context-container">
                  {storyLabel && <div styleName="firstStory-label">{<Label story={stories[0]} />}</div>}
                  <h1>{getStoryHeadline(stories[0])}</h1>
                  {stories[0]?.subheadline ? <h2>{stories[0]?.subheadline}</h2> : ""}
                  <PublishAndReadTime story={stories[0]} showReadTime={true} showTimestamp={true} />
                </div>
              </div>
            )}
          </Link>
          <div styleName="row-separator-firststory"></div>
          {stories.length > 0 && (
            <div styleName="articleList">
              {stories?.slice(1, visibleItems)?.map((story) => (
                <HorizontalStory key={story?.id} story={story} leftImage={true} />
              ))}
            </div>
          )}
          {stories.length > 0 && visibleItems >= 6 && visibleItems < stories.length && (
            <div styleName="loadmore-button-wrapper">
              <button styleName="loadmore-button" onClick={loadMoreStories}>
                LOAD MORE
              </button>
            </div>
          )}
        </div>

        <div styleName="adSlot">
          <div styleName="ad-slot-300x600-wrapper">
            {isDesktop && (
              <DfpComponent
                adStyleName="ad-slot-size-300x600"
                id="section-page-ad1"
                path={rightTopAdUnit}
                size={adConfig1.sizes}
                viewPortSizeMapping={adConfig1.view_port_size_mapping}
              />
            )}
          </div>
          <TrendingStories headlineCustomStyle={trendingHeadlineCustomStyle} />
          <div styleName="ad-slot-300x250-wrapper">
            <DfpComponent
              adStyleName={"ad-slot-size-300x250-constant"}
              id="section-page-ad2"
              path={rightBottomAdUnit}
              size={adConfig2.sizes}
              viewPortSizeMapping={adConfig2.view_port_size_mapping}
            />
          </div>
        </div>
      </div>
      <div styleName="ad-slot-970x250-wrapper">
        <DfpComponent
          adStyleName={"ad-slot-size-970x250"}
          id="section-page-ad3"
          path={BottomAdUnit}
          size={adConfig3.sizes}
          viewPortSizeMapping={adConfig3.view_port_size_mapping}
        />
      </div>
    </div>
  );
};

export { DefaultPage };

DefaultPage.propTypes = {
  data: object,
  config: object,
  removeLeadingSlash: func,
};
