import React from "react";
import { formatTableDate } from "../../../../atoms/Chart";

import "./gold-rate.m.css";

const HistoricalGoldRateTable = ({ historicalRate }) => (
  <div styleName="gold-table-container">
    <table styleName="gold-rate-exchange-table">
      <thead>
        <tr>
          <th>Date</th>
          <th>24 Carat</th>
          <th>22 Carat</th>
          <th>21 Carat</th>
          <th>18 Carat</th>
        </tr>
      </thead>
      <tbody>
        {historicalRate?.length > 0 ? (
          historicalRate?.map((item) => (
            <tr key={item?.date}>
              <td>{formatTableDate(item?.date)}</td>
              <td>{item?.carat24}</td>
              <td>{item?.carat22}</td>
              <td>{item?.carat21}</td>
              <td>{item?.carat18}</td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="5">No data available.</td>
          </tr>
        )}
      </tbody>
    </table>
  </div>
);

export default HistoricalGoldRateTable;
