import React, { useState } from "react";
import { SvgIconHandler } from "../../../atoms/svg-icon-hadler";

import "./common-tabel.m.css";

const PetrolDieselRateTable = ({ fuelApiData }) => {
  const parseFuelData = (dataString) =>
    dataString
      ?.split("\n")
      ?.filter((line) => line.trim())
      ?.map((line) => {
        const values = line
          ?.replace(/[{}]/g, "")
          ?.split(",")
          ?.map((item) => item.trim());
        const obj = {};
        values.forEach((value) => {
          let [key, val] = value?.split(":")?.map((item) => item?.trim().replace(/"/g, ""));
          val = isNaN(val) ? val : parseFloat(val);
          if (val !== undefined && val !== "" && val !== null) {
            obj[key] = val;
          }
        });
        return obj;
      });

  const parsedData = parseFuelData(fuelApiData) || [];
  const [fuelRateData] = useState(parsedData);
  const [fuelTypes] = useState(
    parsedData?.length > 0
      ? Object.keys(parsedData[1])
          .filter((key) => key !== "month")
          .reverse()
      : []
  );

  const recentMonths = fuelRateData?.slice(1, 3);

  const formatFuelType = (key) => {
    return key
      .replace(/([a-z])([A-Z])/g, "$1 $2")
      .replace(/(\d+)/g, " $1")
      .replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const calculateChange = (current, previous) => {
    if (isNaN(current) || isNaN(previous) || previous === 0) {
      return;
    }
    return (((current - previous) / previous) * 100).toFixed(1);
  };

  return (
    <div styleName="table-container">
      <table styleName="rate-exchange-table">
        <thead>
          <tr>
            <th>Type</th>
            {recentMonths?.map((monthData, index) => (
              <th key={index}>{monthData?.month?.slice(0, 3).toUpperCase()}</th>
            ))}
            <th>Change</th>
          </tr>
        </thead>
        <tbody>
          {fuelTypes?.length > 0 ? (
            fuelTypes?.map((fuelType, index) => {
              const currentPrice = parseFloat(recentMonths[0]?.[fuelType]);
              const previousPrice = parseFloat(recentMonths[1]?.[fuelType]);
              const percentageChange = calculateChange(currentPrice, previousPrice);
              const isPositiveChange = !isNaN(percentageChange) && percentageChange > 0;

              if (isNaN(currentPrice) && isNaN(previousPrice)) return null;

              return (
                <tr key={index}>
                  <td>{formatFuelType(fuelType)}</td>
                  <td>{isNaN(currentPrice) ? "" : currentPrice.toFixed(2)}</td>
                  <td>{isNaN(previousPrice) ? "" : previousPrice.toFixed(2)}</td>
                  <td>
                    <span styleName={isPositiveChange ? "fuel-percentage-up" : "fuel-percentage-down"}>
                      {isPositiveChange ? `+${percentageChange}%` : `-${percentageChange}%`}
                    </span>
                    {!isNaN(percentageChange) &&
                      (isPositiveChange ? (
                        <span styleName="arrow-icon">
                          <SvgIconHandler type="green-up-arrow" width="24px" height="24px" viewBox="0 0 24 24" />
                        </span>
                      ) : (
                        <span styleName="arrow-icon-red">
                          <SvgIconHandler type="arrow-down-red" width="24px" height="24px" viewBox="0 0 24 24" />
                        </span>
                      ))}
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan="4">No data available</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default PetrolDieselRateTable;
