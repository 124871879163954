import React from "react";
import { useSelector } from "react-redux";
import get from "lodash/get";
import { Link } from "@quintype/components";
import { DfpComponent } from "../../../ads/dfp-component";
import { SvgIconHandler } from "../../../atoms/svg-icon-hadler";
import { formatTableDate, getLastPublishedTimeGoldForex } from "../../../atoms/Chart";
import TopStoryCard from "../top-story-card/TopStoryCard";
import RateExchangeTable from "../tables/RateExchangeTable";
import GoldRateTable from "../tables/GoldRateTable";
import PetrolDieselRateTable from "../tables/PetrolDieselRateTable";
import ForeignExchangeTable from "../tables/ForeignExchangeTable";

import "./middle-section.m.css";
import { useDeviceType } from "../../../custom-hooks/useDeviceType";
const MiddleSection = ({ apiData }) => {
  const url =
    "https://www.lulumoney.com/?utm_source=https%3A%2F%2Fgulfnews.com%2Fgold-forex&utm_medium=Banner&utm_campaign=Gulfnewsrate_check";
  const centerTopAdConfig = useSelector((state) =>
    get(state, ["qt", "config", "ads-config", "slots", "gold_forex_center_top_ad"], {})
  );
  const centerMidAdConfig = useSelector((state) =>
    get(state, ["qt", "config", "ads-config", "slots", "gold_forex_center_mid_ad"], {})
  );
  const deviceType = useDeviceType();
  const isMobile = deviceType === "mobile";
  const currentDate = formatTableDate();
  const parseFuelData = (dataString) =>
    dataString
      ?.split("\n")
      ?.filter((line) => line?.trim())
      ?.map((line) => {
        const values = line
          ?.replace(/[{}]/g, "")
          ?.split(",")
          ?.map((item) => item?.trim());
        const obj = {};
        values?.forEach((value) => {
          let [key, val] = value?.split(":")?.map((item) => item?.trim()?.replace(/"/g, ""));
          val = isNaN(val) ? val : parseFloat(val);
          if (val !== undefined && val !== "" && val !== null) {
            obj[key] = val;
          }
        });
        return obj;
      });
  const parsedFuelData = parseFuelData(apiData?.allApiData?.["fuelApiData"]) || [];
  const recentMonths = parsedFuelData?.slice(0, 1);
  return (
    <div styleName="forex-page-middle-section-wrapper">
      <div>
        <div id="remittance" styleName="first-section">
          <TopStoryCard
            heading="UAE Money Transfer Exchange rates"
            date={currentDate}
            updatedAt={getLastPublishedTimeGoldForex(
              apiData?.allApiData?.["currencyExchageApiData"]?.[0]?.modifiedDate
            )}
            live="LIVE"
            id="forex-page-first-section-ad"
          />
          <RateExchangeTable currencyExchageApiData={apiData?.allApiData?.["currencyExchageApiData"]} />
          <div styleName="bottom-section">
            <div styleName="details-div">
              <h2> Click the currency type to view past 30 days rate</h2>
              <span>* Rates per 1 UAE Dirham</span>
              <span>* Rates are subject to fluctuation.</span>
            </div>
            <div styleName="lulu-money-logo-div">
              <p>PROVIDED BY</p>
              <Link target="_blank" href={url}>
                <SvgIconHandler type="lulu-money" height="60" width="100" viewBox="0 0 100 60" />
              </Link>
            </div>
          </div>
        </div>

        <div styleName="ad-slot-300x250-wrapper">
          <DfpComponent
            adStyleName="ad-slot-size-300x250-constant"
            id={`forex-page-middleSection-top-ad`}
            path={"/1019334/GULFNEWS/Gulfnews_Native/gulfnews_goldforex_native/gulfnews_goldforex_native_1"}
            size={centerTopAdConfig.sizes}
          />
        </div>
        {isMobile && <div styleName="horizonal-separator"></div>}
        <div id="gold-rate" styleName="second-section">
          <TopStoryCard
            id="forex-page-second-section-ad"
            heading="UAE Gold Rates"
            live="LIVE"
            date={currentDate}
            updatedAt={getLastPublishedTimeGoldForex(apiData?.allApiData?.["goldApiData"]?.lastUpdated)}
          />

          <GoldRateTable goldApidata={apiData?.allApiData?.["goldApiData"]} />
          <div styleName="bottom-section-details-wrapper">
            <Link href="/gold-forex/historical-gold-rates">
              <span>Click here to view past 30 days rate</span>
            </Link>
            <span>Provided by Dubai Gold & Jewellery Group</span>
          </div>
        </div>
        <div styleName="horizonal-separator"></div>
        <div id="fuel-price" styleName="third-section">
          <TopStoryCard
            id="forex-page-third-section-ad"
            heading="Petrol / Diesel Rates (AED/Ltr)"
            date={recentMonths[0]?.Updated}
          />
          <PetrolDieselRateTable fuelApiData={apiData?.allApiData?.["fuelApiData"]} />
          <div styleName="third-bottom-section-details">
            <Link href="/gold-forex/historical-fuel-rates">
              <span>Click here to view past 12 months rate</span>
            </Link>
          </div>
        </div>
        {isMobile && <div styleName="horizonal-separator"></div>}
        <div styleName="ad-slot-300x250-wrapper">
          <DfpComponent
            adStyleName="ad-slot-size-300x250-constant"
            id={`forex-page-middleSection-bottom-ad`}
            path={"/1019334/GULFNEWS/Gulfnews_Native/gulfnews_goldforex_native/gulfnews_goldforex_native_2"}
            size={centerMidAdConfig.sizes}
          />
        </div>
        <div id="currency" styleName="last-section">
          <TopStoryCard
            heading="UAE Foreign Exchange rates"
            date={currentDate}
            updatedAt={getLastPublishedTimeGoldForex(apiData?.allApiData?.["foreignApiData"]?.lastUpdated)}
            id="forex-page-last-section-ad"
          />
          <ForeignExchangeTable foreignApiData={apiData?.allApiData?.["foreignApiData"]} />
          <div styleName="bottom-section">
            <div styleName="details-div">
              <span>* Rates are subject to fluctuation.</span>
            </div>
            <div styleName="lulu-money-logo-div">
              <p>PROVIDED BY</p>
              <Link target="_blank" href={url}>
                <SvgIconHandler type="lulu-money" height="60" width="100" viewBox="0 0 100 60" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MiddleSection;
