import React from "react";
import get from "lodash/get";
import { useSelector } from "react-redux";
import { Stories } from "./stories";
import { DfpComponent } from "../../../ads/dfp-component";

import "./right-section.m.css";

const RightSection = ({ showYourMoneyStories = true, showLatestStories = true, add350x250 = true }) => {
  const righAdConfig = useSelector((state) =>
    get(state, ["qt", "config", "ads-config", "slots", "section_page_adslot_1"], {})
  );

  return (
    <div styleName="right-section">
      <div styleName="right-top-ad-slot">
        <DfpComponent
          adStyleName="ad-slot-size-300x600"
          id="forex-page-right-top-ad"
          path={"1019334/GULFNEWS/Gulfnews_ATF/gulfnews_goldforex_atf/gulfnews_goldforex_right_atf"}
          size={righAdConfig.sizes}
          viewPortSizeMapping={righAdConfig.view_port_size_mapping}
        />
      </div>

      {showYourMoneyStories && (
        <div styleName="your-money-stories-section">
          <Stories collectionName="your-money" title="YOUR MONEY" />
        </div>
      )}

      {showYourMoneyStories && (
        <div styleName="right-middle-ad-slot">
          <DfpComponent
            adStyleName="ad-slot-size-300x250-constant"
            id="forex-page-right-middle-ad"
            path={"1019334/GULFNEWS/Gulfnews_BTF/gulfnews_goldforex_btf/gulfnews_goldforex_btf_article_mpu"}
            size={righAdConfig.sizes}
            viewPortSizeMapping={righAdConfig.view_port_size_mapping}
          />
        </div>
      )}

      {showLatestStories && (
        <div styleName="latest-stories-section">
          <Stories collectionName="latest-stories" title="LATEST STORIES" />
        </div>
      )}

      {add350x250 && (
        <div styleName="right-bottom-ad-slot">
          <DfpComponent
            adStyleName="ad-slot-size-300x250-constant"
            id="forex-page-right-bottom-ad"
            path={"1019334/GULFNEWS/Gulfnews_BTF/gulfnews_goldforex_btf/gulfnews_goldforex_btf_article"}
            size={righAdConfig.sizes}
            viewPortSizeMapping={righAdConfig.view_port_size_mapping}
          />
        </div>
      )}
    </div>
  );
};

export default RightSection;
