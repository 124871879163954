import React from "react";
import PropTypes from "prop-types";

import "./pagination.m.css";

const Page = (props) => {
  function handleClick(e) {
    const { isDisabled, pageNumber, multiplePageTurnType, singlePageTurnType } = props;
    e.preventDefault();
    if (isDisabled) {
      return;
    }
    if (multiplePageTurnType === -1) {
      props.onClick(-1);
    } else if (multiplePageTurnType === 1) {
      props.onClick(1);
    } else if (singlePageTurnType === 1) {
      props.onClick(1);
    } else if (singlePageTurnType === -1) {
      props.onClick(-1);
    } else {
      props.onClick(pageNumber - 1);
    }
  }
  let {
    pageText,
    pageNumber,
    activeClass = "active",
    itemClass = "",
    disabledClass = "disable",
    isActive = false,
    isDisabled = false,
    ariaLabel,
  } = props;

  const css = `${isActive ? activeClass : ""} ${isDisabled ? disabledClass : ""}`;

  return (
    <button styleName={css} onClick={(e) => handleClick(e)} aria-label={ariaLabel}>
      {pageText}
    </button>
  );
};

Page.propTypes = {
  pageText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  pageNumber: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool,
  activeClass: PropTypes.string,
  itemClass: PropTypes.string,
  disabledClass: PropTypes.string,
  multiplePageTurnType: PropTypes.number,
  singlePageTurnType: PropTypes.number,
};

export default Page;
