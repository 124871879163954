import React from "react";
import { object, bool } from "prop-types";
import get from "lodash/get";
import { Link } from "@quintype/components";
import { PublishAndReadTime } from "../../../atoms/PublishAndReadTime";
import { HeroImage } from "../../../atoms/HeroImage";
import {
  getRecipeCookTime,
  getStoryHeadline,
  getStoryLabel,
  isExternalStory,
  storyTemplates,
} from "../../../utils/story";
import CookTime from "../../../atoms/CookTime";
import { Label } from "../../../atoms/Label";
import Headline from "../../../atoms/Headline";

import "../author.m.css";

export const HorizontalStory = ({ story, leftImage, showSubheadline = true }) => {
  const headline = getStoryHeadline(story);
  const label = getStoryLabel(story);
  const imageUrl =
    get(story, ["alternative", "home", "default", "hero-image", "hero-image-s3-key"], "") ||
    get(story, ["hero-image-s3-key"], "");
  const externalStoryUrl = isExternalStory(story);
  const href = externalStoryUrl ? externalStoryUrl : `/${story?.slug}`;
  const target = externalStoryUrl ? "_blank" : "_self";
  const isRecipe = story && story["story-template"] === storyTemplates.recipe;
  const cookTime = getRecipeCookTime(story);
  return (
    <div>
      <div styleName={`common-story`} key={story.id}>
        <Link href={href} target={target}>
          <div styleName="second-card">
            <div styleName={`left-column`} style={{ order: leftImage ? "2" : "" }}>
              {label && <div styleName="label-wrapper">{<Label story={story} />}</div>}
              {headline && <Headline headline={headline} styleName="headline" />}
              {story.subheadline && showSubheadline && <div styleName="subheadline">{story.subheadline}</div>}
              {!isRecipe ? (
                <PublishAndReadTime showTimestamp={true} showReadTime={true} story={story} />
              ) : (
                cookTime && <CookTime cookTime={cookTime} />
              )}
            </div>
            <div styleName="vertical-story-hero-image">
              <HeroImage
                story={story}
                headline={headline}
                aspectRatio={[[4, 3]]}
                iconSizes={{ height: 24, width: 24 }}
                imageUrl={imageUrl}
              />
            </div>
          </div>
        </Link>
      </div>
      <div styleName="row-separator"></div>
    </div>
  );
};

HorizontalStory.propTypes = {
  story: object,
  leftImage: bool,
  showSubheadline: bool,
};
