import React, { useState, useEffect, useLayoutEffect } from "react";
import { string } from "prop-types";
import { useSelector } from "react-redux";
import { parseUrl } from "query-string";
import { get } from "lodash";
import { SvgIconHandler } from "../../../../atoms/svg-icon-hadler";
import { useDeviceType } from "../../../../custom-hooks/useDeviceType";
import { DailyCrossword, SpellIt, Sudoku, WordSearch } from "../../../../atoms/svg-icon-hadler/Icon";
import "./PlayGamesPage.m.css";

const PuzzleEmbed = ({ dataSet, dataPage, dataHeight }) => {
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);

  useEffect(() => {
    const pmScript = document.createElement("script");
    pmScript.src = "https://cdn-eu1.amuselabs.com/pmm/js/puzzleme-embed.js";
    pmScript.async = true;
    pmScript.id = "pm-script";
    document.body.appendChild(pmScript);
    pmScript.onload = () => {
      window.PM_Config = window.PM_Config || {};
      window.PM_Config.PM_BasePath = "https://cdn-eu1.amuselabs.com/pmm/";
      setIsScriptLoaded(true);
    };
    return () => {
      document.body.removeChild(pmScript);
    };
  }, []);
  useEffect(() => {
    if (isScriptLoaded) {
      window.PM && window.PM.init && window.PM.init();
    }
  }, [isScriptLoaded]);
  return <div className="pm-embed-div" data-set={dataSet} data-page={dataPage} data-height={dataHeight}></div>;
};

const DifficultySelector = ({ difficulty }) => {
  const [selectedDifficulty, setSelectedDifficulty] = useState(difficulty ? difficulty : "easy");
  const handleSelection = (level) => {
    setSelectedDifficulty(level);
    window.location.href = `sudoku?difficulty=${level}`;
  };

  return (
    <div styleName="difficulty-selector">
      <button styleName={selectedDifficulty === "easy" ? "selected" : ""} onClick={() => handleSelection("easy")}>
        EASY
      </button>
      <button styleName={selectedDifficulty === "medium" ? "selected" : ""} onClick={() => handleSelection("medium")}>
        MEDIUM
      </button>
      <button styleName={selectedDifficulty === "hard" ? "selected" : ""} onClick={() => handleSelection("hard")}>
        HARD
      </button>
    </div>
  );
};

const GameBanner = ({ bgcolor = "#def2ff", image, title = "Sudoku" }) => {
  const gameComponents = {
    sudoku: Sudoku,
    "daily-crossword": DailyCrossword,
    "spell-it": SpellIt,
    "word-search": WordSearch,
  };

  const GameComponent = gameComponents[image];
  return (
    <div styleName="games-banner-wrapper" style={{ backgroundColor: bgcolor }}>
      <div styleName="games-banner-image">
        {/* <SvgIconHandler type={image} width="100%" height="100%" /> */}
        <GameComponent />
      </div>
      <div styleName="games-banner-content">
        <div>
          <SvgIconHandler
            iconStyle={{ color: "#596E79" }}
            type="logo-small"
            width="100%"
            height="100%"
            viewBox="0 0 310 48"
          />
        </div>
        <div styleName="games-banner-title">
          <h1>{title}</h1>
        </div>
      </div>
    </div>
  );
};

const PlayGamesPage = ({ title }) => {
  const device = useDeviceType();
  useLayoutEffect(() => {
    const cryptoScript = document.createElement("script");
    cryptoScript.src = "https://cdnjs.cloudflare.com/ajax/libs/crypto-js/4.1.1/crypto-js.min.js";
    cryptoScript.async = true;
    document.body.appendChild(cryptoScript);

    return () => {
      document.body.removeChild(cryptoScript);
    };
  }, []);
  const gameDetails = {
    crossword: {
      bgcolor: "#F8A7221A",
      image: "daily-crossword",
      title: "Daily Crossword",
      dataSet: "gulfnews",
    },
    spellIt: {
      bgcolor: "#E11B231A",
      image: "spell-it",
      title: "Spell It",
      dataSet: "gulfnews-spellit",
    },
    sudoku: {
      bgcolor: "#2A87C71A",
      image: "sudoku",
      title: "Sudoku",
      dataSet: "gulfnews-sudoku",
    },
    wordSearch: {
      bgcolor: "#E05B281A",
      image: "word-search",
      title: "Word Search",
      dataSet: "gulfnews-wordsearch",
    },
  };
  const qtConfig = useSelector((state) => get(state, ["qt"], {}));
  const currentPath = get(qtConfig, ["currentPath"], "");
  const params = parseUrl(currentPath);
  let difficulty = get(params, ["query", "difficulty"]);
  if (title === "sudoku" && !difficulty) {
    difficulty = "easy";
  }
  return (
    <div styleName="play-games-container">
      <GameBanner
        bgcolor={gameDetails[title]?.bgcolor}
        title={gameDetails[title]?.title}
        image={gameDetails[title]?.image}
      ></GameBanner>
      {title === "sudoku" && <DifficultySelector difficulty={difficulty} />}
      <PuzzleEmbed
        dataSet={`${gameDetails[title]?.dataSet}${difficulty ? "-" + difficulty : ""}`}
        dataPage="date-picker"
        dataHeight="700px"
      />
    </div>
  );
};

PlayGamesPage.propTypes = {
  title: string,
};

export default PlayGamesPage;
