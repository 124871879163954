import React from 'react';
import moment from "moment";
import { SvgIconHandler } from "../../../atoms/svg-icon-hadler";

import "../prayer-page.m.css";

function toTitleCase(str) {
  return str.replace(
      /\w\S*/g,
      function(txt) {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
  );
}

const PrayerTimeBox = ({ name, period, state }) => {
  const isHighlighted =
    (name === state?.currentPrayer?.time && name !== 'sunrise') ||
    (state?.currentPrayer?.time === "" &&
     name === "fajr" );

  const displayPeriod = period !== ""
    ? period
    : Number(state?.cityPrayerTodayTime[name]?.split(":")[0]) < 12
      ? "AM"
      : "PM";

  const showNote = state?.currentPrayer?.note && name === state?.currentPrayer?.time && name !== "sunrise";
  const isEid = name === "eid";

  return (
    <div
      styleName={`prayer-time-box ${isHighlighted ? "highlight" : ""} ${isEid ? "highlight eid-border" : ""}`}
    >
      <div>
        {isEid && <SvgIconHandler
              type="eid"
              height="24px"
              width="24px"
              iconStyle={{ backgroundColor: "white" } }
            />
        }
        <div>
          {isEid ? 'Eid Prayer' : toTitleCase(name)}
        </div>
        <div>
          {isEid ? moment.parseZone(state.eid[state?.selectedCity]).format("h:mm") : state?.cityPrayerTodayTime[name] || "N/A"}
          <p>
            {displayPeriod}
          </p>
        </div>
        {showNote && (
          <div>
            {state?.currentPrayer?.note}
          </div>
        )}
      </div>
    </div>
  );
};

export default PrayerTimeBox;
