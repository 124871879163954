import React from "react";
import { useSelector } from "react-redux";
import get from "lodash/get";
import { DfpComponent } from "../../../ads/dfp-component";

import "./story-card.m.css";

const TopStoryCard = ({ heading, date, updatedAt, live, id }) => {
  const sponsorAdConfig = useSelector((state) =>
    get(state, ["qt", "config", "ads-config", "slots", "gold_forex_sponsor_ad"], {})
  );
  const enableSponserAds = useSelector((state) =>
    get(state, ["qt", "config", "ads-config", "dfp_ads", "enable_gold_forex_sponser_ads"], false)
  );
  return (
    <div styleName="story-card-wrapper">
      <div styleName="top-section-left-container">
        {heading && <h3>{heading}</h3>}
        <div styleName="label-wrapper">
          {date && <span>{date}</span>}
          {live && <span styleName="label">{live}</span>}
        </div>

        {updatedAt && <span styleName="updated-by">{updatedAt}</span>}
      </div>
      {enableSponserAds && (
        <div>
          <DfpComponent
            adStyleName="ad-slot-size-100x60"
            id={id}
            path={sponsorAdConfig?.ad_unit}
            size={sponsorAdConfig?.sizes}
            viewPortSizeMapping={sponsorAdConfig?.view_port_size_mapping}
          />
        </div>
      )}
    </div>
  );
};

export default TopStoryCard;
