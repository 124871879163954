import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";
import { func, array, object } from "prop-types";
import { get } from "lodash";
import GamesCard from "./GamesCard";
import { useDeviceType } from "../../../../custom-hooks/useDeviceType";
import FourColumnFourStoriesVarTwo from "../../../../collection-templates/four-col-four-stories-var-two";
import CollectionTitle from "../../../../atoms/CollectionTitle";
import { SvgIconHandler } from "../../../../atoms/svg-icon-hadler";
import { DfpComponent } from "../../../../ads/dfp-component";
import { HowToPlay, PlayGames } from "../../../../atoms/svg-icon-hadler/Icon";

import "./GamesHomePage.m.css";

const GamesHomePage = ({ setGameDetails, collection, stories }) => {
  const crossword = "daily-crossword";
  const spellIt = "spell-it";
  const sudoku = "sudoku";
  const wordSearch = "word-search";
  const playGames = "play-games";
  const quickTipsImage = "how-to-play";
  const [selectedGame, setSelectedGame] = useState("CROSSWORD");
  const targetDivRef = useRef(null);
  const deviceType = useDeviceType();
  const scrollToDiv = (game) => {
    const target = targetDivRef.current;
    if (target) {
      const yOffset = -100;
      const y = target.getBoundingClientRect().top + window.pageYOffset + yOffset;

      window.scrollTo({
        top: y,
        behavior: "smooth",
      });
    }
    setSelectedGame(game);
  };
  const adConfig = useSelector((state) => get(state, ["qt", "config", "ads-config", "slots", "games_page_ad"], {}));
  const GameSelector = ({ selectedGame, setSelectedGame }) => {
    const handleSelection = (game) => {
      setSelectedGame(game);
    };
    return (
      <div styleName="how-to-play-button-group">
        <button
          styleName={`how-to-play-button ${selectedGame === "CROSSWORD" ? "selected" : ""}`}
          onClick={() => handleSelection("CROSSWORD")}
        >
          CROSSWORD
        </button>
        <button
          styleName={`how-to-play-button ${selectedGame === "SPELL IT" ? "selected" : ""}`}
          onClick={() => handleSelection("SPELL IT")}
        >
          SPELL IT
        </button>
        <button
          styleName={`how-to-play-button ${selectedGame === "SUDOKU" ? "selected" : ""}`}
          onClick={() => handleSelection("SUDOKU")}
        >
          SUDOKU
        </button>
        <button
          styleName={`how-to-play-button ${selectedGame === "WORD SEARCH" ? "selected" : ""}`}
          onClick={() => handleSelection("WORD SEARCH")}
        >
          WORD SEARCH
        </button>
      </div>
    );
  };

  const PlayTips = ({ selectedGame }) => {
    return (
      <div styleName="how-to-play-tips">
        {selectedGame === "CROSSWORD" ? (
          <>
            <p>Crosswords are not designed to be difficult - keep that in mind when you try any of our puzzles.</p>
            <br></br>
            <p>Here are 4 tips we would like to share:</p>
            <br></br>
            <ul>
              <li>First fill in those clues that are easiest for you, this definitely helps.</li>
              <br></br>
              <li>Then fill in clues that are across them.</li>
              <br></br>
              <li>You could even print it out and try solving with a pencil and eraser.</li>
              <br></br>
              <li>
                It is important to remember that quite often the clues are not a test of knowledge but an attempt at
                making you to think out of the box. The answers could be wordplay, puns, anagrams or even a fill in the
                blank!
              </li>
            </ul>
          </>
        ) : selectedGame === "SPELL IT" ? (
          <>
            <p>Spell It is all about making as many words as you can from the letters provided to you.</p>
            <br></br>
            <p>Here are 6 tips to help you get a head-start:</p>
            <br></br>
            <ul>
              <li>The central letter must be included in each word.</li>
              <br></br>
              <li>You can repeat the letters as many times as you wish in a word.</li>
              <br></br>
              <li>Enter letters by clicking on them, or by typing them in.</li>
              <br></br>
              <li>Special points for finding a pangram – that is, a word that uses all the letters.</li>
              <br></br>
              <li>
                If you’re stuck, use the shuffle button to rearrange the letters – it will help you get a fresh
                perspective!
              </li>
              <br></br>
              <li>Find words that are 4 or more letters long.</li>
            </ul>
          </>
        ) : selectedGame === "SUDOKU" ? (
          <>
            <p>
              Sudoku's objective is simple: Each of the nine blocks has to contain all the numbers from 1 to 9 within
              its squares. Some numbers have already been filled in for you and it’s your mission to figure out the
              rest!
            </p>
            <br></br>
            <p>Here are 4 tips to help you ace the game:</p>
            <br></br>
            <ul>
              <li>
                The most important thing to remember is that each number from 1 to 9 can appear only once in a row,
                column or box. If you repeat a number, it will impact the entire game.
              </li>
              <br></br>
              <li>Try using the process of elimination to figure out which numbers can go in each space.</li>
              <br></br>
              <li>
                Use the Pen function when you are sure about your answer. The Pencil mode can help you track the
                multiple options that are possible in each box.
              </li>
              <br></br>
              <li>
                This is a game of reasoning and logic, so don’t try to guess. If you are stuck, scan the other areas of
                the grid until you see an opportunity to place a number.
              </li>
            </ul>
          </>
        ) : (
          <>
            <p>In each Wordsearch puzzle, find all the listed words hidden in the grid of letters.</p>
            <br></br>
            <p>Here are 4 tips to get you started:</p>
            <br></br>
            <ul>
              <li>Words can run horizontally, vertically or diagonally. They may even go forward or backward!</li>
              <br></br>
              <li>Words will nearly always overlap.</li>
              <br></br>
              <li>
                To mark words you have found, tap or click the first letter of the word, drag until the last letter and
                release.
              </li>
              <br></br>
              <li>
                A useful strategy is to look for the first letter of a listed word, and then check all eight possible
                directions to reveal the entire word.
              </li>
            </ul>
          </>
        )}
      </div>
    );
  };

  const PlayGamesCard = () => {
    const handleClick = (game) => {
      window.location.href = `games/${game}${game === "sudoku" ? "?difficulty=easy" : ""}`;
    };
    return (
      <div styleName="play-games-wrapper">
        <div styleName="play-games-container">
          <div styleName="play-games-image-container">
            {/* <SvgIconHandler type={playGames} width="100%" height="100%" viewBox=""></SvgIconHandler> */}
            <PlayGames />
          </div>
          <div styleName="play-games-buttons">
            <button onClick={() => handleClick("crossword")}>CROSSWORD</button>
            <button onClick={() => handleClick("spell-it")}>SPELL IT</button>
            <button onClick={() => handleClick("sudoku")}>SUDOKU</button>
            <button onClick={() => handleClick("word-search")}>WORD SEARCH</button>
          </div>
        </div>
      </div>
    );
  };

  const HowToPlayCard = ({ selectedGame, setSelectedGame }) => {
    return (
      <div styleName="how-to-play-card-wrapper" ref={targetDivRef}>
        <div styleName="how-to-play-card-container">
          <div styleName="how-to-play-header">
            <h1 styleName="how-to-play-title">
              HOW TO PLAY
              {deviceType !== "desktop" && <HowToPlay />}
            </h1>
            <GameSelector selectedGame={selectedGame} setSelectedGame={setSelectedGame} />
            <div styleName="how-to-play-content">
              <PlayTips selectedGame={selectedGame} />
            </div>
          </div>
          {deviceType === "desktop" && (
            <div styleName="how-to-play-quick-tips">
              <HowToPlay />
            </div>
          )}
        </div>
        <div styleName="how-to-play-feedback">
          <div>
            <SvgIconHandler type="mail" width="19" height="15" viewBox="0 0 19 15"></SvgIconHandler>
          </div>
          <p>
            Have any feedback on the Games section? Email us at{" "}
            <a href="mailto:Games@gulfnews.com" styleName="how-to-play-email">
              Games@gulfnews.com
            </a>
          </p>
        </div>
      </div>
    );
  };

  return (
    <div styleName="games-page-wrapper">
      <GamesCard
        backgroundColor={"#2A87C71A"}
        cardName={"SUDOKU"}
        cardSlug={"sudoku"}
        cardDescription={"Fill each 9x9 grid so every row, column, and 3x3 block contains all numbers from 1 to 9."}
        image={sudoku}
        difficultySelector={true}
        setGameDetails={setGameDetails}
        quickTipsMethod={() => scrollToDiv("SUDOKU")}
      ></GamesCard>
      <PlayGamesCard />
      <GamesCard
        backgroundColor={"#F8A7221A"}
        cardName={"DAILY CROSSWORD"}
        cardSlug={"crossword"}
        cardDescription={
          "Crosswords are not designed to be difficult - keep that in mind when you try any of our puzzles."
        }
        image={crossword}
        setGameDetails={setGameDetails}
        quickTipsMethod={() => scrollToDiv("CROSSWORD")}
      ></GamesCard>
      <GamesCard
        backgroundColor={"#E11B231A"}
        cardName={"SPELL IT"}
        cardSlug={"spell-it"}
        cardDescription={"Spell It is all about making as many words as you can from the letters provided to you."}
        image={spellIt}
        setGameDetails={setGameDetails}
        quickTipsMethod={() => scrollToDiv("SPELL IT")}
      ></GamesCard>
      {stories?.length > 0 && (
        <div styleName="stories-wrapper">
          <CollectionTitle collection={collection} enableSponsorAd={false} />
          <FourColumnFourStoriesVarTwo
            collection={collection}
            stories={stories}
            showTime={true}
            showColSeparator={true}
            showStSeparator={stories?.length > 2 ? true : false}
          />
        </div>
      )}
      <GamesCard
        backgroundColor={"#E05B281A"}
        cardName={"WORD SEARCH"}
        cardSlug={"word-search"}
        cardDescription={"In each Wordsearch puzzle, find all the listed words hidden in the grid of letters."}
        image={wordSearch}
        setGameDetails={setGameDetails}
        quickTipsMethod={() => scrollToDiv("WORD SEARCH")}
      ></GamesCard>
      <HowToPlayCard selectedGame={selectedGame} setSelectedGame={setSelectedGame} />
      <div styleName="adSlot">
        <DfpComponent
          adStyleName={"ad-slot-size-970x250"}
          id="games-page-bottom-ad"
          path={adConfig.ad_unit}
          size={adConfig.sizes}
          viewPortSizeMapping={adConfig.view_port_size_mapping}
        />
      </div>
    </div>
  );
};

GamesHomePage.propTypes = {
  setGameDetails: func,
  collection: object,
  stories: array,
};

export default GamesHomePage;
