import React from "react";
import "./foreign-exchange-table.m.css";

const ForeignExchangeTable = ({ foreignApiData }) => {
  return (
    <div styleName="currency-table-container">
      <table styleName="currency-table">
        <thead>
          <tr>
            <th rowSpan="3" styleName="day-header">
              Currencies
            </th>
          </tr>
          <tr>
            <th colSpan="2" styleName="schedule-header">
              UAE Dirham
            </th>
          </tr>
          <tr>
            <th styleName="buy-header">Buy</th>
            <th styleName="sell-header">Sell</th>
          </tr>
        </thead>
        <tbody>
          {foreignApiData?.data?.length > 0 ? (
            foreignApiData?.data?.map((currency, index) => (
              <tr key={index}>
                <td>
                  {currency?.currencyName} ({currency?.currencyCode})
                </td>
                <td>{currency?.exchangeRatePC.toFixed(3)}</td>
                <td>{currency?.exchangeRateSL.toFixed(3)}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="4">No data available</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default ForeignExchangeTable;
