import React from "react";
import PropTypes from "prop-types";
import Page from "./Page";
import { Paginator } from "./Paginator";

import "./pagination.m.css";
const Pagination = (props) => {
  const {
    itemsCountPerPage = 10,
    pageRangeDisplayed = 5,
    activePage = 1,
    prevPageText = "⟨",
    firstPageText = "«",
    nextPageText = "⟩",
    lastPageText = "»",
    innerClass = "",
    itemClass = "",
    hideFirstLastPages = false,
    hideDisabled = false,
    totalItemsCount,
    handleSinglePageChange,
    handleMultiplePageChange,
    handleSelfPageChange,
    activeClass,
    itemClassFirst,
    itemClassPrev,
    itemClassNext,
    itemClassLast,
    disabledClass,
  } = props;

  function isFirstPageVisible(hasPreviousPage) {
    return hideFirstLastPages || (hideDisabled && !hasPreviousPage) ? false : true;
  }

  function isPrevPageVisible(hasPreviousPage) {
    return hideDisabled && !hasPreviousPage ? false : true;
  }

  function isNextPageVisible(hasNextPage) {
    return hideDisabled && !hasNextPage ? false : true;
  }

  function isLastPageVisible(hasNextPage) {
    return hideDisabled && !hasNextPage ? false : true;
  }

  function buildPages() {
    const pages = [];

    const paginationInfo = Paginator(totalItemsCount, activePage, itemsCountPerPage, pageRangeDisplayed);

    for (let i = paginationInfo.firstPage; i <= paginationInfo.lastPage; i++) {
      pages.push(
        <Page
          isActive={i === activePage}
          key={i}
          pageNumber={i}
          pageText={i + ""}
          onClick={handleSelfPageChange}
          itemClass={itemClass}
          activeClass={activeClass}
          ariaLabel={`Go to page number ${i}`}
        />
      );
    }

    isPrevPageVisible(paginationInfo.hasPreviousPage) &&
      pages.unshift(
        <Page
          key={"prev" + paginationInfo.previousPage}
          pageNumber={paginationInfo.previousPage}
          onClick={handleSinglePageChange}
          pageText={prevPageText}
          isDisabled={!paginationInfo.hasPreviousPage}
          itemClass={`${itemClass} ${itemClassPrev}`}
          disabledClass={disabledClass}
          singlePageTurnType={-1}
          ariaLabel="Go to previous page"
        />
      );

    isFirstPageVisible(paginationInfo.hasPreviousPage) &&
      pages.unshift(
        <Page
          key={"first"}
          pageNumber={activePage}
          onClick={handleMultiplePageChange}
          pageText={firstPageText}
          isDisabled={activePage > 1 ? false : true}
          itemClass={`${itemClass} ${itemClassFirst}`}
          disabledClass={disabledClass}
          multiplePageTurnType={-1}
          ariaLabel={`Go to first page`}
        />
      );

    isNextPageVisible(paginationInfo.hasNextPage) &&
      pages.push(
        <Page
          key={"next" + paginationInfo.nextPage}
          pageNumber={paginationInfo.nextPage}
          onClick={handleSinglePageChange}
          pageText={nextPageText}
          isDisabled={!paginationInfo.hasNextPage}
          itemClass={`${itemClass} ${itemClassNext}`}
          disabledClass={disabledClass}
          ariaLabel="Go to next page"
          singlePageTurnType={1}
        />
      );

    isLastPageVisible(paginationInfo.hasNextPage) &&
      pages.push(
        <Page
          key={"last"}
          pageNumber={activePage}
          onClick={handleMultiplePageChange}
          pageText={lastPageText}
          isDisabled={activePage < Math.ceil(totalItemsCount / itemsCountPerPage) ? false : true}
          itemClass={`${itemClass} ${itemClassLast}`}
          disabledClass={disabledClass}
          multiplePageTurnType={1}
          ariaLabel={`Go to last page`}
        />
      );

    return pages;
  }

  const pages = buildPages();
  return <div styleName={innerClass}>{pages}</div>;
};

Pagination.propTypes = {
  totalItemsCount: PropTypes.number.isRequired,
  handleSinglePageChange: PropTypes.func.isRequired,
  handleMultiplePageChange: PropTypes.func.isRequired,
  handleSelfPageChange: PropTypes.func.isRequired,
  activePage: PropTypes.number,
  itemsCountPerPage: PropTypes.number,
  pageRangeDisplayed: PropTypes.number,
  prevPageText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  nextPageText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  lastPageText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  firstPageText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  disabledClass: PropTypes.string,
  hideDisabled: PropTypes.bool,
  innerClass: PropTypes.string,
  itemClass: PropTypes.string,
  itemClassFirst: PropTypes.string,
  itemClassPrev: PropTypes.string,
  itemClassNext: PropTypes.string,
  itemClassLast: PropTypes.string,
  activeClass: PropTypes.string,
  hideFirstLastPages: PropTypes.bool,
};

export default Pagination;
