import React from "react";
import { string, object, bool } from "prop-types";

import "./page-title.m.css";

export default function PageTitle({ title }) {
  return (
    <div styleName="page-title-container">
      <h1 styleName="title">{title}</h1>
    </div>
  );
}

PageTitle.propTypes = {
  title: object,
};
