import React, { useState } from "react";
import { Link } from "@quintype/components";
import { SvgIconHandler } from "../../../atoms/svg-icon-hadler";
import { useDeviceType } from "../../../custom-hooks/useDeviceType";

import "./share-icons.m.css";

export default function SocialShareIconsButton({ story, imageDimension }) {
  const [shareMenuOpen, setShareMenuOpen] = useState(false);
  const [copyPopupOpen, setCopyPopupOpen] = useState(false);
  const { width, height, viewbox } = imageDimension;

  const deviceType = useDeviceType();
  const isMobile = deviceType === "mobile";

  const iconSet = [
    { id: "share-facebook", label: "Facebook", url: `https://www.facebook.com/sharer.php?u=${story.url}` },
    { id: "share-whatsapp", label: "Whatsapp", url: `https://api.whatsapp.com/send?text=${story.url}` },
  ];
  const mobileIcon = { id: "share-x", label: "Twitter", url: `https://twitter.com/intent/tweet?url=${story.url}` };

  const handleCopyLink = () => {
    setCopyPopupOpen(true);
    navigator.clipboard.writeText(window.location.href);
    setTimeout(() => setCopyPopupOpen(false), 3000);
  };

  return (
    <span styleName="share">
      {iconSet.map((item, index) => (
        <Link key={index} href={item.url} target="_blank" rel="noopener noreferrer">
          <SvgIconHandler type={item.id} height={height} width={width} viewBox={viewbox} />
        </Link>
      ))}
      <span styleName="copy-link-container" tabIndex={0}>
        {copyPopupOpen && <span styleName="copy-link-2">Link copied!</span>}
      </span>

      {isMobile && (
        <span>
          <Link href={mobileIcon.url} target="_blank" rel="noopener noreferrer">
            <SvgIconHandler type="share-x" height={height} width={width} viewBox={viewbox} />
          </Link>
        </span>
      )}

      <span styleName="share-menu-container" tabIndex={0} onClick={() => setShareMenuOpen(!shareMenuOpen)}>
        <SvgIconHandler type="share-menu" height={height} width={width} viewBox={viewbox} />
        {shareMenuOpen && (
          <div styleName="share-menu">
            {iconSet.map((item, index) => (
              <Link key={index} href={item.url} target="_blank" rel="noopener noreferrer">
                <SvgIconHandler type={item.id} height={32} width={32} viewBox="0 0 32 32" />
                <span>{item.label}</span>
              </Link>
            ))}

            {isMobile && (
              <Link href={mobileIcon.url} target="_blank" rel="noopener noreferrer">
                <SvgIconHandler type="share-x" height={32} width={32} viewBox="0 0 32 32" />
                <span>{mobileIcon.label}</span>
              </Link>
            )}

            <span styleName="copy-link-container " tabIndex={0} onClick={handleCopyLink}>
              <SvgIconHandler type="share-link" height={32} width={32} viewBox="0 0 32 32" />
              <span styleName="copy-link">Copy link</span>
            </span>
          </div>
        )}
      </span>
    </span>
  );
}
