import React, { useState } from "react";
import { bool, func } from "prop-types";
import Game from "./Game";
import { useDeviceType } from "../../../../custom-hooks/useDeviceType";
import { Tips } from "../../../../atoms/svg-icon-hadler/Icon";

import "./GamesCard.m.css";

const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
const calculateDateAndDay = (daysAgo) => {
  const today = new Date();
  const calculatedDate = new Date(today);
  calculatedDate.setDate(today.getDate() - daysAgo);
  const day = String(calculatedDate.getDate()).padStart(2, "0");
  const month = String(calculatedDate.getMonth() + 1).padStart(2, "0");
  const year = calculatedDate.getFullYear();

  const formattedDate = `${day}/${month}/${year}`;
  const dayOfWeek = dayNames[calculatedDate.getDay()];
  return { day: dayOfWeek, date: formattedDate };
};

const DifficultySelector = ({ selectedDifficulty, setSelectedDifficulty }) => {
  const handleSelection = (level) => {
    setSelectedDifficulty(level);
  };
  return (
    <div styleName="difficulty-selector">
      <button styleName={selectedDifficulty === "easy" ? "selected" : ""} onClick={() => handleSelection("easy")}>
        EASY
      </button>
      <button styleName={selectedDifficulty === "medium" ? "selected" : ""} onClick={() => handleSelection("medium")}>
        MEDIUM
      </button>
      <button styleName={selectedDifficulty === "hard" ? "selected" : ""} onClick={() => handleSelection("hard")}>
        HARD
      </button>
    </div>
  );
};

const formatDate = (dateString) => {
  const [day, month, year] = dateString.split("/").map(Number);
  const date = new Date(year, month - 1, day);
  const formattedDate = `${date.getFullYear()}${String(date.getMonth() + 1).padStart(2, "0")}${String(
    date.getDate()
  ).padStart(2, "0")}`;
  return formattedDate;
};

const GamesCard = ({
  cardName = "cardName",
  cardDescription = "cardDescription",
  cardSlug,
  backgroundColor = "#2A87C71A",
  image,
  difficultySelector = false,
  setGameDetails,
  quickTipsMethod,
}) => {
  const [selectedDifficulty, setSelectedDifficulty] = useState("easy");
  const gameCards = [calculateDateAndDay(0), calculateDateAndDay(1), calculateDateAndDay(2), calculateDateAndDay(3)];
  const deviceType = useDeviceType();
  const handleClick = (date) => {
    const formattedDate = formatDate(date);
    window.location.href = `games/${cardSlug}?id=gulfnews-${
      cardSlug == "spell-it"
        ? "spellit-"
        : cardSlug == "sudoku"
        ? "sudoku-" + selectedDifficulty + "-"
        : cardSlug == "word-search"
        ? "wordsearch-"
        : ""
    }${formattedDate}${cardSlug == "sudoku" ? "&difficulty=" + selectedDifficulty : ""}`;
  };
  return (
    <div styleName="games-card-wrapper" style={{ backgroundColor: backgroundColor }}>
      <div style={{ position: "relative" }}>
        {deviceType === "mobile" && (
          <div styleName="tips-button" onClick={quickTipsMethod}>
            <p>Tips</p> <Tips />
          </div>
        )}
        <div styleName="games-card-header">
          <h1 styleName="games-card-title">{cardName}</h1>
          {deviceType !== "mobile" && (
            <div styleName="tips-button" onClick={quickTipsMethod}>
              <p>Tips</p> <Tips />
            </div>
          )}
          {difficultySelector && (
            <DifficultySelector setSelectedDifficulty={setSelectedDifficulty} selectedDifficulty={selectedDifficulty} />
          )}
          <p styleName="games-card-description">{cardDescription}</p>
        </div>
        <div styleName="game-card">
          {gameCards.map((game, index) => (
            <Game
              key={index}
              day={game.day}
              date={game.date}
              image={image}
              handleClick={() => handleClick(game.date)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

GamesCard.propTypes = {
  difficultySelector: bool,
  setSelectedDifficulty: func,
};

export default GamesCard;
