import React, { useEffect, useState } from "react";
import { Link } from "@quintype/components";
import get from "lodash/get";
import { object, bool, string } from "prop-types";
import { PARTNER_CONTENT_STORY_TEMPLATE } from "../../../../constants";
import { getCollectionitems } from "../../../../../api/utils";
import { getStoryHeadline, getStoryLabel } from "../../../utils/story";
import { PublishAndReadTime } from "../../../atoms/PublishAndReadTime";
import { HeroImage } from "../../../atoms/HeroImage";
import { Label } from "../../../atoms/Label";
import Headline from "../../../atoms/Headline";

import "./trending-stories.m.css";

const TrendingStories = ({
  showHeroImage,
  isPhotoSectioPage,
  defaultFallback = false,
  showReadTime = true,
  showLabel = true,
  showTimestamp = true,
  headlineCustomStyle = {},
}) => {
  const [trendingStories, setTrendingStories] = useState([]);
  useEffect(() => {
    getCollectionitems("trending-stories", 0, 3).then((trendingStoriesResult) => {
      setTrendingStories(trendingStoriesResult.items);
    });
  }, []);

  const HorizontalStory = ({
    story,
    showThumbnail,
    showReadTime,
    showTimestamp,
    storySeperator,
    columnSeperator,
    defaultFallback,
    textColor,
    isPhotoSectioPage,
    showLabel = true,
  }) => {
    const sponsor = get(story, ["metadata", "sponsored-by"], null);
    const isPartneredContent = story["story-template"] === PARTNER_CONTENT_STORY_TEMPLATE || sponsor !== null;
    const imageUrl =
      get(story, ["hero-image-s3-key"], "") ||
      get(story, ["alternative", "home", "default", "hero-image", "hero-image-s3-key"], "");
    const showImageThumbnail = (showThumbnail || isPartneredContent) && (imageUrl || defaultFallback);
    const headline = getStoryHeadline(story);
    const label = getStoryLabel(story);
    const headlineStyleName = showImageThumbnail ? "show-thumbnail" : "hide-thumbnail"; // Full width if no image
    return (
      <div
        styleName={isPhotoSectioPage ? `photo-story` : `common-story`}
        key={story.id}
        style={{ border: storySeperator || "none", color: textColor || "" }}
      >
        <Link href={`/${story.slug}`}>
          <div styleName="second-card" style={{ border: columnSeperator || "none" }}>
            {isPhotoSectioPage && showImageThumbnail && (
              <div styleName="vertical-story-hero-image">
                <HeroImage
                  story={story}
                  headline={headline}
                  aspectRatio={[[4, 3]]}
                  iconSizes={{ height: 24, width: 24 }}
                  imageUrl={imageUrl}
                  defaultWidth={175}
                />
              </div>
            )}
            <div styleName={`left-column ${headlineStyleName}`}>
              {label && showLabel && <Label story={story} />}
              {headline && <Headline headline={headline} styleName={`headline`} headlineStyles={headlineCustomStyle} />}
              <PublishAndReadTime showReadTime={showReadTime} showTimestamp={showTimestamp} story={story} />
            </div>
          </div>
        </Link>
      </div>
    );
  };

  HorizontalStory.propTypes = {
    story: object,
    showThumbnail: bool,
    showBorder: bool,
    showReadTime: bool,
    showTimestamp: bool,
    defaultFallback: bool,
    storySeperator: bool,
    columnSeperator: bool,
    textColor: string,
    isPhotoSectioPage: bool,
  };

  return (
    <div>
      <div styleName="stories-wrapper">
        <div styleName="right-column">
          <span styleName="trending-stories-text">TRENDING STORIES</span>
          <div styleName="third-column">
            {trendingStories?.map((story, index) => (
              <HorizontalStory
                key={story.id}
                story={story.story}
                showReadTime={showReadTime}
                showTimestamp={showTimestamp}
                storySeperator={true}
                columnSeperator={true}
                textColor={"#ffffff"}
                showThumbnail={true}
                defaultFallback={defaultFallback}
                isPhotoSectioPage={isPhotoSectioPage}
                showLabel={showLabel}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
export { TrendingStories };
