import React from "react";
import { Link } from "@quintype/components";

import "./BottomText.m.css";

const BottomText = () => {
  return (
    <div styleName="prayer-page-text-wrapper">
      {/* <a href="https://imedia.gulfnews.com/ramadan/Ramadan-calendar.pdf">
        Download Ramadan Prayer Timetable for easy reference
      </a> */}
      <h1>Islam: Third Abrahamic faith </h1>
      <p>
        Islam is the final of the three Abrahamic faiths, which emerged in the 7th century after Judaism and
        Christianity. It is an Abrahamic monotheistic religion centred around the holy Quran and the teachings of
        Prophet Mohammad (Peace Be Upon Him).  <br />
        <br />
        The term Islam means submission to the will of Allah (God) by doing what has been commanded and refraining from
        what is prohibited. Muslims aim to lead a life of complete submission to Allah, firmly believing that nothing
        can happen without Allah’s permission, while also recognizing human free will. According to Islamic teachings,
        Allah’s word was revealed to Prophet Mohammad (PBUH) through the angel Gabriel.  <br />
        <br />
        The core beliefs and practices of Islam are encapsulated in the five pillars, which are considered obligatory
        for all Muslims to fulfil during their lifetime. 
      </p>
      <h2>The five pillars of Islam </h2>
      <h3>1. Shahadah (declaration of faith)</h3>
      <p>
        Shahadah is the declaration of faith in Islam, affirming one's belief in the oneness of Allah and the
        prophethood of Mohammad (PBUH). It is considered the fundamental statement of faith and commitment made by
        Muslims, which goes as: "There is no God but Allah, and Mohammad is the Messenger of Allah." Reciting Shahadah
        with conviction is central to Islam as it is the primary way of becoming a Muslim. The recitation of Shahadah
        symbolizes the acceptance of the Islamic faith, and it is also recited during daily prayers.
      </p>
      <h3>2. Salah (ritual prayer)</h3>
      <p>
        Salah or ritual prayer is an essential aspect of Islamic worship and involves conforming to the will of Allah.
        Muslims pray five times a day, facing the Kaaba in Mecca, which is considered the holiest site in Islam.
      </p>
      <h3>3. Zakat (charity)</h3>
      <p>
        Zakat is the third pillar of Islam, and it is an obligatory charity that every Muslim must pay. It is an act of
        worship and a means of purification of wealth. The word ‘Zakat’ means ‘to purify’, and it refers to the
        obligation of giving a certain portion of one's wealth to those in need. <br />
        <br />
        Zakat is calculated at 2.5 per cent of the accumulated savings on one's annual income and is obligatory on every
        Muslim who has reached the threshold of wealth, known as Nisab. The Nisab is the minimum amount of wealth a
        Muslim must possess before being liable to pay Zakat. The current Nisab is calculated based on the value of 85g
        of pure gold. 
        <br />
        <br /> Zakat can be given to eight categories of people, including the poor, the needy, those employed to
        collect Zakat, those in debt, those in the way of Allah, travellers, and the weak from amongst the newly
        converted to Islam. Zakat can be paid to the beneficiaries directly or through charitable organizations.
      </p>
      <h3>4. Sawm (fasting)</h3>
      <p>
        Fasting (Sawm) is the fourth pillar of Islam, and it refers to fasting during the month of Ramadan. It is one of
        the most important acts of worship in Islam and is considered a means of purifying the soul and strengthening
        one's faith.
        <br />
        <br /> During Ramadan, Muslims abstain from food and drink from dawn until sunset. Fasting is obligatory for
        every adult Muslim who is healthy and has reached the age of puberty. However, those who are sick, travelling,
        pregnant, breastfeeding, or menstruating are exempted from fasting.
        <br />
        <br /> To begin their fast, Muslims have a pre-dawn meal called Suhur or Imsak. They then end the fast at
        sunset, with a meal called Iftar. <br />
        <br />
        During Ramadan, Muslims also increase their acts of worship and perform extra prayers at night called Taraweeh.
      </p>
      <h3>5. Hajj (Pilgrimage to Mecca)</h3>
      <p>
        Hajj is the fifth pillar of Islam and is the pilgrimage to the holy city of Mecca, which is obligatory for every
        Muslim who is physically and financially able to perform it, at least once in their lifetime. Hajj is performed
        in the month of Dhu al-Hijjah, the last month of the Islamic calendar. <br />
        <br />
        The rituals of Hajj include wearing the Ihram (a white cloth) and performing Tawaf (circumambulation) around the
        Kaaba, which is the holiest site in Islam. The pilgrims also perform Sa'i, which is walking seven times between
        the hills of Safa and Marwa, and they spend a day in Arafat, where they pray for forgiveness and mercy.
        <br />
        <br /> Hajj also includes the sacrifice of an animal and the stoning of satan in Mina. After completing the
        rituals of Hajj, Muslims celebrate the Eid al-Adha festival, which marks the end of the pilgrimage.
      </p>
      <h2>Essential aspects of the Islamic faith </h2>
      <p>
        In addition to the five pillars of Islam, there are essential aspects of the Islamic faith, such as Belief in
        Allah, His Angels, His Books, His Messengers, The Day of Judgement, and Fate, both good and evil, from Allah.
        <br />
        <br />  Quran is the holy book of Islam, and it contains the words of Allah, which were revealed to Prophet
        Mohammed (PBUH) through the angel Gabriel over 23 years.
        <br />
        <br /> The Quran combined with Hadith upon the understanding of the companions of the Prophet Mohammed (PBUH) is
        the primary source of Islamic teachings, and it covers a wide range of topics, including faith, morality,
        ethics, and social justice. Muslims believe that the Quran is a complete and perfect book, and it provides
        guidance for their lives.
      </p>
      <hr />
      <h1>The Prayer Times</h1>
      <p>
        In addition to the five pillars of Islam, there are essential aspects of the Islamic faith, such as Belief in
        Allah, His Angels, His Books, His Messengers, The Day of Judgement, and Fate, both good and evil, from Allah.{" "}
        <br />
        <br />  Quran is the holy book of Islam, and it contains the words of Allah, which were revealed to Prophet
        Mohammed (PBUH) through the angel Gabriel over 23 years. <br />
        <br />
        The Quran combined with Hadith upon the understanding of the companions of the Prophet Mohammed (PBUH) is the
        primary source of Islamic teachings, and it covers a wide range of topics, including faith, morality, ethics,
        and social justice. Muslims believe that the Quran is a complete and perfect book, and it provides guidance for
        their lives.
      </p>
      <h2>Different types of prayers</h2>
      <p>
        There are two types of prayers in Islam: Fard (obligatory prayers) and Sunnah and Nafl (optional prayers).{" "}
        <br />
        <br />
        Fard or obligatory prayers are compulsory for every Muslim. Muslims are required to perform five obligatory
        prayers every day. These prayers are considered a pillar of Islam.
      </p>
      <h3>Sunnah and Nafl prayers</h3>
      <p>
        Sunnah and Nafl prayers are considered meritorious and earn additional rewards from Allah. Sunnah prayers are
        based on the actions and sayings of Prophet Mohammad (PBUH), while Nafl prayers are voluntary prayers that can
        be performed at any time, apart from mandatory prayer times. <br />
        <br />
        Salah consists of two, three or four rakahs (units) within. A rakah consists of a set of actions beginning with
        the Qiyaam (standing with hands folded), followed by the Rukuh (bowing down, supported by placing hands on the
        knees), standing up once again before going into the Sajdah (prostration on the ground) twice and
        the Tashahud (sitting on the legs) and ending with the Tasleem (turning the head to each side while saying
        Salaam alaikum wa rahmathullah). Each of these positions includes different recitations.
      </p>
      <h2>The five obligatory prayers</h2>
      <h3>1. Fajr Prayer</h3>
      <p>
        Also known as the dawn prayer, is the first of the five daily obligatory prayers in Islam. It is performed
        before sunrise and consists of two rakahs (units) of prayer. Additionally, two Sunnah rakahs are performed
        before the obligatory prayer.
      </p>
      <h3>2. Dhuhr Prayer</h3>
      <p>
        It is the second of the five daily obligatory prayers in Islam. It is performed after the sun passes its zenith
        or the highest point in the sky and consists of four rakahs of prayer. Additionally, four Sunnah rakahs are
        usually performed before the obligatory prayer, and two are performed after it.
      </p>
      <h3>3. Asr Prayer</h3>
      <p>
        Also known as the late afternoon prayer, is the third of the five daily obligatory prayers in Islam. It is
        performed after the time for Dhuhr prayer has passed and before sunset, and consists of four rakahs of prayer.
      </p>
      <h3>4. Maghrib Prayer</h3>
      <p>
        It is the fourth of the five daily obligatory prayers in Islam. It is performed just after sunset and consists
        of three rakahs of prayer. Additionally, two Sunnah rakahs are usually performed after the obligatory prayer.
      </p>
      <h3>5. Isha Prayer</h3>
      <p>
        It is the fifth and final daily obligatory prayer in Islam. It is performed after the twilight has disappeared
        and the night has begun. Isha prayer consists of four obligatory rakahs of prayer. Additionally, two Sunnah
        rakahs are usually performed after the obligatory prayer.
      </p>
      <p>
        Additionally, there is Witr prayer, performed after the Sunnah rakahs of Isha. It is usually performed as one,
        three, five, etc., in odd numbers. <br />
        <br /> It is considered a sin to intentionally miss performing obligatory prayers. However, if a prayer is
        missed due to forgetfulness or some other unavoidable circumstance, the missed prayer must be offered as soon as
        possible to make up for it, before offering the prayer that is next in line
      </p>
      <hr />
      <h3>Adhan</h3>
      <p>
        Adhan is the Islamic call to prayer recited by a muezzin at prescribed times of the day. Adhan is traditionally
        recited from the minaret of a mosque five times daily. It is the first call summoning Muslims to enter the
        mosque for obligatory (Fard) prayer. A second call, known as the Iqamah, is made inside the mosque to summon
        those present to line up for the beginning of the prayers. <br />
        <br />
        In Islam, it is considered important to call Adhan before each prayer.
      </p>
      <h3>Ablution (Wudu)</h3>
      <p>
        Ablution, also known as Wudu, is the Islamic procedure for cleansing certain parts of the body. It is a type of
        ritual purification to be performed in preparation for prayer and worship. Wudu involves using clean water on
        specific body parts as prescribed in the Quran and Hadith. <br />
        <br />
        The four Fard acts of Wudu consist of washing the face, and arms, wiping the head, and washing the feet with
        water. Wudu is an essential part of ritual purity in Islam and should be carried out following the prescribed
        procedure.
      </p>
      <h3>Intention (Niyyah)</h3>
      <p>
        Intention, Niyyah, is the Islamic concept of performing an act for the sake of Allah. One should make Niyyah
        before  <br />
        <br />
        performing any act by focusing on the intention to please Allah. To truly perform Wudu or prayer, you should
        centre yourself and quieten your thoughts, focusing seriously on what you are doing (intending to perform wudu
        or prayer). <br />
        <br />
        Niyyah for Salah is not said out loud and should be made in the heart; focusing on the phrase "Bismillah" (in
        the name of Allah) is a good way to accomplish the necessary centring.
      </p>
      <h3>Mosques</h3>
      <p>
        The mosque is the place of worship for Muslims, and it is considered the centre of their religious and social
        lives. Muslims gather in the mosque five times a day to offer their prayers, and they also perform other
        religious activities, such as reading the Quran, attending lectures, and participating in community events.{" "}
        <br />
        <br />
        The mosque also serves as a refuge for those in need, and it is a place where Muslims can find support and
        guidance during difficult times.
      </p>
      <h3>The Prophets</h3>
      <p>
        The significance of prophets in Islam lies in their ability to offer guidance and impart knowledge about the
        Islamic faith. They serve as exemplars to Muslims, having lived their lives in accordance with Allah's
        teachings, providing a model for how to lead a virtuous life. Their stories and teachings are found in the
        Quran, which Muslims believe is the ultimate source of guidance and wisdom. <br />
        <br />
        In Islam, prophets and messengers were sent by Allah, to guide and educate humanity towards belief in His
        Oneness (Tawheed). Muslims believe in all of the prophets, such as Adam, Abraham, Moses, and Jesus, with Prophet
        Mohammed (PBUH) regarded as the last prophet and the seal of the prophets.
      </p>
      <h3>Angels</h3>
      <p>
        In Islam, angels are beings that were brought into existence by Allah, and they hold great significance. Muslims
        firmly believe that angels act as emissaries of Allah who execute his directives.
      </p>
    </div>
  );
};

export default BottomText;
