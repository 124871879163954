import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import get from "lodash/get";
import { DfpComponent } from "../../ads/dfp-component";

import "./GratuityCalculator.m.css";

export const GratuityCalculatorPage = () => {
  const rowAdConfig = useSelector((state) =>
    get(state, ["qt", "config", "ads-config", "slots", "section_page_adslot_1"], {})
  );
  const [firstWorkingDay, setFirstWorkingDay] = useState("");
  const [lastWorkingDay, setLastWorkingDay] = useState("");
  const [basicSalary, setBasicSalary] = useState("");
  const [gratuity, setGratuity] = useState(0);
  const [dayDifference, setDayDifference] = useState("");
  const [error, setError] = useState({
    dateError: "",
    salaryError: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    const calculatedGratuity = calculateGratuity();
    setGratuity(calculatedGratuity);
  };

  const calculateGratuity = () => {
    if (!basicSalary) {
      setError((prevState) => ({
        ...prevState,
        salaryError: "Please enter basic salary",
      }));
      return 0;
    }
    if (!firstWorkingDay || !lastWorkingDay || error.dateError !== "" || error.salaryError !== "") {
      return 0;
    }

    const getDateDifference = (startDate, endDate) => {
      let totalYears = endDate.getFullYear() - startDate.getFullYear();
      let totalMonths = endDate.getMonth() - startDate.getMonth();
      let totalDays = endDate.getDate() - startDate.getDate();

      if (totalDays < 0) {
        totalMonths -= 1;
        totalDays += new Date(endDate.getFullYear(), endDate.getMonth(), 0).getDate();
      }

      if (totalMonths < 0) {
        totalMonths += 12;
        totalYears -= 1;
      }

      return { totalYears, totalMonths, totalDays };
    };

    const calculateAmountForLessThanFiveYears = (dailySalary21Days, years, months, days) => {
      let amount = dailySalary21Days * years;
      if (months > 0) {
        amount += (dailySalary21Days / 12) * months;
      }
      if (days > 0) {
        amount += (dailySalary21Days / 365) * days;
      }
      return amount;
    };

    const calculateAmountForFiveOrMoreYears = (dailySalary21Days, basicSalary, years, months, days) => {
      let amount = 5 * dailySalary21Days + basicSalary * (years - 5);
      if (months > 0) {
        amount += (basicSalary / 12) * months;
      }
      if (days > 0) {
        amount += (basicSalary / 365) * days;
      }
      return amount;
    };

    let startDate = new Date(firstWorkingDay);
    startDate.setDate(startDate.getDate() - 1);
    const endDate = new Date(lastWorkingDay);

    const { totalYears, totalMonths, totalDays } = getDateDifference(startDate, endDate);
    const dailySalary21Days = 21 * (basicSalary / 30);

    let gratuityAmount = 0;

    if (totalYears < 1) {
      gratuityAmount = 0;
    } else if (totalYears < 5) {
      gratuityAmount = calculateAmountForLessThanFiveYears(dailySalary21Days, totalYears, totalMonths, totalDays);
    } else {
      gratuityAmount = calculateAmountForFiveOrMoreYears(
        dailySalary21Days,
        basicSalary,
        totalYears,
        totalMonths,
        totalDays
      );
    }

    const maxGratuity = basicSalary * 24;
    return Math.round(gratuityAmount > maxGratuity ? maxGratuity : gratuityAmount);
  };

  useEffect(() => {
    let startDate = new Date(firstWorkingDay);
    let endDate = new Date(lastWorkingDay);

    if (startDate > endDate || basicSalary < 0) {
      if (startDate > endDate) {
        setError((prevState) => ({
          ...prevState,
          dateError: "Last working day can't be less than first working day",
        }));
        setDayDifference("");
      } else {
        setError((prevState) => ({
          ...prevState,
          dateError: "",
        }));
      }
      if (basicSalary < 0) {
        setError((prevState) => ({
          ...prevState,
          salaryError: "Please enter a valid basic salary",
        }));
      } else {
        setError((prevState) => ({
          ...prevState,
          salaryError: "",
        }));
      }
    } else {
      setError({
        dateError: "",
        salaryError: "",
      });

      if (!isNaN(startDate) && !isNaN(endDate)) {
        endDate.setDate(endDate.getDate() + 1);

        let totalYears = endDate.getFullYear() - startDate.getFullYear();
        let totalMonths = endDate.getMonth() - startDate.getMonth();
        let totalDays = endDate.getDate() - startDate.getDate();

        if (totalDays < 0) {
          let previousMonth = new Date(endDate.getFullYear(), endDate.getMonth(), 0);
          totalDays += previousMonth.getDate();
          totalMonths -= 1;
        }

        if (totalMonths < 0) {
          totalMonths += 12;
          totalYears -= 1;
        }

        if (totalDays === new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0).getDate()) {
          totalDays = 0;
          totalMonths += 1;
        }

        setDayDifference(`${totalYears} years ${totalMonths} months ${totalDays} days`);
      }
    }
  }, [firstWorkingDay, lastWorkingDay, basicSalary]);

  const handleReset = () => {
    setFirstWorkingDay("");
    setLastWorkingDay("");
    setBasicSalary("");
    setGratuity(0);
    setDayDifference("");
  };

  return (
    <div styleName="gratuity-calculator-container">
      <div styleName="content">
        <div styleName="right-container">
          <div styleName="calculator-container">
            <h2 styleName="title">Gratuity Calculator</h2>
            <form onSubmit={handleSubmit}>
              <div styleName="input-container">
                <div styleName="input-row">
                  <label styleName="label">First working day</label>
                  <input
                    type="date"
                    id="firstWorkingDay"
                    value={firstWorkingDay}
                    onChange={(e) => setFirstWorkingDay(e.target.value)}
                  />
                </div>
                <div styleName="input-row">
                  <label styleName="label">Last working day</label>
                  <input
                    type="date"
                    id="lastWorkingDay"
                    value={lastWorkingDay}
                    onChange={(e) => setLastWorkingDay(e.target.value)}
                  />
                  {error.dateError != "" && <p styleName="error">{error.dateError}</p>}
                  {dayDifference != "" && <p styleName="dayDifference">{dayDifference}</p>}
                </div>
              </div>

              <div styleName="input-row">
                <label styleName="label">Basic salary</label>
                <input
                  type="number"
                  id="basicSalary"
                  placeholder=""
                  value={basicSalary}
                  onChange={(e) => setBasicSalary(e.target.value)}
                />
                {error.salaryError != "" && <p styleName="error">{error.salaryError}</p>}
              </div>

              <button type="submit" styleName="submit">
                Submit
              </button>
              <button type="button" styleName="reset" onClick={handleReset}>
                Reset
              </button>
            </form>

            <div styleName="result">
              <span styleName="resultLabel">Approximate Gratuity: </span>
              <span styleName="resultValue">
                <h1>{`AED ${gratuity?.toFixed(0)}`}</h1>
              </span>
            </div>
          </div>
          <div styleName="calcualtor-info">
            <p>How gratuity in UAE is calculated</p>
            <br></br>
            <p>
              The UAE law entitles every worker to receive end-of-service benefits, also known as Gratuity, if they work
              for a minimum period of one year. When calculating the period of service, days of absence without pay are
              not included and employers may deduct any amounts owed by the worker from their gratuity payment. UAE
              Gratuity is calculated based on 21 days’ wage for each year of the first five years of their service, and
              30 days’ wage for each additional year.
            </p>
          </div>
        </div>
        <div styleName="rowAd">
          <DfpComponent
            adStyleName="ad-slot-size-300x600"
            id={`gratuity-calculator-page-row-ad`}
            path={
              "/1019334/GULFNEWS/Gulfnews_ATF/gulfnews_gratuitycalculator_atf/gulfnews_gratuitycalculator_right_atf_article"
            }
            size={rowAdConfig?.sizes}
            viewPortSizeMapping={rowAdConfig?.view_port_size_mapping}
          />
        </div>
      </div>
    </div>
  );
};
