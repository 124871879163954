import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { DfpComponent } from "../../../../ads/dfp-component";
import { useSelector } from "react-redux";
import get from "lodash/get";
import { getStoryHeadline, getStoryLabel, isExternalStory } from "../../../../utils/story";
import { Link } from "@quintype/components";
import { HeroImage } from "../../../../atoms/HeroImage";
import Newsletter from "../../../../atoms/Newsletter";
import { useDeviceType } from "../../../../custom-hooks/useDeviceType";
import { Label } from "../../../../atoms/Label";
import SubHeadline from "../../../../atoms/SubHeadline";
import { TrendingStories } from "../../trending-stories/TrendingStories";
import { BreadCrumbs } from "../../../../story-templates/atoms";

import "./vertical-list-with-labels.m.css";

const StoryTextContent = ({ story, type }) => {
  const { sections, authors } = story;
  return (
    <div styleName={`story-box`}>
      {type === "label"
        ? getStoryLabel(story) && <Label story={story} />
        : sections?.[0]?.["display-name"] && (
            <div styleName="section-text-class-section">{sections?.[0]?.["display-name"].toUpperCase()}</div>
          )}
      {getStoryHeadline(story) && <span styleName={`headline`}>{getStoryHeadline(story)}</span>}
      {story?.subheadline && <SubHeadline styleName="subheadline" subHeadline={story?.subheadline} />}
      {authors?.[0]?.name && <span styleName="section-text-class">{authors?.[0]?.name.toUpperCase()}</span>}
    </div>
  );
};

const StoryComponent = ({ story, type }) => {
  const externalStoryUrl = isExternalStory(story);
  const href = externalStoryUrl ? externalStoryUrl : `/${story?.slug}`;
  const target = externalStoryUrl ? "_blank" : "_self";
  return (
    <Link href={href} target={target}>
      <div styleName={`story-container`}>
        <div styleName={`story-image-container`}>
          <HeroImage
            story={story}
            widths={[135]}
            aspectRatio={[[4, 3]]}
            headline={getStoryHeadline(story)}
            iconSizes={{ width: 24, height: 24 }}
          />
        </div>
        <StoryTextContent story={story} type={type} />
      </div>
    </Link>
  );
};

const VerticalListWithLabels = ({ props, type }) => {
  const itemsPath =
    props.pageType === "magazine-landing-page" ? ["data", "data", "items"] : ["data", "collection", "items"];
  const list = get(props, itemsPath) || [];

  const stories =
    props.pageType === "magazine-landing-page"
      ? list?.filter((item) => item.type === "story").map((ele) => ele.story)
      : list;

  const [visibleItems, setVisibleItems] = useState(stories?.length > 12 ? 12 : stories?.length);

  let currPath = useSelector((state) => get(state, ["qt", "currentPath"], ""))
    .split("?")[0]
    .split("/")[1];
  let pageName = useSelector((state) =>
    get(state, ["qt", "config", "ads-config", "ad_name_mapping", currPath], "sections")
  );
  const networkId = useSelector((state) => get(state, ["qt", "config", "ads-config", "network_id"], ""));
  const rightTopAdUnit = `${networkId}/GULFNEWS/Gulfnews_ATF/gulfnews_${pageName}_atf/gulfnews_${pageName}_right_atf`;
  const rightBottomAdUnit = `${networkId}/GULFNEWS/Gulfnews_BTF/gulfnews_${pageName}_btf/gulfnews_${pageName}_btf_right`;
  const bottomAdUnit = `${networkId}/GULFNEWS/Gulfnews_BTF/gulfnews_${pageName}_btf/gulfnews_${pageName}_btf_footer`;
  const adConfig1 = useSelector((state) =>
    get(state, ["qt", "config", "ads-config", "slots", "section_page_adslot_1"], {})
  );
  const adConfig2 = useSelector((state) =>
    get(state, ["qt", "config", "ads-config", "slots", "section_page_adslot_2"], {})
  );
  const adConfig3 = useSelector((state) =>
    get(state, ["qt", "config", "ads-config", "slots", "section_page_adslot_3"], {})
  );
  const deviceType = useDeviceType();
  const isDesktop = deviceType === "desktop";

  const trendingHeadlineCustomStyle = {
    fontSize: isDesktop ? "16px" : "18px",
  };

  const newsLetterCustomStyle = {
    marginTop: "16px",
    marginBottom: "16px",
  };
  const newsLetterTextsStyle = {
    textAlign: isDesktop ? "left" : "center",
    fontFamily: "Roboto Serif",
  };

  const removeLeadingSlash = (path) => {
    return path.startsWith("/") ? path.slice(1) : path;
  };

  const loadMoreStories = () => {
    setVisibleItems((prevVisibleItems) =>
      stories.length < prevVisibleItems + 3 ? stories.length : prevVisibleItems + 3
    );
  };
  let currentPath = "";
  if (typeof window !== "undefined" && window.location) {
    currentPath = removeLeadingSlash(window.location.pathname);
  }

  return (
    <div styleName="container">
      <div styleName="breadcrumbs">
        <BreadCrumbs currentPath={currentPath} />
      </div>
      <div styleName="stories-wrapper">
        <div styleName="stories-results">
          {stories?.slice(0, visibleItems)?.map((item, index) => {
            const story = props.pageType === "magazine-landing-page" ? item : item?.story;
            return (
              <Fragment key={index}>
                <StoryComponent story={story} type={type} />
                {index !== visibleItems - 1 && <div styleName="horizontal-separator"></div>}
              </Fragment>
            );
          })}

          {visibleItems < stories.length && (
            <p styleName="loadmore-button" onClick={loadMoreStories}>
              LOAD MORE
            </p>
          )}
        </div>
        <div styleName="separartor"></div>
        <div styleName="right-column">
          <div styleName="top-ad-container">
            <DfpComponent
              adStyleName="ad-slot-size-300x600"
              id={`section-page-right-ad-1`}
              path={rightTopAdUnit}
              size={adConfig1?.sizes}
              viewPortSizeMapping={adConfig1.view_port_size_mapping}
            />
          </div>
          <div styleName="trending-stories">
            <TrendingStories headlineCustomStyle={trendingHeadlineCustomStyle} />
          </div>
          <div styleName="top-ad-container mobile-bottom-padding">
            <DfpComponent
              adStyleName="ad-slot-size-300x250-constant"
              id={`section-page-right-ad-2`}
              path={rightBottomAdUnit}
              size={adConfig2.sizes}
              viewPortSizeMapping={adConfig2.view_port_size_mapping}
            />
          </div>
          <Newsletter
            customStyle={newsLetterCustomStyle}
            isButtonPositionLower={true}
            newsLetterTextsStyle={newsLetterTextsStyle}
          />
        </div>
      </div>
      <div styleName="bottom-ad-container">
        <DfpComponent
          adStyleName="ad-slot-size-970x250"
          id={`section-page-bottom-ad`}
          path={bottomAdUnit}
          size={adConfig3.sizes}
          viewPortSizeMapping={adConfig3.view_port_size_mapping}
        />
      </div>
    </div>
  );
};

VerticalListWithLabels.propTypes = {
  data: PropTypes.object,
};

export default VerticalListWithLabels;
