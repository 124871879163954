import React from "react";
import Chart, { formatChartDate, formatTableDate, getLastPublishedTimeGoldForex } from "../../../../atoms/Chart";
import get from "lodash/get";
import { Link } from "@quintype/components";
import { useDeviceType } from "../../../../custom-hooks/useDeviceType";
import { useSelector } from "react-redux";
import TopStoryCard from "../../top-story-card/TopStoryCard";
import LeftSection from "../../left-section/LeftSection";
import RightSection from "../../right-section/RightSection";
import { DfpComponent } from "../../../../ads/dfp-component";
import { SvgIconHandler } from "../../../../atoms/svg-icon-hadler";
import HistoricalRemmitanceTable from "./HistoricalRemmitanceTable";

import "./remmitance-rate.m.css";

export const HistoricalRemmitancePage = (props) => {
  const menuItems = ["Remittance", "Gold Rate", "Fuel Price", "Currency"];
  const sectionIds = ["remittance", "gold-rate", "fuel-price", "currency"];
  const deviceType = useDeviceType();
  const isMobile = deviceType === "mobile";
  const isDesktop = deviceType === "desktop";
  const url =
    "https://www.lulumoney.com/?utm_source=https%3A%2F%2Fgulfnews.com%2Fgold-forex&utm_medium=Banner&utm_campaign=Gulfnewsrate_check";
  const adConfig = useSelector((state) =>
    get(state, ["qt", "config", "ads-config", "slots", "section_page_adslot_3"], {})
  );

  const queryString = props?.currentPath?.split("?")[1];
  const params = queryString ? new URLSearchParams(queryString) : null;
  const currency = params?.get("cc") || "";
  const country = props?.data?.historicalCurrencyApiData || [];
  const currencyDetails = country?.find((item) => item?.currencyCode.toLowerCase() === currency.toLowerCase()) || {};
  const rates = currencyDetails?.rates?.map((item) => item?.currencyRate) || [];
  const maxRate = rates?.length > 0 ? Math.max(...rates) : 0;
  const minRate = rates?.length > 0 ? Math.min(...rates) : 0;
  const adjustedMaxPrice = parseFloat((maxRate + 0.1).toFixed(2));
  const adjustedMinPrice = Math.max(0, parseFloat((minRate - 0.1).toFixed(2)));

  const lineKeys = ["currencyRate"];
  const lineNames = ["rate"];
  const xAxisKey = "modifiedDate";
  const formattedChartData = currencyDetails?.rates
    ?.map((item) => ({
      ...item,
      modifiedDate: formatChartDate(item?.modifiedDate),
      currencyRate: item?.currencyRate,
    }))
    .reverse();

  const domin = [adjustedMinPrice, adjustedMaxPrice];
  const currentDate = formatTableDate();
  return (
    <div styleName="remmitance-page-container">
      <div styleName="remmitance-page-wrapper">
        <div styleName="remmitance-page-left-center-container">
          <div styleName="remmitance-page-left-container">
            <LeftSection menuItems={menuItems} sectionIds={sectionIds} />
          </div>
          <div styleName="remmitance-page-center-container">
            <div>
              <TopStoryCard
                heading={currencyDetails?.currencyName}
                date={currentDate}
                updatedAt={getLastPublishedTimeGoldForex(currencyDetails?.ts)}
                id="gold-rates-003"
              />

              <Chart
                domin={domin}
                data={formattedChartData}
                lineKeys={lineKeys}
                xAxisKey={xAxisKey}
                showLegend={false}
                lineNames={lineNames}
              />
              <HistoricalRemmitanceTable currencyDetails={currencyDetails} />
            </div>

            <div styleName="lulu-money-logo-div">
              <p>PROVIDED BY</p>
              <Link target="_blank" href={url}>
                <SvgIconHandler type="lulu-money" height="60" width="100" viewBox="0 0 100 60" />
              </Link>
            </div>
          </div>
        </div>
        <div styleName="remmitance-page-right-container">
          <RightSection showYourMoneyStories={false} />
        </div>
      </div>

      <div>
        {isDesktop ? (
          <DfpComponent
            adStyleName="ad-slot-size-970x250"
            id="forex-page-bottom-ad"
            path={"/1019334/GULFNEWS/Gulfnews_BTF/gulfnews_goldforex_btf/gulfnews_goldforex_btf_footer"}
            size={adConfig.sizes}
            viewPortSizeMapping={adConfig.view_port_size_mapping}
          />
        ) : isMobile ? (
          <div styleName="right-bottom-ad-slot-320">
            <DfpComponent
              adStyleName="ad-slot-size-320x50"
              id="forex-page-right-bottom-ad"
              path={"/1019334/GULFNEWS/Gulfnews_BTF/gulfnews_goldforex_btf/gulfnews_goldforex_btf_footer"}
              size={adConfig.sizes}
              viewPortSizeMapping={adConfig.view_port_size_mapping}
            />
          </div>
        ) : (
          <div styleName="right-bottom-ad-slot">
            <DfpComponent
              adStyleName="ad-slot-size-300x250-constant"
              id="forex-page-right-bottom-ad"
              path={"/1019334/GULFNEWS/Gulfnews_BTF/gulfnews_goldforex_btf/gulfnews_goldforex_btf_footer"}
              size={adConfig.sizes}
              viewPortSizeMapping={adConfig.view_port_size_mapping}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default HistoricalRemmitancePage;
