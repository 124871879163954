import React from "react";
import LeftSection from "../../left-section/LeftSection";
import TopStoryCard from "../../top-story-card/TopStoryCard";
import get from "lodash/get";
import Chart, { formatChartDate, formatTableDate, getLastPublishedTimeGoldForex } from "../../../../atoms/Chart";
import RightSection from "../../right-section/RightSection";
import { useDeviceType } from "../../../../custom-hooks/useDeviceType";
import { useSelector } from "react-redux";
import { DfpComponent } from "../../../../ads/dfp-component";
import HistoricalGoldRateTable from "./HistoricalGoldRateTable";

import "./gold-rate.m.css";

export const HistoricalGoldPage = (props) => {
  const historicalRate = props?.data?.["historicalGoldApiData"] || [];
  const lineKeys = ["carat24", "carat22", "carat21", "carat18"];
  const xAxisKey = "date";
  const domin = ["dataMin-4", "dataMax+4"];
  const currentDate = formatTableDate();
  const lineNames = ["24ct", "22ct", "21ct", "18ct"];
  const deviceType = useDeviceType();
  const isMobile = deviceType === "mobile";
  const isDesktop = deviceType === "desktop";
  const adConfig = useSelector((state) =>
    get(state, ["qt", "config", "ads-config", "slots", "section_page_adslot_3"], {})
  );

  const formattedChartData = historicalRate
    ?.map((item) => ({
      date: formatChartDate(item?.date),
      carat24: item?.carat24,
      carat22: item?.carat22,
      carat21: item?.carat21,
      carat18: item?.carat18,
    }))
    ?.reverse();

  const menuItems = ["Remittance", "Gold Rate", "Fuel Price", "Currency"];
  const sectionIds = ["remittance", "gold-rate", "fuel-price", "currency"];

  return (
    <div styleName="gold-page-container">
      <div styleName="gold-page-wrapper">
        <div styleName="gold-page-left-center-container">
          <div styleName="gold-page-left-container">
            <LeftSection menuItems={menuItems} sectionIds={sectionIds} />
          </div>
          <div styleName="gold-page-center-container">
            <div>
              <TopStoryCard
                heading="Historical Gold Rates"
                date={currentDate}
                updatedAt={getLastPublishedTimeGoldForex(historicalRate?.[0]?.lastUpdated)}
                id="gold-rates-002"
              />
              <Chart
                data={formattedChartData}
                xAxisKey={xAxisKey}
                domin={domin}
                lineKeys={lineKeys}
                lineNames={lineNames}
                dataKey={lineKeys}
              />
              <HistoricalGoldRateTable historicalRate={historicalRate} />
            </div>
            <div styleName="lulu-money-logo-div">
              <p>Provided by Dubai Gold & Jewellery Group</p>
            </div>
          </div>
        </div>
        <div styleName="gold-page-right-container">
          <RightSection showYourMoneyStories={false} />
        </div>
      </div>
      <div>
        {isDesktop ? (
          <DfpComponent
            adStyleName="ad-slot-size-970x250"
            id="forex-page-bottom-ad"
            path={"/1019334/GULFNEWS/Gulfnews_BTF/gulfnews_goldforex_btf/gulfnews_goldforex_btf_footer"}
            size={adConfig?.sizes}
            viewPortSizeMapping={adConfig?.view_port_size_mapping}
          />
        ) : isMobile ? (
          <div styleName="right-bottom-ad-slot-320">
            <DfpComponent
              adStyleName="ad-slot-size-320x50"
              id="forex-page-right-bottom-ad"
              path={"/1019334/GULFNEWS/Gulfnews_BTF/gulfnews_goldforex_btf/gulfnews_goldforex_btf_footer"}
              size={adConfig?.sizes}
              viewPortSizeMapping={adConfig?.view_port_size_mapping}
            />
          </div>
        ) : (
          <div styleName="right-bottom-ad-slot">
            <DfpComponent
              adStyleName="ad-slot-size-300x250-constant"
              id="forex-page-right-bottom-ad"
              path={"/1019334/GULFNEWS/Gulfnews_BTF/gulfnews_goldforex_btf/gulfnews_goldforex_btf_footer"}
              size={adConfig?.sizes}
              viewPortSizeMapping={adConfig?.view_port_size_mapping}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default HistoricalGoldPage;
