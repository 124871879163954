import React, { useEffect, useState } from "react";
import fetch from "node-fetch";
import "./magazine-listing-page.m.css";

export default function FilterMagazineByMonthYear({ magazineSlug, setEditions, setShowSearchResults, sketchesHost }) {
  const [selectedMonth, setMonth] = useState({ label: "All Months", value: 12 });
  const [selectedYear, setYear] = useState({ label: "Select Year", value: "" });
  const [showYearOptions, setShowYearOptions] = useState(false);
  const [showMonthOptions, setShowMonthOptions] = useState(false);
  const [firstEditionPublishedDate, setFirstEditionPublishedDate] = useState();
  const currentYear = new Date().getFullYear();

  let yearOptions = [];

  const monthOptions = [
    { label: "All Months", value: 12 },
    { label: "January", value: 0 },
    { label: "February", value: 1 },
    { label: "March", value: 2 },
    { label: "April", value: 3 },
    { label: "May", value: 4 },
    { label: "June", value: 5 },
    { label: "July", value: 6 },
    { label: "August", value: 7 },
    { label: "September", value: 8 },
    { label: "October", value: 9 },
    { label: "November", value: 10 },
    { label: "December", value: 11 },
  ];

  const toggleSelect = (toggleYear, toggleMonth) => {
    setShowYearOptions(toggleYear);
    setShowMonthOptions(toggleMonth);
  };

  const getFirstEditionPublishedDate = async (magazineSlug) => {
    try {
      // the below call is to fetch the first published date of the magazine based on issue-date in ascending order
      // For locally to work remove sketchesHost
      const entityUrl = `${sketchesHost}/api/v1/magazines/${magazineSlug}/issues?sort-by=issue-date&order=asc`;
      const data = await fetch(entityUrl);
      const response = await data.json();
      const items = response?.data;

      let firstPublishedIssueDate;
      firstPublishedIssueDate = items[0] && items[0]["issue-date"];
      firstPublishedIssueDate = new Date(firstPublishedIssueDate).getFullYear() || currentYear;

      setFirstEditionPublishedDate(firstPublishedIssueDate);
    } catch (err) {
      console.error(`failed to get editions data: ${err}`);
    }
  };

  const getMonthTimestamps = (year, month) => {
    // months value starts from 0
    const from = month === 12 ? Date.UTC(year - 1, month, 1) : Date.UTC(year, month, 1);

    const to =
      month === 12 ? Date.UTC(year, month, 1) - 6 * 60 * 60 * 1000 : Date.UTC(year, month + 1, 1) - 6 * 60 * 60 * 1000;
    return { from, to };
  };

  const updateAllEditions = async (year, month) => {
    const { from, to } = getMonthTimestamps(year, month);
    const entityUrl = `/api/v1/magazines/${magazineSlug}/issues?from=${from}&to=${to}&sort-by=issue-date&order=desc`;
    try {
      const response = await fetch(entityUrl);
      const { data = [] } = await response.json();
      setEditions(data);
      setShowSearchResults(true);
    } catch (err) {
      console.error(`failed to get Magazine Issue data: ${err}`);
    }
  };

  if (!firstEditionPublishedDate) {
    // this function gets the first published date
    getFirstEditionPublishedDate(magazineSlug);
  }

  for (let year = currentYear; year >= firstEditionPublishedDate; year--) {
    yearOptions.push({ label: year, value: year });
  }

  const yearMonthSelector = (year, month) => {
    setMonth(month);
    setYear(year);
    updateAllEditions(year.value, month.value);
  };

  const getDropDown = (key, selectedOption, showOptions, toSelectOptions) => {
    const isYear = key === "year";
    const updatedYear = selectedYear.value ? selectedYear : { label: currentYear, value: currentYear };

    return (
      <div styleName="select-wrapper">
        <div
          styleName="select-overlay"
          onClick={() => (isYear ? toggleSelect(!showYearOptions, false) : toggleSelect(false, !showMonthOptions))}
        >
          {selectedOption.label}
        </div>
        {showOptions && (
          <div styleName="select-items">
            {toSelectOptions.map((option, index) => (
              <div
                styleName="item"
                key={`${option.label}-${index}`}
                onClick={() => {
                  isYear ? yearMonthSelector(option, selectedMonth) : yearMonthSelector(updatedYear, option);
                  toggleSelect(false, false);
                }}
              >
                {option.label}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  return (
    <div styleName="search-bar">
      <div styleName="year-div">
        <p>YEAR</p>
        {getDropDown("year", selectedYear, showYearOptions, yearOptions)}
      </div>
      <div styleName="month-div">
        <p>MONTH</p>
        {getDropDown("month", selectedMonth, showMonthOptions, monthOptions)}
      </div>
    </div>
  );
}
