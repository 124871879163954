import React, { useEffect, useState } from "react";
import get from "lodash/get";
import { useSelector } from "react-redux";
import { DfpComponent } from "../../ads/dfp-component";
import LeftSection from "./left-section/LeftSection";
import MiddleSection from "./middle-section/MiddleSection";
import RightSection from "./right-section/RightSection";
import { getMenuGroups } from "../../../../api/utils";

import "./forex-page.m.css";

export const ForexPage = (props) => {
  const menuItems = ["Remittance", "Gold Rate", "Fuel Price", "Currency"];
  const sectionIds = ["remittance", "gold-rate", "fuel-price", "currency"];
  const [menuGroupsList, setMenuGroupsList] = useState([]);
  const sectionMenuList = menuGroupsList?.["menu-groups"]?.["main-nav"]?.items || [];
  const coverImage = get(props, ["data", "collection", "metadata", "cover-image"], null);
  const centerBottomAdConfig = useSelector((state) =>
    get(state, ["qt", "config", "ads-config", "slots", "gold_forex_center_bottom_ad"], {})
  );

  useEffect(() => {
    const fetchMenuGroups = async () => {
      try {
        const menuGroups = await getMenuGroups();
        setMenuGroupsList(menuGroups);
      } catch (error) {
        console.error("Error fetching menu groups:", error);
      }
    };

    fetchMenuGroups();
  }, []);
  return (
    <div styleName="forex-page-container">
      <div styleName="forex-page-wrapper">
        <div styleName="forex-page-left-center-container">
          <div styleName="forex-page-left-container">
            <LeftSection menuItems={menuItems} sectionIds={sectionIds} />
          </div>
          <div styleName="forex-page-center-container">
            <MiddleSection apiData={{ allApiData: props?.data }} />
          </div>
        </div>
        <div styleName="forex-page-right-container">
          <RightSection />
        </div>
      </div>

      <div>
        <div styleName="right-bottom-add-wrraper">
          <DfpComponent
            adStyleName="ad-slot-size-gold-forex-970x250"
            id="forex-page-bottom-ad"
            path={"/1019334/GULFNEWS/Gulfnews_BTF/gulfnews_goldforex_btf/gulfnews_goldforex_btf_footer"}
            size={centerBottomAdConfig.sizes}
            viewPortSizeMapping={centerBottomAdConfig.view_port_size_mapping}
          />
        </div>
      </div>
    </div>
  );
};
