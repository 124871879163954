import React from "react";
import get from "lodash/get";
import { Link } from "@quintype/components";
import { MagazineCoverImageCard } from "./magazine-coverimage-card";
import { getMagazineSlug } from "../../utils";
import "./magazine-edition.m.css";

export function MagazineEdition({ data, sketchesHost }) {
  if (!data?.length) return null;

  return data.map((item, index) => {
    const { name, "created-at": createdAt, "issue-date": issueDate, metadata } = item;
    const magazineSlug = get(metadata, ["entities", "collectionEntities", "magazine", "0", "slug"], "");
    const date = issueDate || createdAt;
    const magazineDate = date && new Date(date);
    const slug = item && getMagazineSlug(item, magazineSlug);

    const options = {
      day: "numeric",
      month: "long",
      year: "numeric",
      timeZone: "UTC",
    };
    return (
      <>
        <div key={item.id} styleName="magazine-edition-wrapper">
          <Link href={`${sketchesHost}/${slug}`}>
            <div styleName="issues">
              <MagazineCoverImageCard item={item} magazineSlug={magazineSlug} sketchesHost={sketchesHost} />
              {name && <p styleName="mag-title">{name}</p>}
              {magazineDate && <p styleName="mag-date">{magazineDate.toLocaleDateString("en-GB", options)}</p>}
            </div>
          </Link>
        </div>
      </>
    );
  });
}
