import React, { useState } from "react";
import { Link } from "@quintype/components";

import { SvgIconHandler } from "../../../atoms/svg-icon-hadler";
import "./common-tabel.m.css";

const RateExchangeTable = ({ currencyExchageApiData }) => {
  const [selectedCurrency, setSelectedCurrency] = useState(null);

  const handleRowClick = (currencyCode) => {
    const currencyDetails = currencyExchageApiData?.find((item) => item?.currencyCode === currencyCode);
    setSelectedCurrency(currencyDetails);
  };

  const countriesWithFlags = [
    { code: "INR", flag: <SvgIconHandler type="india-flag" width="16" height="12" viewBox="0 0 16 12" /> },
    { code: "PHP", flag: <SvgIconHandler type="philp-flag" width="16" height="12" viewBox="0 0 16 12" /> },
    { code: "PKR", flag: <SvgIconHandler type="pak-flag" width="16" height="12" viewBox="0 0 16 12" /> },
    { code: "BDT", flag: <SvgIconHandler type="bng-flag" width="16" height="12" viewBox="0 0 16 12" /> },
    { code: "LKR", flag: <SvgIconHandler type="sri-lanka-flag" width="16" height="12" viewBox="0 0 16 12" /> },
    { code: "NPR", flag: <SvgIconHandler type="nepal-flag" width="16" height="12" viewBox="0 0 16 12" /> },
    { code: "EGP", flag: <SvgIconHandler type="egypt-flag" width="16" height="12" viewBox="0 0 16 12" /> },
    { code: "GBP", flag: <SvgIconHandler type="british-flag" width="16" height="12" viewBox="0 0 16 12" /> },
  ];

  return (
    <div styleName="table-container">
      <table styleName="rate-exchange-table rate-exchange-currency-table">
        <thead>
          <tr>
            <th>Currencies</th>
            <th>Morning</th>
            <th>Evening</th>
            <th>Yesterday</th>
          </tr>
        </thead>
        <tbody styleName="table-body-wrapper">
          {currencyExchageApiData?.length > 0 ? (
            countriesWithFlags?.map((countryInfo) => {
              const country = currencyExchageApiData.find((item) => item?.currencyCode === countryInfo?.code);
              if (!country) return null;

              return (
                <tr key={countryInfo?.code} onClick={() => handleRowClick(selectedCurrency?.currencyCode)}>
                  <th>
                    <Link href={`/gold-forex/historical-currency-draft-rates?cc=${country.currencyCode.toLowerCase()}`}>
                      <span styleName="county-flag">{countryInfo?.flag} </span>
                      {country?.currencyName} <br />
                      <span styleName="currency-code">({country?.currencyCode})</span>
                    </Link>
                  </th>

                  <td>
                    {country?.morningRate > 0 ? (
                      <>
                        {country?.morningRate}
                        <br />
                        <small styleName="small-rates">{`DH ${(1000 / country?.morningRate).toFixed(2)} / 1000 ${
                          country?.currencyCode
                        }`}</small>
                      </>
                    ) : (
                      "-"
                    )}
                  </td>

                  <td>
                    {country?.eveningRate > 0 ? (
                      <>
                        {country?.eveningRate}
                        <br />
                        <small styleName="small-rates">{`DH ${(1000 / country?.eveningRate).toFixed(2)} / 1000 ${
                          country?.currencyCode
                        }`}</small>
                      </>
                    ) : (
                      "-"
                    )}
                  </td>

                  <td>
                    {country?.yesterdayRate > 0 ? (
                      <>
                        {country?.yesterdayRate}
                        <br />
                        <small styleName="small-rates">{`DH ${(1000 / country?.yesterdayRate).toFixed(2)} / 1000 ${
                          country?.currencyCode
                        }`}</small>
                      </>
                    ) : (
                      "-"
                    )}
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan="4">No data available</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default RateExchangeTable;
