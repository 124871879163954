import React, { useState } from "react";
import PropTypes from "prop-types";
import SearchHeading from "../../atoms/SearchHeading";
import { SearchBar } from "./SearchBar";

import "./search-page.m.css";

export const SearchPage = ({ config, data }) => {
  const [showSearchResults, setShowSearchResults] = useState(false);
  return (
    <>
      <SearchHeading headingText="Search" />
      <div className="container" styleName="search-container">
        <SearchBar
          config={config}
          showSearchResults={showSearchResults}
          setShowSearchResults={setShowSearchResults}
          childSections={config?.sections}
          queryParam={data?.query}
        />
      </div>
    </>
  );
};

SearchPage.propTypes = {
  data: PropTypes.object,
};
