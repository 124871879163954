import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { DfpComponent } from "../../ads/dfp-component";
import { useSelector } from "react-redux";
import get from "lodash/get";
import { getStoryHeadline, isExternalStory } from "../../utils/story";
import { Link } from "@quintype/components";
import { HeroImage } from "../../atoms/HeroImage";
import { SearchBar } from "../search-page/SearchBar";
import { BreadCrumbs } from "../../story-templates/atoms";
import { useDeviceType } from "../../custom-hooks/useDeviceType";

import "./gn-reach.m.css";
import { getCollectionitems } from "../../../../api/utils";

const StoryComponent = ({ story, deviceType }) => {
  const externalStoryUrl = isExternalStory(story);
  const href = externalStoryUrl ? externalStoryUrl : `/${story?.slug}`;
  const target = externalStoryUrl ? "_blank" : "_self";
  const brandName = get(story, ["metadata", "story-attributes", "brandname"], undefined);
  const iconSizes = deviceType === "mobile" ? { width: 24, height: 24 } : { width: 32, height: 32 };
  return (
    <div styleName={`story-container-wrapper`}>
      <Link href={href} target={target}>
        <div styleName={`story-container`}>
          <div styleName={`story-image-container`}>
            <HeroImage
              story={story}
              widths={[135]}
              aspectRatio={[[4, 3]]}
              headline={getStoryHeadline(story)}
              iconSizes={iconSizes}
            />
          </div>
          <div styleName={`story-box`}>
            {getStoryHeadline(story) && <span styleName={`headline`}>{getStoryHeadline(story)}</span>}
            {brandName?.[0] && <span styleName="brand-text">{brandName?.[0].toUpperCase()}</span>}
          </div>
        </div>
      </Link>
    </div>
  );
};

const getStoriesInRows = (stories, deviceType) => {
  let updatedStories = [];
  let rowStories = [];
  let itemsPerRow = 3;
  deviceType === "mobile" ? (itemsPerRow = 2) : (itemsPerRow = 3);

  stories.forEach((story, index) => {
    if ((index + 1) % itemsPerRow === 0) {
      rowStories.push(story);
      updatedStories.push(rowStories);
      rowStories = [];
    } else {
      rowStories.push(story);
    }
  });
  rowStories.length > 0 ? updatedStories.push(rowStories) : "";
  return updatedStories;
};

const GulfNewsReach = (props) => {
  const deviceType = useDeviceType();
  const [showSearchResults, setShowSearchResults] = useState(false);
  const initialList = props?.data?.collection?.items ? props?.data?.collection?.items : [];
  const [stories, setStories] = useState(initialList?.length > 99 ? initialList.slice(0, 99) : initialList);
  const [offset, setOffset] = useState(99);
  const [visibleItems, setVisibleItems] = useState(initialList?.length > 99 ? 99 : initialList?.length);

  const slug = get(props, ["data", "collection", "slug"], null);
  let currPath = useSelector((state) => get(state, ["qt", "currentPath"], ""))
    .split("?")[0]
    .split("/")[1];
  let pageName = useSelector((state) =>
    get(state, ["qt", "config", "ads-config", "ad_name_mapping", currPath], "sections")
  );
  const networkId = useSelector((state) => get(state, ["qt", "config", "ads-config", "network_id"], ""));
  const rightTopAdUnit = `${networkId}/GULFNEWS/Gulfnews_ATF/gulfnews_${pageName}_atf/gulfnews_${pageName}_right_atf`;
  const rightBottomAdUnit = `${networkId}/GULFNEWS/Gulfnews_BTF/gulfnews_${pageName}_btf/gulfnews_${pageName}_btf_right`;
  const BottomAdUnit = `${networkId}/GULFNEWS/Gulfnews_BTF/gulfnews_${pageName}_btf/gulfnews_${pageName}_btf_footer`;
  const adConfig1 = useSelector((state) =>
    get(state, ["qt", "config", "ads-config", "slots", "section_page_adslot_1"], {})
  );
  const adConfig2 = useSelector((state) =>
    get(state, ["qt", "config", "ads-config", "slots", "section_page_adslot_2"], {})
  );
  const adConfig3 = useSelector((state) =>
    get(state, ["qt", "config", "ads-config", "slots", "section_page_adslot_3"], {})
  );

  const removeLeadingSlash = (path) => {
    return path.startsWith("/") ? path.slice(1) : path;
  };

  let currentPath = "";
  if (typeof window !== "undefined" && window.location) {
    currentPath = removeLeadingSlash(window.location.pathname);
  }

  const loadMoreStories = async () => {
    const limit = 98;
    const response = await getCollectionitems(slug, offset, limit);
    const { items: moreStories, totalCount } = response;

    setStories([...stories, ...moreStories]);
    setVisibleItems(offset + limit <= totalCount);
    setOffset((prevOffset) => prevOffset + 99);
  };
  const visibleStories = getStoriesInRows(stories, deviceType);

  return (
    <div styleName="container">
      <div styleName="breadCrumbs-wrapper">
        <BreadCrumbs currentPath={currentPath} displayName={props?.data?.collection?.name} />
      </div>
      <div styleName="search-bar">
        <SearchBar
          config={props.config}
          showResultsOnButtonClick={true}
          setShowSearchResults={setShowSearchResults}
          showSearchResults={showSearchResults}
        />
      </div>
      {!showSearchResults && (
        <div styleName="stories-wrapper">
          <div styleName="stories-results">
            <div styleName="story-row-class">
              {visibleStories?.map((storyGroup, index) => {
                return (
                  <Fragment key={index}>
                    <div key={index} styleName="three-story-container">
                      <StoryComponent story={storyGroup?.[0]?.story} deviceType={deviceType} />
                      {storyGroup?.[1] && (
                        <>
                          <div styleName="verticle-separator"></div>
                          <StoryComponent story={storyGroup?.[1]?.story} deviceType={deviceType} />
                        </>
                      )}
                      {storyGroup?.[2] && (
                        <>
                          <div styleName="verticle-separator"></div>
                          <StoryComponent story={storyGroup?.[2]?.story} deviceType={deviceType} />
                        </>
                      )}
                    </div>
                    {index !== visibleStories?.length - 1 && <div styleName="horizontal-separator"></div>}
                  </Fragment>
                );
              })}
            </div>

            {visibleItems ? (
              <p styleName="loadmore-button" onClick={loadMoreStories}>
                LOAD MORE
              </p>
            ) : (
              <div></div>
            )}
          </div>
          <div styleName="separartor"></div>
          <div styleName="right-column">
            <div styleName="top-ad-container">
              <DfpComponent
                adStyleName="ad-slot-size-300x600"
                id={`gn-reach-page-right-top-ad`}
                path={rightTopAdUnit}
                size={adConfig1?.sizes}
                viewPortSizeMapping={adConfig1.view_port_size_mapping}
              />
            </div>
          </div>
        </div>
      )}

      <div styleName="bottom-ad-container">
        <DfpComponent
          adStyleName="ad-slot-size-970x250"
          id={`gn-reach-page-bottom-ad`}
          path={BottomAdUnit}
          size={adConfig3.sizes}
          viewPortSizeMapping={adConfig3.view_port_size_mapping}
        />
      </div>
    </div>
  );
};

GulfNewsReach.propTypes = {
  data: PropTypes.object,
};

export { GulfNewsReach };
