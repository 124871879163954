import React, { useEffect, useState } from "react";
import { object, shape } from "prop-types";
import { useSelector } from "react-redux";
import get from "lodash/get";
import { useDeviceType } from "../../custom-hooks/useDeviceType";
import { DfpComponent } from "../../ads/dfp-component";
import SectionMast from "../section-mast";
import { AuthorInfo } from "./author-card/AuthorInfo";
import { HorizontalStory } from "./horizontal-stories/HorizontalStory";
import { getMenuGroups } from "../../../../api/utils";

import "./author.m.css";

export const AuthorPage = (props) => {
  const [menuGroupsList, setMenuGroupsList] = useState([]);
  const deviceType = useDeviceType();
  const isDesktop = deviceType === "desktop";
  const ismobile = deviceType === "mobile";
  const currPath = useSelector((state) => get(state, ["qt", "currentPath"], ""))
    .split("?")[0]
    .split("/")[1];
  const pageName = useSelector((state) =>
    get(state, ["qt", "config", "ads-config", "ad_name_mapping", currPath], "sections")
  );
  const networkId = useSelector((state) => get(state, ["qt", "config", "ads-config", "network_id"], ""));
  const rightTopAdUnit = `${networkId}/GULFNEWS/Gulfnews_ATF/gulfnews_${pageName}_atf/gulfnews_${pageName}_right_atf`;
  const BottomAdUnit = `${networkId}/GULFNEWS/Gulfnews_BTF/gulfnews_${pageName}_btf/gulfnews_${pageName}_btf_footer`;

  const adConfig1 = useSelector((state) =>
    get(state, ["qt", "config", "ads-config", "slots", "section_page_adslot_2"], {})
  );
  const adConfig2 = useSelector((state) =>
    get(state, ["qt", "config", "ads-config", "slots", "section_page_adslot_3"], {})
  );
  useEffect(async () => {
    setMenuGroupsList(await getMenuGroups());
  }, []);
  const stories = props.data.authorCollection.items.map(({ story }) => story) || [];
  const [visibleItems, setVisibleItems] = useState(6); // Initial number of visible items
  const loadMoreStories = () => {
    setVisibleItems((prevVisibleItems) => prevVisibleItems + 6);
  };
  return (
    <div styleName="author-page-container">
      <div className="container" styleName="wrapper">
        <div styleName="authorContainer">
          <AuthorInfo author={props?.data?.author} ismobile={ismobile} />
          <div styleName="row-separator"></div>
          <h1 styleName="article-list-title">Articles from the author</h1>
          {stories.length > 0 ? (
            <div styleName="articleList">
              {stories.slice(0, visibleItems).map((story) => (
                <HorizontalStory key={story?.id} story={story} />
              ))}
            </div>
          ) : (
            <div>
              <p styleName="no-article">We’re sorry, but no articles were found :(</p>
            </div>
          )}
          {visibleItems < stories.length && (
            <p styleName="loadmore-button" onClick={loadMoreStories}>
              LOAD MORE
            </p>
          )}
        </div>
        {isDesktop && (
          <div styleName="right-column">
            <div styleName="right-top-ad-slot">
              <DfpComponent
                adStyleName="ad-slot-size-300x600"
                id="author-page-right-ad"
                path={rightTopAdUnit}
                size={adConfig1.sizes}
                viewPortSizeMapping={adConfig1.view_port_size_mapping}
              />
            </div>
          </div>
        )}
      </div>
      <div styleName="bottom-ad-container">
        <DfpComponent
          adStyleName={"ad-slot-size-970x250"}
          id="author-page-bottom-ad"
          path={BottomAdUnit}
          size={adConfig2.sizes}
          viewPortSizeMapping={adConfig2.view_port_size_mapping}
        />
      </div>
    </div>
  );
};

AuthorPage.propTypes = {
  data: shape({
    author: object,
    authorCollection: object,
  }),
};
