import React from "react";
import "./common-tabel.m.css";

const GoldRateTable = ({ goldApidata }) => {
  const rates = goldApidata || {};
  const sortedRates = Object.entries(rates)
    .filter(([carat]) => ["carat18", "carat21", "carat22", "carat24"].includes(carat))
    .sort((a, b) => parseInt(b[0].replace("carat", ""), 10) - parseInt(a[0].replace("carat", ""), 10));

  return (
    <div styleName="table-container">
      <table styleName="rate-exchange-table gold-table">
        <thead>
          <tr>
            <th>Type</th>
            <th>Morning</th>
            <th>Afternoon</th>
            <th>Evening</th>
            <th>Yesterday</th>
          </tr>
        </thead>

        <tbody>
          {sortedRates?.length > 0 ? (
            sortedRates?.map(([carat, prices]) => (
              <tr key={carat}>
                <td>{`${carat.replace("carat", "")} Carat`}</td>
                <td>{prices?.morning ? prices?.morning : "-"}</td>
                <td>{prices?.afternoon ? prices?.afternoon : "-"}</td>
                <td>{prices?.evening ? prices?.evening : "-"}</td>
                <td>{prices?.yesterday ? prices?.yesterday : "-"}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5">No data available</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default GoldRateTable;
