import React, { useState, useEffect } from "react";

const getRemainingMonths = () => {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const currentMonth = new Date().getMonth();
  return monthNames.slice(currentMonth).map((name, index) => ({ name, number: currentMonth + index + 1 }));
};

const MonthDropdown = React.memo(({ handleMonthChange, selectedMonth }) => {
  const [months, setMonths] = useState([]);

  useEffect(() => {
    setMonths(getRemainingMonths());
  }, []);

  return (
    <select
      onChange={(e) => handleMonthChange(e.target.value, months[e.target.selectedIndex].name)}
      value={selectedMonth}
    >
      {months.map((month, index) => (
        <option key={index} value={month.number}>
          {month.name}
        </option>
      ))}
    </select>
  );
});

export default MonthDropdown;
