import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { InputField } from "../../atoms/InputField";
import Button from "../../atoms/Button";
import { SvgIconHandler } from "../../atoms/svg-icon-hadler";
import { getAdvanceSearchPageItems, getCollectionitems } from "../../../../api/utils";
import { useDeviceType } from "../../custom-hooks/useDeviceType";
import {
  getQueryParams,
  getSectionIdFromSectionName,
  getSectionNameFromSectionId,
  setQueryParam,
} from "../../utils/utils";
import SearchResults from "./SearchResults";

import "./search-page.m.css";

export const SearchBar = ({
  config,
  showResultsOnButtonClick = false,
  sectionId = "",
  sectionName = "",
  showSearchResults,
  setShowSearchResults,
  childSections,
  queryParam = "",
}) => {
  const [searchResult, setSearchResult] = useState({});
  const [trendingStories, setTrendingStories] = useState([]);
  const [offset, setOffset] = useState(() => getPageNumber("page") || 1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [query, setQuery] = useState(getQueryParams("q") || queryParam);
  const [filterValue, setFilterValue] = useState(
    getSectionIdFromSectionName(config, getQueryParams("filter")) || sectionId
  );
  const [sortText, setSortText] = useState(getQueryParams("sort") || "score");
  const [cuisineFilter, setCuisineFilter] = useState("");
  const [prepTimeFilterValue, setPrepTimeFilterValue] = useState("");
  const [cookTimeFilterValue, setCookTimeFilterValue] = useState("");

  const [searched, setSearched] = useState(false);

  const allSectionRef = useRef([]);
  const allCuisineRef = useRef([]);
  const allPrepTimeRef = useRef([]);
  const allCookingTimeRef = useRef([]);

  const isMobile = useDeviceType();

  let itemsPerPage = 13;

  function getPageNumber(param) {
    if (typeof window !== "undefined" && window.location) {
      const pageNumber = getQueryParams(param);
      const totalLimit = searchResult?.total > 9987 ? 9987 : searchResult?.total;
      const totalPages = Math.ceil(totalLimit / itemsPerPage);
      if (parseInt(pageNumber, 10) >= totalPages) {
        setQueryParam("page", totalPages);
        setOffset(totalPages);
        return totalPages;
      }
      return pageNumber ? parseInt(pageNumber, 10) : null;
    }
    return null;
  }

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && query.trim() && query !== getQueryParams("q")) {
      e.preventDefault();
      getQueryParams("page") && setQueryParam("page", 1);
      getQueryParams("page") && setOffset(1);
      setQueryParam("filter", "");
      setQueryParam("sort", "score");
      setFilterValue("");
      setSortText("score");
      query && setQueryParam("q", query);
      handleSearch();
      setSearched(true);
    }
  };

  const handleClick = () => {
    if (query.trim() && query !== getQueryParams("q")) {
      getQueryParams("page") && setQueryParam("page", 1);
      getQueryParams("page") && setOffset(1);
      setQueryParam("filter", "");
      setQueryParam("sort", "score");
      setFilterValue("");
      setSortText("score");
      query && setQueryParam("q", query);
      handleSearch(true);
      setSearched(true);
    }
  };

  const getCurrentFilterValue = (filterValues, numOfChildGenerations = 0, generationCount = 1) => {
    if (!filterValues) return "";
    let modifiedFilterValues = Array.isArray(filterValues) ? filterValues : [parseInt(filterValues)];
    if (!(generationCount >= numOfChildGenerations + 1)) {
      if (Array.isArray(filterValues)) {
        modifiedFilterValues = filterValues.flatMap((filterValue) => [
          filterValue,
          ...config?.sections.filter((section) => section?.["parent-id"] === filterValue).map((section) => section?.id),
        ]);
      } else {
        modifiedFilterValues = [
          filterValues,
          ...config?.sections
            .filter((section) => section?.["parent-id"] === parseInt(filterValues))
            .map((section) => section?.id),
        ];
      }
      return getCurrentFilterValue([...new Set(modifiedFilterValues)], numOfChildGenerations, generationCount + 1);
    } else {
      return [...new Set(modifiedFilterValues)];
    }
  };

  const handleSearch = (fromButtonClick = false) => {
    setLoading(true);
    let currentPage = getPageNumber("page") >= 2 ? getPageNumber("page") - 1 : 0;
    const totalLimit = searchResult?.total > 9987 ? 9987 : searchResult?.total;
    const totalPages = Math.ceil(totalLimit / itemsPerPage);
    if (parseInt(currentPage, 10) >= totalPages) {
      currentPage = totalPages;
    }
    const offset = currentPage * itemsPerPage;

    const currentQuery = query ? query : getQueryParams("q");
    const currentSortText = sortText ? sortText : getQueryParams("sort");

    const modifiedFilterValue = fromButtonClick
      ? getCurrentFilterValue(sectionId, 2)
      : getCurrentFilterValue(filterValue, 2);

    const currentFilterValue = modifiedFilterValue
      ? modifiedFilterValue
      : modifiedFilterValue === ""
      ? ""
      : getQueryParams("filter");

    getAdvanceSearchPageItems(
      currentQuery,
      offset,
      itemsPerPage,
      currentSortText,
      currentFilterValue,
      cuisineFilter,
      prepTimeFilterValue,
      cookTimeFilterValue,
      sectionId
    )
      .then((result) => {
        !allSectionRef.current.length && (allSectionRef.current = Object.keys(result?.aggregations?.["section-id"]));
        if (sectionName === "Food") {
          !allCuisineRef.current.length &&
            (allCuisineRef.current = Object.keys(result?.aggregations?.["story-attributes.cuisine"]));
          !allPrepTimeRef.current.length &&
            (allPrepTimeRef.current = Object.keys(result?.aggregations?.["story-attributes.preparationtime"]));
          !allCookingTimeRef.current.length &&
            (allCookingTimeRef.current = Object.keys(result?.aggregations?.["story-attributes.cookingtime"]));
        }
        query && setQueryParam("q", query);
        sortText && setQueryParam("sort", sortText);
        filterValue && setQueryParam("filter", filterValue);
        window.location.pathname === "/gn-reach" && setQueryParam("type", "gn-reach");
        setSearchResult(result);
      })
      .catch((error) => {
        setError(true);
      })
      .finally(() => {
        setLoading(false);
        setSearched(false);
      });
  };

  useEffect(() => {
    getCollectionitems("trending-stories", 0, 5).then((trendingStoriesResult) => {
      setTrendingStories(trendingStoriesResult.items);
    });
    // query && getQueryParams("q") !== "" ? setQuery(getQueryParams("q")) : setQuery("");
  }, []);

  useEffect(() => {
    if (!searched && (!showResultsOnButtonClick || query)) {
      handleSearch();
    }
  }, [offset, filterValue, sortText, cuisineFilter, prepTimeFilterValue, cookTimeFilterValue]);

  useEffect(() => {
    Object.keys(searchResult).length > 0 && setShowSearchResults(true);
  }, [searchResult]);

  useEffect(() => {
    const handlePopState = (event) => {
      window.location.href = window.location.pathname;
    };

    window.addEventListener("popstate", handlePopState);
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);
  return (
    <>
      <div styleName={`input-wrapper ${sectionId ? "padding-32" : ""}`}>
        <div styleName="input-icon-wrapper">
          <InputField
            styleName="search-input"
            value={query}
            placeholder={
              sectionId ? `Search ${getSectionNameFromSectionId(config, sectionId)}` : "Search a topic or keyword"
            }
            onKeyDown={handleKeyDown}
            onChange={(e) => setQuery(e.target.value)}
          />
          {isMobile !== "mobile" && (
            <SvgIconHandler type="search-gray-color" width="32" height="32" className="svg-icon" />
          )}
        </div>
        <Button styleName="search-button" onClick={handleClick}>
          {isMobile === "mobile" ? (
            <SvgIconHandler type="search" width="32" height="32" className="svg-icon" />
          ) : (
            "SEARCH"
          )}
        </Button>
      </div>
      {showSearchResults && (
        <SearchResults
          config={config}
          searchResult={searchResult}
          trendingStories={trendingStories}
          loading={loading}
          error={error}
          setOffset={setOffset}
          setFilterValue={setFilterValue}
          setSortText={setSortText}
          filterValue={filterValue}
          sortText={sortText}
          offset={offset}
          sectionId={sectionId}
          sectionName={sectionName}
          handleSearch={handleSearch}
          allSectionRef={allSectionRef.current}
          allCuisineRef={allCuisineRef.current}
          allPrepTimeRef={allPrepTimeRef.current}
          allCookingTimeRef={allCookingTimeRef.current}
          childSections={childSections}
          setCuisineFilter={setCuisineFilter}
          cuisineFilter={cuisineFilter}
          setPrepTimeFilterValue={setPrepTimeFilterValue}
          prepTimeFilterValue={prepTimeFilterValue}
          setCookTimeFilterValue={setCookTimeFilterValue}
          cookTimeFilterValue={cookTimeFilterValue}
          itemsPerPage={itemsPerPage}
        />
      )}
    </>
  );
};

SearchBar.propTypes = {
  config: PropTypes.object,
  showResultsOnButtonClick: PropTypes.bool,
  sectionId: PropTypes.number,
  sectionName: PropTypes.string,
  showSearchResults: PropTypes.bool,
  setShowSearchResults: PropTypes.func,
  childSections: PropTypes.array,
};
