import React from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import PropTypes from "prop-types";
import { differenceInMinutes, parseISO } from "date-fns";

import "./chart.m.css";

export const formatChartDate = (dateStr) => {
  const date = new Date(dateStr);
  const options = { day: "numeric", month: "short" };
  return date.toLocaleDateString("en-GB", options);
};

export const formatTableDate = (dateString) => {
  const date = dateString ? new Date(dateString) : new Date();
  const options = { day: "numeric", month: "short", year: "numeric" };
  const formattedDate = new Intl.DateTimeFormat("en-GB", options).format(date);

  const day = date.getDate();
  const suffix = (day) => {
    if (day > 3 && day < 21) return "th";
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  return `${day}${suffix(day)} ${formattedDate.split(" ")[1]} ${formattedDate.split(" ")[2]}`;
};

export const getLastPublishedTimeGoldForex = (timestamp) => {
  const parsedTimestamp = new Date(timestamp);
  const minutesDifference = differenceInMinutes(new Date(), parsedTimestamp);

  if (minutesDifference < 60) {
    const minutesLabel = minutesDifference <= 1 ? "minute" : "minutes";
    return `Updated  ${minutesDifference} ${minutesLabel} ago`;
  }

  const hoursDifference = Math.ceil(minutesDifference / 60);
  const hourLabel = hoursDifference === 1 ? "hour" : "hours";
  return `Updated  ${hoursDifference} ${hourLabel} ago`;
};

const Chart = ({
  data,
  lineKeys,
  xAxisKey,
  domin = [0, "auto"],
  lineNames = [],
  showLegend = true,
  colors = ["#8844D8", "#5E865D", "#C71597", "#FF5500"],
}) => {
  const generateColor = (index) => colors[index % colors.length];

  return (
    <div styleName="chart-container">
      <ResponsiveContainer styleName="responsive-chart" height={252.6}>
        <LineChart data={data} margin={{ top: 5, right: 20, left: 10, bottom: 5 }}>
          <CartesianGrid strokeDasharray="2 2" />
          <XAxis dataKey={xAxisKey} />
          <YAxis domain={domin} />
          <Tooltip />
          {showLegend && <Legend />}
          {lineKeys.map((key, index) => (
            <Line
              key={key}
              type="monotone"
              dataKey={key}
              stroke={generateColor(index)}
              name={lineNames[index] || key}
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

Chart.propTypes = {
  data: PropTypes.array.isRequired,
  lineKeys: PropTypes.array.isRequired,
  xAxisKey: PropTypes.string.isRequired,
  domin: PropTypes.array,
  lineNames: PropTypes.array,
};

export default Chart;
