import React, { useEffect, useState } from "react";
import get from "lodash/get";
import { useSelector } from "react-redux";

import { DfpComponent } from "../../ads/dfp-component";
import { SvgIconHandler } from "../../atoms/svg-icon-hadler";
import { ThreeColThreeStories } from "../../collection-templates/three-col-three-stories";
import { getCollectionitems } from "../../../../api/utils";
import { getStoriesFromCollections } from "../../utils/collection";

import "./weather.m.css";

const WeatherPage = () => {
  let currPath = useSelector((state) => get(state, ["qt", "currentPath"], ""))
    .split("?")[0]
    .split("/")[1];
  let pageName = useSelector((state) =>
    get(state, ["qt", "config", "ads-config", "ad_name_mapping", currPath], "sections")
  );
  const networkId = useSelector((state) => get(state, ["qt", "config", "ads-config", "network_id"], ""));
  const BottomAdUnit = `${networkId}/GULFNEWS/Gulfnews_BTF/gulfnews_${pageName}_btf/gulfnews_${pageName}_btf_footer`;
  const adConfig = useSelector((state) =>
    get(state, ["qt", "config", "ads-config", "slots", "section_page_adslot_3"], {})
  );
  const [weatherData, setWeatherData] = React.useState(null);
  const today = new Date()
    .toLocaleDateString("en-GB", {
      weekday: "long",
      day: "numeric",
      month: "long",
    })
    .replace(/(\w+)\s/, "$1, ");

  const [weatherCollection, setWeatherCollection] = useState([]);
  const [country, setCountry] = useState("dubai");
  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp * 1000);
    return date.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  };

  useEffect(async () => {
    getCollectionitems("weather", 0, 3).then((weatherCollectionResult) => {
      setWeatherCollection(weatherCollectionResult);
    });
  }, []);

  useEffect(() => {
    async function getTemperature() {
      const response = await fetch(`/gulf-weather/${country}`);
      const result = await response.json();
      setWeatherData(result);
    }
    getTemperature();
  }, [country]);

  return (
    <div styleName="weather-page-wrapper">
      {weatherData && (
        <>
          <div>
            <div styleName="weather-widget">
              <div styleName="left-container">
                <div styleName="country-selector">
                  <select styleName="custom-select" onChange={(e) => setCountry(e.target.value)} value={country}>
                    <option value="abu_dhabi">Abu Dhabi</option>
                    <option value="dubai">Dubai</option>
                    <option value="ras_al_khaimah">RAK</option>
                    <option value="sharjah">Sharjah</option>
                    <option value="fujairah">Fujairah</option>
                    <option value="ajman">Ajman</option>
                    <option value="umm_al_quwain">Umm Al Quwain</option>
                  </select>
                </div>
                <div styleName="degree">
                  <div>
                    <img
                      src={`https://openweathermap.org/themes/openweathermap/assets/vendor/owm/img/widgets/${weatherData?.data?.weather[0]?.icon}.png`}
                    />
                    <span>{Math.round(weatherData?.data?.main?.temp)}</span>
                    <sup>&deg;</sup>
                  </div>
                </div>
                <div styleName="climate">{weatherData?.data?.weather[0]?.description}</div>
                <div styleName="day">{today}</div>
                <div styleName="sunrise-sunset">
                  <div>
                    <span>Sunrise</span> {formatTimestamp(weatherData?.data?.sys?.sunrise)}
                  </div>
                  <div>
                    <span>Sunset</span> {formatTimestamp(weatherData?.data?.sys?.sunset)}
                  </div>
                </div>
              </div>
              <div styleName="right-container">
                <div styleName="wind-speed">
                  <div styleName="image">
                    <SvgIconHandler type="wind-speed" width="40px" height="40px" />
                  </div>
                  <div styleName="value">
                    <div>Wind Speed</div>
                    <div>{weatherData?.data?.wind?.speed} m/s SE</div>
                  </div>
                </div>
                <div>
                  <div styleName="image">
                    <SvgIconHandler type="humidity" width="40px" height="40px" />
                  </div>
                  <div styleName="value">
                    <div>Humidity</div>
                    <div>{weatherData?.data?.main?.humidity}%</div>
                  </div>
                </div>
                <div>
                  <div styleName="image">
                    <SvgIconHandler type="visibility" width="40px" height="40px" />
                  </div>
                  <div styleName="value">
                    <div>Visibility</div>
                    <div>{weatherData?.data?.visibility / 1000} km</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div styleName="forecast-div-heading"> 7-day weather forecast </div>
            <div styleName="forecast-days-div">
              {weatherData?.forcast?.list?.map((day, index) => (
                <div styleName="forecast-day" key={index}>
                  <div>{new Date(day.dt * 1000).toLocaleDateString("en-US", { weekday: "long" })}</div>
                  <div>{new Date(day.dt * 1000).toLocaleDateString("en-US", { day: "numeric", month: "short" })}</div>
                  <div>{day.temp.day}°</div>
                  <div>
                    <img
                      src={`https://openweathermap.org/img/w/${day.weather[0].icon}.png`}
                      alt={day.weather[0].description}
                    />
                  </div>
                  <div>{day.weather[0].main}</div>
                </div>
              ))}
            </div>
          </div>

          <div styleName="stories-div">
            <div styleName="stories-div-heading">
              <h1>More on Weather</h1>
            </div>
            {weatherCollection?.items && (
              <ThreeColThreeStories
                collection={weatherCollection}
                stories={getStoriesFromCollections(weatherCollection, 3)}
                showStorySeparator={true}
              />
            )}
          </div>
          <div styleName="bottom-ad">
            <DfpComponent
              adStyleName="weather-page-ad-slot"
              id={`weather-page-bottom-ad`}
              path={BottomAdUnit}
              size={adConfig.sizes}
              viewPortSizeMapping={adConfig.view_port_size_mapping}
            />
          </div>
        </>
      )}
    </div>
  );
};

export { WeatherPage };
