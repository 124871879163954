import React, { useEffect, useState } from "react";
import get from "lodash/get";
import { SvgIconHandler } from "../../../atoms/svg-icon-hadler";
import { useSelector } from "react-redux";
import SocialShareIconsButton from "../shareIcons";
import { useDeviceType } from "../../../custom-hooks/useDeviceType";
import { DfpComponent } from "../../../ads/dfp-component";

import "./left-section.m.css";

const LeftSection = ({ menuItems, sectionIds }) => {
  const [pageInfo, setPageInfo] = useState({ selectedIndex: 0, currentPage: "home" });
  const [offset, setOffset] = useState(60);
  const [isSticky, setIsSticky] = useState(false);
  const qtConfig = useSelector((state) => get(state, ["qt"], {}));
  const domain = get(qtConfig, ["currentPath"], "");
  const leftTopadConfig = useSelector((state) =>
    get(state, ["qt", "config", "ads-config", "slots", "gold_forex_left_ad"], {})
  );
  const deviceType = useDeviceType();
  const isDesktop = deviceType === "desktop";
  const isTablet = deviceType === "tablet";
  const isMobile = deviceType === "mobile";
  const [isHistoricalPage, setIsHistoricalPage] = useState(false);
  const baseUrl = useSelector((state) => get(state, ["qt", "config", "sketches-host"], ""));

  useEffect(() => {
    const handleHashChange = () => {
      if (isDesktop || isTablet) {
        setOffset(60);
      } else {
        setOffset(110);
      }
    };
    handleHashChange();
  }, [deviceType, isMobile, isHistoricalPage]);

  const story = {
    url: `${baseUrl}${domain}`,
  };

  useEffect(() => {
    const handleHashChangeHistoricalPages = () => {
      const currentHash = window?.location?.hash.substring(1);
      const index = sectionIds.indexOf(currentHash);
      if (index !== -1) {
        handleClick(index);
      }
    };
    handleHashChangeHistoricalPages();
    window.addEventListener("hashchange", handleHashChangeHistoricalPages);
    return () => {
      window.removeEventListener("hashchange", handleHashChangeHistoricalPages);
    };
  }, [sectionIds]);
  const handleClick = (index) => {
    if (typeof window !== "undefined") {
      setPageInfo({ selectedIndex: index, currentPage: window.location.pathname });
      const sectionId = sectionIds[index];
      const sectionElement = document.querySelector(`#${sectionId}`);
      if (sectionElement) {
        const elementPosition = sectionElement.getBoundingClientRect().top;

        const offsetPosition = elementPosition + window.scrollY - offset;
        window.scrollTo({ top: offsetPosition, behavior: "smooth" });
      }

      if (isHistoricalPage) {
        setPageInfo({ selectedIndex: sectionIds[index], currentPage: window.location.pathname });
        window.location.href = `/gold-forex#${sectionId}`;
      }
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      const historicalPaths = ["historical-gold-rates", "historical-fuel-rates", "historical-currency-draft-rates"];
      const currentPath = window.location.pathname;
      const currentHash = window.location.hash;
      setIsHistoricalPage(historicalPaths.some((path) => currentPath.includes(path)));
      if (currentPath.includes("historical-gold-rates")) {
        setPageInfo({ selectedIndex: 1, currentPage: "historical-gold-rates" });
      } else if (currentPath.includes("historical-fuel-rates")) {
        setPageInfo({ selectedIndex: 2, currentPage: "historical-fuel-rates" });
      } else if (currentPath.includes("historical-currency-draft-rates")) {
        setPageInfo({ selectedIndex: 0, currentPage: "historical-currency-draft-rates" });
      } else if (currentPath.includes("/gold-forex") && currentHash) {
        const sectionId = currentHash.substring(1);
        const index = sectionIds.indexOf(sectionId);
        if (index !== -1) {
          setPageInfo({ selectedIndex: index, currentPage: "home" });
        }
      }
    }
  }, [menuItems]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 2500) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div styleName="left-section-container">
      <div styleName="menu-wrapper-container">
        <div styleName="menu-wrapper">
          {menuItems?.map((item, index) => (
            <a
              href={`#${sectionIds[index]}`}
              key={index}
              onClick={(e) => {
                e.preventDefault();
                handleClick(index);
              }}
              styleName={pageInfo.selectedIndex === index ? "active" : ""}
            >
              {item}

              {pageInfo.selectedIndex === index &&
                (isHistoricalPage && !isMobile ? (
                  <SvgIconHandler
                    styleName="historical-arrow"
                    type="right-arrow-new"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                  />
                ) : (
                  !isMobile && (
                    <SvgIconHandler
                      styleName="story-icon"
                      type="right-arrow-new"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                    />
                  )
                ))}
            </a>
          ))}
        </div>
        <div>
          {isMobile ? (
            <SocialShareIconsButton
              story={story}
              imageDimension={{ width: "35", height: "35", viewbox: "0 0 35 35" }}
            />
          ) : (
            <SocialShareIconsButton
              story={story}
              imageDimension={{ width: "42px", height: "42px", viewbox: "0 0 42 42" }}
            />
          )}
        </div>
        {!isMobile ? (
          <div styleName={`ad-slot-120x600-wrapper ${isSticky ? "sticky" : ""}`}>
            <DfpComponent
              adStyleName="ad-slot-size-120x600"
              id={`forex-left-ad`}
              path={leftTopadConfig?.ad_unit}
              size={leftTopadConfig?.sizes}
              viewPortSizeMapping={leftTopadConfig?.view_port_size_mapping}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default LeftSection;
