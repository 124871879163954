import React from "react";
import { object, bool } from "prop-types";
import get from "lodash/get";
import { Link } from "@quintype/components";
import { PublishAndReadTime } from "../../../../atoms/PublishAndReadTime";
import { HeroImage } from "../../../../atoms/HeroImage";
import { HeadlineWithLabel } from "../../../../atoms/HeadlineWithLabel";
import { getStoryHeadline } from "../../../../utils/story";

import "../photo-default-page.m.css";

export const StoryGrid = ({ story, leftImage, showBorder }) => {
  const headline = getStoryHeadline(story);
  const imageUrl =
    get(story, ["hero-image-s3-key"], "") ||
    get(story, ["alternative", "home", "default", "hero-image", "hero-image-s3-key"], "");

  return (
    <div style={{ borderBottom: !showBorder ? "0px" : "" }}>
      <div styleName={`common-story`} key={story?.id}>
        <Link href={`/${story?.slug}`}>
          <div styleName="storycard">
            <HeroImage
              story={story}
              headline={headline}
              aspectRatio={[[4, 3]]}
              iconSizes={{ height: 24, width: 24 }}
              imageUrl={imageUrl}
            />
            <HeadlineWithLabel story={story} showLabel={false} />
          </div>
        </Link>
      </div>
    </div>
  );
};

StoryGrid.propTypes = {
  story: object,
  leftImage: bool,
};
