export const NextPrayerTime = (cityPrayerTimes) => {
  const currentTime = new Date();
  const currentHour = currentTime.getHours();
  const currentMinutes = currentTime.getMinutes();
  let currentPrayer = {
    time: "",
    note: "",
  };

  const convertToMinutes = (time, period) => {
    let [hour, minutes] = time.split(":").map(Number);

    hour < 12 && !period && (period = "AM");

    if (period === "PM" && hour !== 12) {
      hour += 12;
    } else if (period === "AM" && hour === 12) {
      hour = 0;
    }

    return hour * 60 + minutes;
  };

  const currentTotalMinutes = currentHour * 60 + currentMinutes;

  let nextPrayer = null;

  const  prayerTimesBox = [
    { name: "fajr", period: "AM", note: "" },
    { name: "dhuhr", period: "", note: "" },
    { name: "asr", period: "PM", note: "" },
    { name: "maghrib", period: "PM", note: "" },
    { name: "isha", period: "PM", note: "" },
  ];
  prayerTimesBox.forEach((prayer) => {
    const prayerTimeInMinutes = convertToMinutes(cityPrayerTimes[prayer.name], prayer.period);
    if (prayerTimeInMinutes > currentTotalMinutes && !nextPrayer) {
      let remainingMinutesTotal = prayerTimeInMinutes - currentTotalMinutes;
      let remainingHour = Math.floor(remainingMinutesTotal / 60);
      let remainingMinutes = remainingMinutesTotal % 60;

      let hourString =
        remainingHour > 1 ? `${remainingHour} hours` : remainingHour === 1 ? `${remainingHour} hour` : "";
      let minuteString =
        remainingMinutes > 1
          ? `${remainingMinutes} minutes`
          : remainingMinutes === 1
          ? `${remainingMinutes} minute`
          : "";
      let timeString = `${hourString}${hourString && minuteString ? " " : ""}${minuteString}`;

      currentPrayer = {
        time: prayer.name,
        note: timeString ? `${timeString} to ${prayer.name}` : `It's time for ${prayer.name}`,
      };

      nextPrayer = prayer;
    }
  });

  return currentPrayer;
};
