import React from "react";
import { Link, ResponsiveImage } from "@quintype/components";
import PropTypes from "prop-types";
import get from "lodash.get";
import { useSelector } from "react-redux";
import { getMagazineSlug } from "../../utils";
import "./magazine-edition.m.css";

export const MagazineCoverImageCard = ({ item, magazineSlug, sketchesHost }) => {
  const imgAltText = get(item, ["metadata", "magazine", "name"], "cover-image");
  const coverImageData = get(item, ["metadata", "cover-image"]) || {};
  const {
    "cover-image-url": coverUrl,
    "cover-image-s3-key": covers3Key,
    "cover-image-metadata": coverImageMetaData,
  } = coverImageData;
  const slug = item && getMagazineSlug(item, magazineSlug);
  const fallbackImageUrl = "https://images.assettype.com/gulfnews-uat/2024-10-15/r2sl2lqd/Gulf-News-Fallback-Image.jpg";

  const isBotRequest = useSelector((state) => get(state, ["qt", "isBotRequest"], false));

  return (
    <div styleName="cover-image">
      {covers3Key || coverUrl ? (
        <figure styleName="image">
          {covers3Key ? (
            <Link href={`${sketchesHost}/${slug}`}>
              <ResponsiveImage
                slug={covers3Key}
                aspectRatio={[1, 2]}
                metadata={{ coverImageMetaData }}
                defaultWidth={400}
                imgParams={{ auto: ["format", "compress"] }}
                alt={imgAltText}
                isBotRequest={isBotRequest}
              />
            </Link>
          ) : (
            <img src={coverUrl} alt="cover-image" loading="lazy" />
          )}
        </figure>
      ) : (
        <div styleName="fallback-img">
          <img className="qt-image" src={`${fallbackImageUrl}`} alt="image-fallback"></img>
        </div>
      )}
    </div>
  );
};

MagazineCoverImageCard.propTypes = {
  collection: PropTypes.shape({
    "cover-image-url": PropTypes.string,
    "cover-image-s3-key": PropTypes.string,
    "cover-image-metadata": PropTypes.string,
    slug: PropTypes.string,
  }),
  config: PropTypes.shape({
    magazineSlug: PropTypes.string,
  }),
};
