import React from "react";
import { object, bool } from "prop-types";
import { SvgIconHandler } from "../../../atoms/svg-icon-hadler";
import "../author.m.css";

const getIcon = (item) => {
  switch (item) {
    case "twitter":
      return (
        <SvgIconHandler iconStyle={{ color: "#596E79" }} type="x" height="44px" width="44px" viewBox="0 0 44 44" />
      );
    case "facebook":
      return (
        <SvgIconHandler
          iconStyle={{ color: "#596E79" }}
          type="facebook"
          height="44px"
          width="44px"
          viewBox="0 0 44 44"
        />
      );
    case "youtube":
      return (
        <SvgIconHandler
          iconStyle={{ color: "#596E79" }}
          type="youtube"
          height="44px"
          width="44px"
          viewBox="0 0 44 44"
        />
      );
    case "linkedin":
      return (
        <SvgIconHandler
          iconStyle={{ color: "#596E79" }}
          type="linkedin"
          height="44px"
          width="44px"
          viewBox="0 0 44 44"
        />
      );
    case "whatsapp":
      return (
        <SvgIconHandler
          iconStyle={{ color: "#596E79" }}
          type="whatsapp"
          height="44px"
          width="44px"
          viewBox="0 0 44 44"
        />
      );
    case "instagram":
      return (
        <SvgIconHandler
          iconStyle={{ color: "#596E79" }}
          type="instagram"
          height="44px"
          width="44px"
          viewBox="0 0 44 44"
        />
      );
    case "pinterest":
      return (
        <SvgIconHandler
          iconStyle={{ color: "#596E79" }}
          type="pinterest"
          height="44px"
          width="44px"
          viewBox="0 0 44 44"
        />
      );
  }
};

export const AuthorInfo = ({ author, ismobile }) => {
  const size = Object.entries(author.social).length;
  const gridTemplateColumns = () => {
    if (size < 5) {
      return `repeat(${size}, 1fr)`;
    }
  };
  return (
    <div styleName="author-card-wrapper">
      <div>
        <div styleName="author-info" style={{ marginBottom: !author.bio ? 0 : "" }}>
          <div styleName="author-info-card">
            {author["avatar-url"] ? (
              <img src={author["avatar-url"]} alt={author.name} styleName="author-image" />
            ) : (
              <SvgIconHandler iconStyle={"author-image"} type="profile" styleName="author-image" />
            )}
            <div>
              <h2 styleName="author-name">{author.name}</h2>
              <p styleName="author-role">{author.metadata.designation}</p>
            </div>
          </div>
          {author.social && author.social && Object.keys(author.social).length > 0 && (
            <div
              styleName="social-links"
              style={{ gridTemplateColumns: gridTemplateColumns(), width: ismobile ? 52 * size : "" }}
            >
              {author.social &&
                Object.entries(author.social).map(([key, value], index) => {
                  if (value && value.url) {
                    return (
                      <a
                        data-test-id="social-link"
                        href={value.url}
                        key={`${key}-${index}`}
                        rel="noreferrer"
                        target="_blank"
                      >
                        {getIcon(key)}
                      </a>
                    );
                  }
                  return null;
                })}
            </div>
          )}
        </div>
      </div>
      {author.bio && <p styleName="author-bio">{author.bio}</p>}
    </div>
  );
};

AuthorInfo.propTypes = {
  author: object,
  ismobile: bool,
};
